import axios from "axios"

//!!!!!!! LOGIN DURUMU
const saltDataIslemleri = async (token)=>{
    try{
        let data =  await axios.get("http://localhost:5001/api/salt/"+window.location.pathname.split("t/")[1],
            {
                headers: {
                    'authorization': 'Bearer ' + token
                }
            }
        )
        if (data.status === 200) {
            return data.data
        }
    }
    catch{
        // // console.log("*****", window.location.pathname.split("r/")[1])   
    }

}

export default saltDataIslemleri