import React, { useEffect, useState } from "react"
import "./profil.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import axios from "axios"
import CookieManager from "../cookieManager"

const Profil = (props) => {

    const [baslangic, setBaslangic] = useState(false)
    const [menuPozisyon, setMenuPozisyon] = useState([false, false])
    const [email, setEmail] = useState("")
    const [telefon, setTelefon] = useState("")
    const [inputAktif, setInputAktif] = useState([false, false])
    const [passwordT, setPassword] = useState("")
    const [newPassword, setNewPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [sifreGizle, setSifreGizle] = useState([true, true, true])

    let email2 = CookieManager.getCookie("email")
    let telefon2 = CookieManager.getCookie("phone")

    useEffect(() => {
        if (baslangic)
            return
        setBaslangic(true)
        try {
            props.kullaniciBilgileriGuncelle()
        }
        catch { }
    }, [baslangic, props])

    let menuPozisyonAyari = (i) => {
        let pozisyonlar = Array.from(menuPozisyon);
        pozisyonlar[i] = !pozisyonlar[i];
        setMenuPozisyon(pozisyonlar)
    }

    //!!!!!!! LOGIN DURUMU
    let hesapBilgileriniGuncelle = async () => {
        let gonderilecekData = {
            email: inputAktif[0] ? email : email2,
            phone: inputAktif[1] ? telefon : telefon2,
            NameSurname: CookieManager.getCookie("kullaniciAdiSoyadi"),
            id: CookieManager.getCookie("id")
        }
        try {
            let res = await axios.put("https://api.enelsa.com:5015/api/edit-user", JSON.stringify(gonderilecekData), {
                headers: {
                    "Content-Type": "application/json",
                    "authorization": "Bearer " + props.tokenBilgileri.token
                }
            })
            let yeniBildirim = { mesaj: "Hesap Ayarları", bildirim: 1, key: "kalanRenk-" + Math.random(10), sagaGorePozisyonu: -250, uyari: 0 }
            props.bildirimleriYonet("kanalRenk", yeniBildirim)
        }
        catch {
            let yeniBildirim = { mesaj: "Hesap Ayarları", bildirim: 0, key: "kalanRenk-" + Math.random(10), sagaGorePozisyonu: -250, uyari: 0 }
            props.bildirimleriYonet("kanalRenk", yeniBildirim)
        }
    }

    let sifreGuncelle = async () => {
        let ID = CookieManager.getCookie("id")
        let gonderilecekData = { oldPassword: passwordT, newPassword: newPassword, confirmPassword: confirmPassword, ID }
        try {
            if (newPassword == confirmPassword) {
                let res = await axios.post("https://api.enelsa.com:5015/api/change-password", JSON.stringify(gonderilecekData), {
                    headers: {
                        "Content-Type": "application/json",
                        "authorization": "Bearer " + props.tokenBilgileri.token
                    }
                })
                if (res.data == "error") {
                    let yeniBildirim = { mesaj: "Mevcut Şifre Hatalı", bildirim: 0, key: "kalanRenk-" + Math.random(10), sagaGorePozisyonu: -250, uyari: 0 }
                    props.bildirimleriYonet("kanalRenk", yeniBildirim)
                } else {
                    let yeniBildirim = { mesaj: "Yeni Şifre", bildirim: 1, key: "kalanRenk-" + Math.random(10), sagaGorePozisyonu: -250, uyari: 0 }
                    props.bildirimleriYonet("kanalRenk", yeniBildirim)
                    props.cikisYap()
                    // console.log(window.location.pathname)
                }
            } else {
                let yeniBildirim = { mesaj: "Şifreler Uyuşmuyor", bildirim: 0, key: "kalanRenk-" + Math.random(10), sagaGorePozisyonu: -250, uyari: 0 }
                props.bildirimleriYonet("kanalRenk", yeniBildirim)
            }
        }
        catch {
            let yeniBildirim = { mesaj: "Yeni Şifre", bildirim: 0, key: "kalanRenk-" + Math.random(10), sagaGorePozisyonu: -250, uyari: 0 }
            props.bildirimleriYonet("kanalRenk", yeniBildirim)
        }
    }

    let inputAktiviteAyarlarama = (konum) => {
        if (konum === -1)
            return
        let inputAktifKopya = Array.from(inputAktif)
        inputAktifKopya[konum] = true
        if (konum === 0)
            setEmail(email2 ? email2 : "")
        else
            setTelefon(telefon2 ? telefon2 : "")
        setInputAktif(inputAktifKopya)
    }

    let ekranGenisligi = window.innerWidth - props.menuGenisligi

    return (
        <div id="profil">
            <div className="profil-alt-div">
                <div onClick={() => { menuPozisyonAyari(0) }} className="profil-alt-div-hesap">
                    <div className="profil-div-nokta"></div>
                    <span className="profil-div-title">Hesap Ayarları</span>
                </div>
                <div className="profil-div-menu">

                    <div style={{ display: "flex", flexDirection: ekranGenisligi < 600 ? "column" : "row", columnGap: "10px", alignItems: "center" }}>
                        <div className="profil-userIco-div">
                            <FontAwesomeIcon icon={faUser} className="profil-user-ico" />
                        </div>
                        <div className="profil-flex-column">
                            <span className="profil-flex-text">Mail Adresi</span>
                            <input onClick={() => { inputAktiviteAyarlarama(inputAktif[0] ? -1 : 0) }} className="profil-input" placeholder={inputAktif[0] ? "" : email2} onChange={(e) => setEmail(e.target.value)} value={email}></input>
                        </div>
                        <div className="profil-flex-column">
                            <span className="profil-flex-text">Telefon Numarası</span>
                            <input onClick={() => { inputAktiviteAyarlarama(inputAktif[1] ? -1 : 1) }} className="profil-input" placeholder={inputAktif[1] ? "" : telefon2} onChange={(e) => setTelefon(e.target.value)} value={telefon}></input>

                        </div>
                        <div style={{ display: "flex", flexDirection: "column", marginTop: "8px" }}>
                            <button onClick={() => hesapBilgileriniGuncelle()} className="profil-guncelle-buton">
                                Güncelle
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="profil-alt-div" style={{ marginTop: "0" }}>
                <div onClick={() => { menuPozisyonAyari(1) }} className="profil-alt-div-hesap">
                    <div className="profil-div-nokta"></div>
                    <span className="profil-div-title">Şifre Güncelle</span>
                </div>
                <div className="profil-div-menu">
                    <div style={{ display: "flex", flexFlow: "wrap", flexDirection: ekranGenisligi < 600 ? "column" : "row", columnGap: "10px", alignItems: "center" }}>
                        <div className="profil-flex-column">
                            <span className="profil-flex-text">Mevcut Şifreniz</span>
                            <input type={sifreGizle[0] ? "password" : "text"} className="profil-input" onChange={(e) => setPassword(e.target.value)} value={passwordT} autocomplete="new-password"></input>
                            <FontAwesomeIcon icon={sifreGizle[0] ? faEye : faEyeSlash} className="profil-sifre-ico" onClick={() => setSifreGizle([!(sifreGizle[0]), sifreGizle[1], sifreGizle[2]])} />
                        </div>
                        <div className="profil-flex-column">
                            <span className="profil-flex-text">Yeni Şifre</span>
                            <input type={sifreGizle[1] ? "password" : "text"} className="profil-input" onChange={(e) => setNewPassword(e.target.value)} value={newPassword} autocomplete="new-password3"></input>
                            <FontAwesomeIcon icon={sifreGizle[1] ? faEye : faEyeSlash} className="profil-sifre-ico" onClick={() => setSifreGizle([sifreGizle[0], !sifreGizle[1], sifreGizle[2]])} />
                        </div>
                        <div className="profil-flex-column">
                            <span className="profil-flex-text">Yeni Şifre Tekrar </span>
                            <input type={sifreGizle[2] ? "password" : "text"} className="profil-input" onChange={(e) => setConfirmPassword(e.target.value)} value={confirmPassword} autocomplete="new-password4"></input>
                            <FontAwesomeIcon icon={sifreGizle[2] ? faEye : faEyeSlash} className="profil-sifre-ico" onClick={() => setSifreGizle([sifreGizle[0], sifreGizle[1], !sifreGizle[2]])} />
                        </div>
                        <div  className="profil-flex-column">
                            <button onClick={() => sifreGuncelle()} className="profil-guncelle-buton">
                                Güncelle
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Profil

