import React from "react"
import "./navbar.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBell, faCaretDown, faUser, faGear, faCircleUser, faPlus, faRightFromBracket, faUsers } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from "react-router-dom"
import { Link } from "react-router-dom";
import CookieManager from "../../cookieManager"

class NavBar extends React.Component {

    state = {
        acik: false,
        kullaniciDegisimAktif: true
    }

    popupMenu = () => {
        this.setState({
            acik: !this.state.acik
        })
    }

    kullaniciDegisimAktifGuncelle = (durum) => {
        this.setState({
            kullaniciDegisimAktif: durum
        })
    }

    render() {
        return (
            <div className="navbar">
                <Link to={"/"}>
                    <img src={require("../assets/navbar-img/antech-logo.png")} alt="Antech Logo" id="navbar-img" />
                </Link>
                <div className="kullanici-menu">
                    <div id="navbar-bildirim-div">
                        <div>
                            <span>0</span>
                        </div>
                        <FontAwesomeIcon icon={faBell} style={{ height: "18px" }} />
                    </div>
                    <div className="navbar-profil" onClick={this.popupMenu}>
                        <div className="navbar-profil-ico-div">
                            <FontAwesomeIcon icon={faUser} className="navbar-profil-ico" />
                        </div>
                        <p className="navbar-kullanici-adi" >{CookieManager.getCookie("kullaniciAdi")}</p>
                        <FontAwesomeIcon icon={faCaretDown} />
                    </div>
                    {
                        this.state.acik &&
                        <div id="navbar-popup">
                            <div className="navbar-popup-div">
                                <p className="profil-popup-kisi">Kişi 1</p>:
                            </div>
                            <div className="profil-popup-gecis-div">
                                <p className="profil-popup-kullaniciAdi">{CookieManager.getCookie("kullaniciAdiSoyadi")}</p>
                                <p className="profil-popup-kullaniciAdi">{CookieManager.getCookie("kullaniciAdi")}</p>
                                <p>{CookieManager.getCookie("email") !== null ? CookieManager.getCookie("email") : "-"}</p>
                                <div onClick={() => this.props.navigate("/profil")} className="butonddd">
                                    <FontAwesomeIcon icon={faGear} className="profil-popup-ico" /><span className="profil-popup-ico-text">Hesabınızı Yönetin</span>
                                </div>
                                <div onClick={this.props.cikisYap} className="profil-popup-alt-ico butonddd">
                                <FontAwesomeIcon icon={faRightFromBracket} className="profil-popup-ico" /><span className="profil-popup-ico-text">Çıkış</span>
                            </div>
                            </div>
                            {/* <div className="profil-popup-alt-div">
                                <div className="profil-popup-alt-div-diger">
                                    <span>Diğer profiller</span>
                                    <FontAwesomeIcon icon={faUsers} style={{ height: "14px" }} />
                                </div>
                                {
                                    JSON.parse(CookieManager.getCookie("hesaplar")).ekliHesaplar.map((e, i) => {
                                        return (
                                            <div onClick={() => { this.props.hesapDegistirGuncelle(true, e) }} style={{ height: "35px", margin: (i === 0 ? 10 : 2) + "px auto 0px auto", display: "flex", alignItems: "center", padding: "0px 0px 0px 5%" }} className="butonddd">
                                                <FontAwesomeIcon icon={faCircleUser} style={{ color: "#" + (Math.floor(Math.random() * 16777215).toString(16)), height: "23px" }} /><span className="profil-popup-kullaniciAdi-alt">{e.kullaniciAdi}</span>
                                            </div>
                                        )
                                    })
                                }

                                <div onClick={() => { this.props.yeniKayitiGuncelle(true) }} className="profil-popup-alt-ico butonddd">
                                    <FontAwesomeIcon icon={faPlus} className="profil-popup-ico" /><span className="profil-popup-ico-text">Ekle</span>
                                </div>

                            </div> */}
                            
                            <div className="profil-popup-user-ico-div">
                                <FontAwesomeIcon icon={faUser} className="profil-popup-user-ico" />
                            </div>
                        </div>
                    }
                </div>
            </div>
        )
    }
}


export default function (props) {
    const navigate = useNavigate();
    return <NavBar {...props} navigate={navigate} />;
}