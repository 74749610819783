import React, { useEffect, useState } from "react"
import { faGear, faRotateLeft,faChartLine, faWaveSquare } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import "./manuel-on-taraf.css"
import { Link } from "react-router-dom"
import { 
    isletimModu,
    mode,
    state,
    unitSelection2 as unitSelection,
    icolarMod,
    icolarState
 } from "./dictionaries"

const ManuelOnTarafi = (props)=>{

    // console.log(props)
    let unit = {
        "0": "4-20 mA",
        "1": "0-20 mA",
        "2": "20-4 mA",
        "3": "20-0 mA"
    }

    let flexDirection = props.ekranGenisligi < 520 ? "column" : "row" 

    return(
        <>
        <div className="cihaz-bilgi-kart-icerik-cerceve2">
            <div className="nova-bilgi-kart-ust">
                <div className="nova-isletim-modu">
                    İşletim Modu : {isletimModu[props.veriler["operatingMode"]]}
                   { props.veriler["operatingMode"] != "0" && <div>Analog Giriş Modu : {unit[props.veriler["analogInputType"]]}</div> }
                </div>
            </div>
            <div className="nova-bilgi-kart-orta">
                <div className="vurus-sayisi">
                    <div className="vurus-ust">
                        Vuruş Oranı
                    </div>
                    <hr className="vurus-cizgi"></hr>
                    <div className="vurus-alt">
                        {props.veriler["strokeRate"]} {unitSelection[props.veriler["strokeRateUnitSelection"]]}
                    </div>
                </div>
                {
                    props.veriler["operatingMode"] != "0" &&
                    <div className="analog">
                    <div className="vertical"></div>
                    <div className="vurus-sayisi analog-vurus-sayisi">
                        <div className="vurus-alt analog-vurus-alt">
                            <span>{props.veriler["mA"]} mA</span>
                        </div>
                    </div>
                </div>
                }
            </div>
            <div>
                <hr className=""></hr>
                <div className="icos">
                    <div className="calisma-modux">
                        <div>Çalışma Modu : <span>{mode[props.veriler["runMode"]]}</span></div>
                        <span>{icolarMod[props.veriler["runMode"]]} </span>
                    </div>
                    <div className="calisma-modux">
                        <div>Durum : <span>{state[props.veriler["runModeState"]]}</span></div>
                        <span>{icolarState[props.veriler["runModeState"]]} </span>
                    </div>
                </div> 
            </div>
            <div className="nova-bilgi-kart-alt">
                <div className="kapasite-limit" style={{flexDirection: flexDirection, columnGap: "50px"}}>
                    <div>Kapasite Alt Limit : <span>{props.veriler["strokeRateLowLimit"]} {unitSelection[props.veriler["strokeRateUnitSelection"]]}</span> </div>
                    <div>Kapasite Üst Limit : <span>{props.veriler["strokeRateHighLimit"]} {unitSelection[props.veriler["strokeRateUnitSelection"]]}</span> </div>
                </div>
            </div>
        </div>
        <Link id="cihaz-on-taraf-grafik-ico" to={"/grafik/" + window.location.pathname.split("r/")[1]}>
            <FontAwesomeIcon icon={faChartLine}/>
        </Link>
        </>
    )
}

export default ManuelOnTarafi