import React, { useState } from "react"
import axios from "axios"
import "./login.css"
import EnelsaLogo from "../../assets/logo-enelsa.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEyeSlash, faEye, faCircleUser, faRightFromBracket } from "@fortawesome/free-solid-svg-icons"
import CookieManager from "../../cookieManager"

function kullaniciBilgileri(kullaniciAdi){
    let hesaplar = JSON.parse(CookieManager.getCookie("hesaplar"))
    let dondurulecekSifre = ""
    for(let hesap of hesaplar.ekliHesaplar){
        if(hesap.kullaniciAdi !== kullaniciAdi) continue
        dondurulecekSifre = hesap.kullaniciSifresi
    }
    return dondurulecekSifre
}

const LoginPopup = (props)=>{

    let islemGerceklesitrme = props.degisecekHesap === "" || props.degisecekHesap === undefined || props.degisecekHesap === null

    const [girisEkraniBool, setGirisEkraniBool] =  useState(props.tokenKontrol)
    const [kullaniciAdi, setKullaniciAdi] =  useState(islemGerceklesitrme ? "" : props.degisecekHesap)
    const [kullaniciSifresi, setKullaniciSifresi] = useState(islemGerceklesitrme ? "" : kullaniciBilgileri(props.degisecekHesap))
    const [sifreGizle, setSifreGizle] = useState(true)
    const [beniHatirla, setBeniHatirla] = useState(islemGerceklesitrme ? false : kullaniciBilgileri(props.degisecekHesap) ? true : false)
    const [hata, setHata] = useState(false)
    const [show, setShow] = useState(true)

    const girisYap = async (kullaniciAdi, kullaniciSifresi, beniHatirla) => {
        let hesaplar = CookieManager.getCookie("hesaplar");
        CookieManager.deleteCookie("token")
        hesaplar = hesaplar ? JSON.parse(hesaplar) : { anaHesap: {}, ekliHesaplar: [] };
    
        if (kullaniciAdi === "" || kullaniciSifresi === "" || (hesaplar.anaHesap.kullaniciAdi === kullaniciAdi && props.tokenKontrol))
            return;
    
        const res = await axios.put("https://api.enelsa.com:5015/api/login", JSON.stringify({ kullaniciAdi: kullaniciAdi, kullaniciSifresi: kullaniciSifresi }), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    
        // return
        if (res.data.giris === "Başarılı") {
            if (hata) setHata(false);
            
            let kaydedilecekKullaniciAdi = kullaniciAdi;
            let kaydedilecekKullaniciSifresi = "";
            if (beniHatirla) kaydedilecekKullaniciSifresi = kullaniciSifresi;
    
            let hesap = { kullaniciAdi: kaydedilecekKullaniciAdi, kullaniciSifresi: kaydedilecekKullaniciSifresi, dToken: "", token: res.data.token };
    
            if (props.tokenKontrol || hesaplar.anaHesap.kullaniciAdi !== kullaniciAdi) {
                let transferEdilecekAnaHesap = JSON.parse(JSON.stringify(hesaplar.anaHesap));
                let takip = 0;
                for (let h of hesaplar.ekliHesaplar) {
                    if (h.kullaniciAdi === kullaniciAdi) {
                        hesaplar.ekliHesaplar.splice(takip, 1);
                    }
                    takip++;
                }
                if (transferEdilecekAnaHesap.kullaniciAdi !== "")
                    hesaplar.ekliHesaplar.push(transferEdilecekAnaHesap);
            }
    
            hesaplar.anaHesap = hesap;
            // console.log(res.data.kullaniciBilgileri)
            CookieManager.setCookie("hesaplar", JSON.stringify(hesaplar), beniHatirla ? 30 : null);  // 30 gün için sakla veya oturum süresince
            CookieManager.setCookie("kullaniciBilgileri", res.data.kullaniciBilgileri, beniHatirla ? 30 : null);
            CookieManager.setCookie("id", res.data.kullaniciBilgileri.kullaniciId, beniHatirla ? 30 : null);
            CookieManager.setCookie("phone", res.data.kullaniciBilgileri.phone, beniHatirla ? 30 : null)
            CookieManager.setCookie("kullaniciAdi", kaydedilecekKullaniciAdi, beniHatirla ? 30 : null);
            CookieManager.setCookie("email", res.data.kullaniciBilgileri.email, beniHatirla ? 30 : null)
            CookieManager.setCookie("kullaniciAdiSoyadi", res.data.kullaniciBilgileri.kullaniciAdiSoyadi, beniHatirla ? 30 : null)
            CookieManager.setCookie("kullaniciAdi", res.data.kullaniciBilgileri.kullaniciAdi, beniHatirla ? 30 : null)

            props.loginAyarlari({ token: res.data.token, tarih: Math.floor((new Date()).getTime() / 1000 / 60 / 60) });
            window.location.replace("/anasayfa");
            setShow(false);
            return true;
        }
    
        setHata(true);
        return false;
    }

    const kullaniciyiSec = async (hesap)=>{
        if(!await girisYap(hesap.kullaniciAdi,hesap.kullaniciSifresi,hesap.kullaniciSifresi ? true : false))
        {
            setKullaniciAdi(hesap.kullaniciAdi)
            setKullaniciSifresi(hesap.kullaniciSifresi)
            setBeniHatirla(hesap.kullaniciSifresi ? true : false)
            setGirisEkraniBool(true)
        }
    }

    const secimEkrani = ()=>{
        return(
            <>
                <span style={{margin: "0px auto 15px auto", fontSize: "16px", color: "#5f6368"}}>Bir Hesap Seçin</span>
                {
                    JSON.parse(CookieManager.getCookie("hesaplar")).anaHesap.kullaniciAdi !== "" &&
                        <div onClick={()=>{kullaniciyiSec(JSON.parse(CookieManager.getCookie("hesaplar")).anaHesap)}} style={{width: "257px", height: "35px", margin: "0px auto 0px auto", display: "flex", alignItems: "center", justifyContent: "start", borderBottom: "1px solid rgba(192, 192, 192, 0.667)", borderRadius: "0px"}} className="butonddd">
                            <FontAwesomeIcon icon={faCircleUser} style={{color: "#"+(Math.floor(Math.random()*16777215).toString(16)), height: "23px", marginLeft: "0px"}}/><span style={{color: "#5f6368", marginLeft: "10px", fontSize: "13px"}}>{JSON.parse(CookieManager.getCookie("hesaplar")).anaHesap.kullaniciAdi}</span>
                        </div>
                }
                {
                    JSON.parse(CookieManager.getCookie("hesaplar")).ekliHesaplar.map(e=>{
                        return(
                            <div onClick={()=>{kullaniciyiSec(e)}} style={{width: "257px", height: "35px", margin: "5px auto 0px auto", display: "flex", alignItems: "center", justifyContent: "start", borderBottom: "1px solid rgba(192, 192, 192, 0.667)", borderRadius: "0px"}} className="butonddd">
                                <FontAwesomeIcon icon={faCircleUser} style={{color: "#"+(Math.floor(Math.random()*16777215).toString(16)), height: "23px", marginLeft: "0px"}}/><span style={{color: "#5f6368", marginLeft: "10px", fontSize: "13px"}}>{e.kullaniciAdi}</span>
                            </div>
                        )
                    })
                }
                <div onClick={props.cikisYap} style={{width: "257px", height: "35px", margin: "5px auto 0px auto", display: "flex", alignItems: "center", justifyContent: "start", borderRadius: "0px"}} className="butonddd">
                    <FontAwesomeIcon icon={faRightFromBracket} style={{color: "#5f6368", height: "18px", marginLeft: "3px"}}/><span style={{color: "#5f6368", marginLeft: "10px", fontSize: "13px"}}>Tüm Hesaplardan Çıkış Yap</span>
                </div>
            </>
        )
    }

    const girisEkrani = ()=>{
        return(
            <>
                <input value={kullaniciAdi} onChange={(e)=>props.hesapDegistir ? "" : setKullaniciAdi(e.target.value)} disabled={props.hesapDegistir} className="login-screen-input" placeholder="Kullanıcı Adı"/>
                <div style={{position: "relative"}}>
                    <input value={kullaniciSifresi} onChange={(e)=>setKullaniciSifresi(e.target.value)} className="login-screen-input" style={{marginTop: "10px"}} placeholder="Şifre" type={sifreGizle ? "password" : "input"}/>
                    <FontAwesomeIcon icon={sifreGizle ? faEye : faEyeSlash} id="sifre-ico" onClick={()=>setSifreGizle(!sifreGizle)}/>
                </div>
                <div id="beni-hatirla" onClick={()=>setBeniHatirla(!beniHatirla)} style={{width: "110px"}}>
                    <input checked={beniHatirla} type="checkbox" readOnly/>
                    <span>Şifreyi Hatırla</span>
                </div>
                <div style={{display: "flex", justifyContent: "space-evenly"}}>
                    {!props.tokenKontrol && <div id="login-screen-giris-buton" onClick={()=>{setGirisEkraniBool(false)}}>
                        <span style={{borderColor: "#ee6b6e", width: "60px", textAlign: "center", color: "#ee6b6e"}}>Geri Git</span>
                    </div>}
                    <div id="login-screen-giris-buton" onClick={()=>{girisYap(kullaniciAdi, kullaniciSifresi, beniHatirla)}}>
                        <span style={{width: "60px", textAlign: "center"}}>Giriş Yap</span>
                    </div>
                </div>
                <span style={{color: "red", fontSize: "14px", position: "absolute", bottom: "60px", display: hata ? "block" : "none"}} >Hatalı Kullanıcı Adı veya Şifre</span>
            </>
        )
    }

    return(
        <div onKeyDown={(e)=>{if(e.key === "Enter"){girisYap(kullaniciAdi, kullaniciSifresi, beniHatirla)} }} className="login-popup">
            <div onClick={()=>{if(props.yeniKayit) props.yeniKayitiGuncelle(false); if(props.hesapDegistir) props.hesapDegistirGuncelle(false,"")}} style={{height: "100vh", width: "100vw", backgroundColor: "#263b4a7e", position: "absolute"}}></div>
            <div style={{margin: "auto", position: "relative"}} className="deneme">
                <div id="login-screen-pencere">
                    <img src={EnelsaLogo} alt="enelsa-logo" id="login-screen-img"/>
                    <div id="login-screen-girisi-div" style={{marginTop: (girisEkraniBool ? 85 : 35) + "px"}}>
                        {girisEkraniBool ? girisEkrani() : secimEkrani()}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LoginPopup