export const alarms = [
    "Kanal 1 Ölçülemiyor","Kanal 1 Değer Düşük","Kanal 1 Değer Yüksek","Kanal 2 Ölçülemiyor","Kanal 2 Değer Düşük","Kanal 2 Değer Yüksek",
    "Kanal 3 Ölçülemiyor","Kanal 3 Değer Düşük","Kanal 3 Değer Yüksek","Kanal 4 Ölçülemiyor","Kanal 4 Değer Düşük","Kanal 4 Değer Yüksek",
    "Kanal 5 Ölçülemiyor","Kanal 5 Değer Düşük","Kanal 5 Değer Yüksek","Kanal 6 Ölçülemiyor"
];

export const alarms2 = [
    "Kanal 6 Değer Düşük","Kanal 6 Değer Yüksek","Role 1 Zaman Aşımı","Role 2 Zaman Aşımı","Role 3 Zaman Aşımı",
    "Role 4 Zaman Aşımı","Role 5 Zaman Aşımı","An 1 Zaman Aşımı","An 2 Zaman Aşımı","An 3 Zaman Aşımı","An 4 Zaman Aşımı",
    "Akış Yok","1.Tank Svy Düşük","2.Tank Svy Düşük","Harici Alarm","Rezerve"
];
