import React from "react"
import AnalogCikis from "./analog-cikis/analog-cikis"
import Role from "./role/role"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChartLine } from "@fortawesome/free-solid-svg-icons"
import "./cihaz-on-taraf.css"
import { Link } from "react-router-dom"
import { faBell } from "@fortawesome/free-regular-svg-icons"
import { grafikYetkisi } from '../cihaz';

const CihazOnTarafi = (props)=>{
    const cikisLariGoster = (cikislar, cikisTipi)=>{
        let donenGorseller = []
        Object.keys(cikislar).forEach(e=>{
            donenGorseller.push(cikisTipi === 1 ? <Role cikisBilgisi={cikislar[e]} kacinciRoleOlduguBilgisi={e} popupGoster={props.popupGoster} key={Math.random(10)+"1"}/> : <AnalogCikis cikisBilgisi={cikislar[e]} kacinciRoleOlduguBilgisi={e} popupGoster={props.popupGoster} key={Math.random(10)+"1"}/>) 
        })
        return donenGorseller
    }
    let kanalDurumu = "stabil"
    if (parseFloat(props.veriler.kanalDegeri) < parseFloat(props.veriler.kanalMinDegeri) || parseFloat(props.veriler.kanalDegeri) > parseFloat(props.veriler.kanalMaxDegeri)) {
        kanalDurumu = "sorunlu"
    } else if (parseFloat(props.veriler.limitler.kanalAltLimit) >= parseFloat(props.veriler.kanalDegeri) || parseFloat(props.veriler.limitler.kanalUstLimit) <= parseFloat(props.veriler.kanalDegeri)) {
        kanalDurumu = "alarmda"
    }
    // // // console.log(parseFloat(props.veriler.limitler.kanalUstLimit) >= parseFloat(props.veriler.kanalDegeri))
    let kanalDegeri = parseFloat(props.veriler.kanalDegeri) < parseFloat(props.veriler.kanalMinDegeri) ? "---" : parseFloat(props.veriler.kanalDegeri) > parseFloat(props.veriler.kanalMaxDegeri) ? "+++" : props.veriler.kanalDegeri
    return(
        <>
            <div id="cihaz-on-taraf">
                <div style={{display: "flex", justifyContent: "center"}}>
                    <div id="cihaz-on-taraf-kanal-degeri" style={{display: "flex"}} >
                        <span style={{color: kanalDurumu === "sorunlu" ? "rgb(163, 1, 1)" : kanalDurumu === "alarmda" ? "yellow" : props.renk, marginRight: "5px"}}>{kanalDegeri}</span> <span> {props.veriler.kanalBirimi}</span>
                    </div>
                    {kanalDurumu !== "stabil" && <div style={{color:kanalDurumu === "sorunlu" ? "rgb(163, 1, 1)" : kanalDurumu === "alarmda" ? "yellow" : props.renk, marginLeft: "5px"}}>
                        <FontAwesomeIcon icon={faBell} shake/>
                    </div>}
                </div>
                <div id="cihaz-on-taraf-cikis-birimleri">
                    {["kanalRoleleri", "kanalAnalogCikislari"].map((tip,i)=>{
                        return(
                            Object.keys(props.veriler[tip]).length !== 0 && 
                                <div className="cihaz-on-taraf-cikis-biri" key={Math.random(10)+"1"}>
                                    {cikisLariGoster(props.veriler[tip], i+1).map((e) => {return e})}
                                </div>
                        )
                    })}
                </div>
            </div>
            {grafikYetkisi && (
            <Link id="cihaz-on-taraf-grafik-ico" to={"/grafik/" + window.location.pathname.split("r/")[1]} state={{ isim: props.veriler.ismi}}>
                <FontAwesomeIcon icon={faChartLine}/>
            </Link>)}
        </>
    )
}

export default CihazOnTarafi