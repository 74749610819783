import axios from "axios"
import CookieManager from "../../../../../../cookieManager"
const origin = "https://api.enelsa.com:5015"

let gonderilecekData = {
    id: CookieManager.getCookie("id")
}
class ConfigClient {
    //a Mixin class for providing requests to access server via axios requests that return promise
    static lock(token) {
        return axios.post(origin + "/api/verificationExpirationTimes", gonderilecekData, {
            headers: {
                "Content-Type": "application/json",
                "authorization": "Bearer " + CookieManager.getCookie("token"),
            },
        })
    } 
    static approveMail(verificationCode, token) {
        let gonderilecek = {
            id: CookieManager.getCookie("id"),
            verificationCode: verificationCode,
            kullaniciAdi: CookieManager.getCookie("kullaniciAdi")
        }
        return axios.put(origin + "/api/sendVerificationMail",
            gonderilecek,
        {
           headers: {
               "Content-Type": "application/json",
               "authorization": "Bearer " + CookieManager.getCookie("token")
           }
        })
    }
    
    static generateMail(token) {
        let gonderilecek = {
            id: CookieManager.getCookie("id"),
            mail: CookieManager.getCookie("email"),
            kullaniciAdi: CookieManager.getCookie("kullaniciAdi"),
            phone: CookieManager.getCookie("phone")
        }
        return axios.post(origin + "/api/sendVerificationMail",gonderilecek, {
            headers: {
                "Content-Type": "application/json",
                "authorization": "Bearer " + CookieManager.getCookie("token")
            }
        })
    }
    static getExpiration(token) {
        return axios.get(origin + "/api/verificationExpirationTimes",
        {
           headers: {
               "Content-Type": "application/json",
               "authorization": "Bearer " + CookieManager.getCookie("token")
           }
        }) 
    }

    
}
export default ConfigClient
    