import React from "react";
import "./menu.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faHouse, faMicrochip, faRss, fa1, fa2, faSquare, faGear, faPenNib, faCircleQuestion, faUser, faMoneyBillTransfer, faUsersGear, faUsersRectangle, faCirclePlus, faCircleCheck } from '@fortawesome/free-solid-svg-icons'
import { Link } from "react-router-dom";
import CookieManager from "../../cookieManager";

const ustMenuBasliklari = ["Ayarlar", "Bize Ulaşın", "Yardım"]
const ustMenuSigeleri = [faGear, faPenNib, faCircleQuestion]

class Menu extends React.Component
{
    state = {
        fareninUstundeOlduguKompanent: "-",
        cihazlarPenceresiAcik: false,
        ekMenuDurum: false
    }
    classBelirle(konum){
        let mevcutKonum = this.props.konum
        if(konum === mevcutKonum && this.state.fareninUstundeOlduguKompanent !== konum && this.state.fareninUstundeOlduguKompanent !== "-")
        {
            return  "menu-item-selected-hover"
        }
        return konum === mevcutKonum ? "menu-item-selected" : "menu-item"
    }
    fareKompanentinUstuneGeldi(konum){
        this.setState(
            {
                fareninUstundeOlduguKompanent: konum
            }
        )
    }
    fareKompanentinUstundenGitti(){
        this.setState(
            {
                fareninUstundeOlduguKompanent: "-"
            }
        )
    }
    ekMenuAcKapa = ()=>{
        this.setState(
            {
                ekMenuDurum: !this.state.ekMenuDurum
            }
        )
    }

    yanMenuButon = (sayfa, icon)=>{
        let sayfaText = sayfa == "lisans" ? "Kredi İşlemleri" : sayfa == "kullanici" ? "Kullanıcı İşlemleri" : sayfa == "cihazekle" ? "Cihaz Ekle": sayfa == "cihazonay" ? "Cihaz Onay" : sayfa
        return(
            <div style={{fontSize: "14px", width: "90%", margin: "0px auto"}}>
                <Link className="yan-menu-link" to={"/"+sayfa} onMouseEnter={() => this.fareKompanentinUstuneGeldi(sayfa)} onMouseLeave={() => this.state.cihazlarPenceresiAcik  ? this.fareKompanentinUstuneGeldi("cihazlar") : this.fareKompanentinUstundenGitti()}>
                    <div style={{justifyContent: this.props.menuGenisligi > 120 ? "flex-start" : "center"}} className={this.classBelirle(sayfa) + " yan-menu-item"}> 
                        <FontAwesomeIcon icon={icon} className="yan-menu-icon"/>
                        <p style={{display: this.props.menuGenisligi > 130 ? "inline-block" : "none"}}>{sayfaText.charAt(0).toUpperCase() + sayfaText.slice(1)}</p>
                    </div>
                </Link>
            </div>
        )
    }

    render(){
        return(
            <div style={{width: this.props.menuGenisligi + "px", transition: "all 0.5s", whiteSpace: "nowrap"}} className="menu">
                <div onClick={this.ekMenuAcKapa} style={{width: this.props.menuGenisligi + "px",height: "118px"}} id="menuKullaniciPenceresi">
                    <div style={{display:"flex", overflow: "hidden", justifyContent: "center", alignItems: "end", margin: this.props.menuGenisligi === 220 ? "15px 0px 0px 20px" : "auto", width:"35px", height:"35px", borderRadius: "100px", backgroundColor: "#ad42ba"}}>
                        <FontAwesomeIcon icon={faUser} style={{height: "30px", marginTop: "10px", color: "#e6c6e9"}}/>
                    </div>
                    {
                        this.props.menuGenisligi === 220 &&
                        <>
                            <span style={{alignSelf: "flex-end", marginRight: "15px", color: this.state.ekMenuDurum ? "white" : "#E4E7EB", rotate: this.state.ekMenuDurum ? "90deg": "0deg", transition: "all 0.5s"}}>{">"}</span>
                            <div style={{marginLeft: "15px", fontSize: "12px", color: "#E4E7EB"}}>{CookieManager.getCookie("kullaniciAdiSoyadi")}</div>
                        </>
                    }
                </div>

                <div style={{height: this.state.ekMenuDurum ? "155px" : "0px"}} id="yan-menu-div-temel">
                    {
                        Array.from(Array(3).keys()).map(e=>{
                            return(
                                <div className="yan-menu-div" key={e}>
                                    <Link className="yan-menu-link">
                                        <div  style={{justifyContent: this.props.menuGenisligi > 120 ? "flex-start" : "center"}} className="menu-item yan-menu-item"> 
                                            <FontAwesomeIcon icon={ustMenuSigeleri[e]} className="yan-menu-icon"/>
                                            <p style={{fontSize: "14px", display: this.props.menuGenisligi > 130 ? "inline-block" : "none"}}>{ustMenuBasliklari[e]}</p>
                                        </div>
                                    </Link>
                                </div>
                            )
                        })
                    }
                </div>

                <div className="yan-menu-ayar">
                    <p style={{display: this.props.menuGenisligi > 130 ? "inline-block" : "none"}}>ANTECH KONTROL</p> 
                    <FontAwesomeIcon icon={faBars} style={{margin: this.props.menuGenisligi === 60 ? "auto" : "auto 0px auto 40px"}} className="yan-menu-link" onClick={this.props.menuGenisligiAyarla}/>
                </div>

                {this.yanMenuButon("anasayfa",faHouse)}
                {this.yanMenuButon("cihazekle",faCirclePlus)}
                {this.yanMenuButon("cihazonay",faCircleCheck)}

                <div id="cihazlar-ana-div"
                onClick={()=>{this.setState({cihazlarPenceresiAcik: !this.state.cihazlarPenceresiAcik})}} className={this.classBelirle("cihazlar")} 
                onMouseEnter={() => this.fareKompanentinUstuneGeldi("cihazlar")} 
                onMouseLeave={() => this.state.cihazlarPenceresiAcik  ? "this.fareKompanentinUstundenGitti()" : this.fareKompanentinUstundenGitti()}>
                    <div style={{borderRadius: this.state.cihazlarPenceresiAcik ? "10px 10px 0px 0px" : "10px 10px 10px 10px", 
                                 justifyContent: this.props.menuGenisligi > 120 ? "flex-start" : "center", 
                                 backgroundColor: (this.state.cihazlarPenceresiAcik || this.props.konum === "cihazlar") ? (this.state.fareninUstundeOlduguKompanent === "cihazlar" || this.state.fareninUstundeOlduguKompanent === "-" ? "white" : "rgb(129, 129, 129)") : this.state.fareninUstundeOlduguKompanent === "cihazlar" ? "white" : "transparent"}} 
                    className={this.classBelirle("cihazlar") + " yan-menu-item"}> 
                        <FontAwesomeIcon icon={faMicrochip} className="yan-menu-icon" 
                        style={{color: this.state.cihazlarPenceresiAcik || this.props.konum === "cihazlar" ? "#2D353C" : this.state.fareninUstundeOlduguKompanent === "cihazlar" ? "#2D353C" : "#F7FBFF"}}/> 
                        <p style={{display: this.props.menuGenisligi > 110 ? "inline-block" : "none", 
                                color: this.state.cihazlarPenceresiAcik || this.props.konum === "cihazlar" ? "#2D353C" : this.state.fareninUstundeOlduguKompanent === "cihazlar" ? "#2D353C" :"#F7FBFF"}}>Cihazlar
                        </p>
                    </div>
                </div>
                <div style={{transition: "all 0.5s", width: "90%", margin: "0px auto", overflow: "hidden", height: this.state.cihazlarPenceresiAcik ? "89px" : "0px"}}>
                    {
                        ["tema1","tema2"].map(e=>{
                            return(
                                <Link className="yan-menu-link" to="/cihazlar" onClick={()=>{localStorage.setItem("tema",e);this.props.temayiGuncelle(e) }} key={e}>    
                                    <div className="yan-menu-cihazlar-pencere" style={{borderWidth: e === "tema1" ? "0px 1px 0px 1px" : "0px 1px 1px 1px", borderRadius: e === "tema1" ? "0px" : "0px 0px 10px 10px",
                                    borderStyle: this.state.cihazlarPenceresiAcik ? "solid" : "none",
                                    borderColor: this.state.cihazlarPenceresiAcik  ? (this.state.fareninUstundeOlduguKompanent === "cihazlar" ? "#F7FBFF" : "rgb(129, 129, 129)") : "rgb(129, 129, 129)",
                                    justifyContent: this.props.menuGenisligi > 120 ? "flex-start" : "center"}}>
                                        <div>
                                            <FontAwesomeIcon icon={faSquare} className="yan-menu-icon yan-menu-cihazlar-pencere-link" style={{
                                            color: this.state.cihazlarPenceresiAcik  ? (this.state.fareninUstundeOlduguKompanent === "cihazlar" ? (this.props.tema === e ? "#005FAB" : "#F7FBFF") : "rgb(129, 129, 129)") : "rgb(129, 129, 129)"}}/>
                                            <FontAwesomeIcon icon={e === "tema1" ? fa1 : fa2} className="yan-menu-cihazlar-pencere-link2" style={{margin: "auto 0px auto " + (e === "tema1" ? "14px" : "13.5px"), color: this.props.tema === e ? "#F7FBFF" : "#2D353C"}}/>
                                        </div>
                                        <p style={{
                                        display: this.props.menuGenisligi > 130 ? "inline-block" : "none", 
                                        color: this.state.cihazlarPenceresiAcik  ? (this.state.fareninUstundeOlduguKompanent === "cihazlar" ? "#F7FBFF" : "rgb(129, 129, 129)") : "rgb(129, 129, 129)"}}>
                                            {e === "tema1" ? "Tema 1" : "Tema 2"}
                                        </p>
                                    </div>
                                </Link>
                            )})
                    }
                </div>
            </div>
        )
    }
}

export default Menu