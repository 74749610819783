import React, { useEffect, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faX, faWaveSquare } from "@fortawesome/free-solid-svg-icons"

const ProsesPopUp = (props) => {
    const programAdi = props.index
    const popupKonum = props.popupKonum

    // console.log(programAdi)s
    let hour = ""
    let minute = ""
    if(props.veriler.programs[programAdi].processStartHour != undefined) {
        let periodHourString = props.veriler.programs[programAdi].processStartHour.toString();
        hour = periodHourString.length === 1 ?  "0" + periodHourString : periodHourString

        let periodMinuteString = props.veriler.programs[programAdi].processStartMinute.toString();
        minute = periodMinuteString.length === 1 ?  "0" + periodMinuteString : periodMinuteString
    }

    let dakika = ""
    let saniye = ""
    let processTime = ""
    if(props.veriler.programs[programAdi].processTime != undefined){
        let dakikaa = Math.floor(props.veriler.programs[programAdi].processTime / 60);
        let dakikaString = dakikaa.toString();
        dakika = dakikaString.length === 1 ?  "0" + dakikaString : dakikaString

        let saniyee = props.veriler.programs[programAdi].processTime % 60;
        let saniyeString = saniyee.toString();
        saniye = saniyeString.length === 1 ?  "0" + saniyeString : saniyeString

        processTime = dakika + " : " + saniye
    }

    let background = props.veriler.programs[programAdi].processStartDayNumber != 0 ? "linear-gradient(45deg, rgba(19,126,0,0.7959558823529411) 0%, rgba(19,126,0,0.7987570028011204) 100%)" : "linear-gradient(90deg, rgba(66,73,79,1) 0%, rgba(121,121,121,0.7763480392156863) 63%)"
    return (
        <div className="cihaz-bilgi-kart-popup" style={{ display: "flex" }}>
            <div className="cihaz-bilgi-kart-popup-cerceve" style={{ padding: "15px", height: "100px", width: "auto", top: popupKonum + "px", background: background}}>
                <div className="cihaz-bilgi-kart-popup-icerk icerik">
                    <div className="popup-prg">
                        <div> PROGRAM {props.index+1}</div>
                        <span><FontAwesomeIcon style={{cursor:"pointer"}} icon={faX} onClick={props.kapat} /></span>
                    </div>
                    <div className="popup-proses-grid">
                        <div className="popup-proses">
                            <div>Proses Başlangıç Günü : <span>{props.veriler.programs[programAdi].processStartDayNumber}</span></div>
                            <div>Proses Vuruş Oranı : <span>{props.veriler.programs[programAdi].processStrokeRate} <FontAwesomeIcon icon={faWaveSquare}></FontAwesomeIcon>d</span></div>
                            <div>Proses Zamanı : <span>{processTime}</span></div>
                        </div>
                        <div className="popup-proses">
                            <div>Proses Başlangıç Saati : <span>{hour} : {minute}</span></div>
                            <div>Proses Vuruş Sayısı : <span>{props.veriler.programs[programAdi].processStrokeCount} <FontAwesomeIcon icon={faWaveSquare}></FontAwesomeIcon></span></div>
                            <div>Proses Kapasitesi : <span>{props.veriler.programs[programAdi].processCapacity} L</span></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProsesPopUp