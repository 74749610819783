import React, { useState, useEffect } from 'react';
import { MaterialReactTable } from 'material-react-table';
import { Box, Button } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { generateCsv } from 'export-to-csv';
import * as XLSX from 'xlsx';
import axios from "axios"
import jsPDF from 'jspdf';
import autotable from 'jspdf-autotable'
import "./tablo.css"
import CookieManager from '../../../cookieManager';
import { useNavigate } from 'react-router-dom';


const Tablo = (props) => {
  const [baslangic, setBaslangic] = useState(true)
  const [sutunlar, setSutunlar] = useState([])
  const [data, setData] = useState([])

  const csvDataIndir = () => {
    if (data.length == 0) return;
    const csvOptions = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      useBom: true,
      useKeysAsHeaders: false,
      filename: "rapor",
      headers: sutunlar.map((c) => c.header),
    };
    const csvExporter = new generateCsv(csvOptions);
    csvExporter.generateCsv(data);
  };

  const excelDatasiIndir = () => {
    if (data.length === 0) return;

    let excelData = data.map(d => {
      let keyler = Object.keys(d);
      let dondurulecekObje = { "ID": d[keyler[0]] };

      for (let i = 1; i < keyler.length; i++) {
        if (i === keyler.length - 1) {
          dondurulecekObje["Tarih"] = d[keyler[i]];
          continue;
        }

        // sutunlar dizisinin mevcut indeksi kontrol ediliyor
        if (sutunlar[i]) {
          dondurulecekObje[sutunlar[i].header] = d[keyler[i]];
        }
      }

      return dondurulecekObje;
    });

    const workSheet = XLSX.utils.json_to_sheet(excelData);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Rapor");

    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    XLSX.writeFile(workBook, "rapor.xlsx");
  };

  const pdfDataIndir = () => {
    if (data.length == 0) return;
    let doc = new jsPDF()
    doc.text("Rapor", 10, 10)
    doc.autoTable({
      columns: sutunlar.map(s => ({ header: s.header.split(" - ")[1], dataKey: s.accessorKey })),
      body: data
    })
    doc.save("rapor.pdf")
  }

  const dataGuncelle = async () => {
    // // console.log(window.location.pathname.split("tablo/")[1])
    let res = await axios.get("https://api.enelsa.com:5015/api/tablo/" + window.location.pathname.split("tablo/")[1], {
      headers: {
        'Content-Type': 'application/json',
        'authorization': 'Bearer ' + CookieManager.getCookie("token")
      }
    })

    let data = res.data
    // console.log(data)
    if (Object.keys(data).length == 0) {
      let location = window.location.href
      let seriNo = location.split("/")[2]
      let location2 = window.location.pathname.split("tablo/")[1]
      location = location2 + "/cihazlar/" + seriNo
      window.location.href = location
    } else {
      setSutunlar(data.sutunlar)
      setData(data.data)
    }
    // console.log(Object.keys(data).length == 0)

  }

  useEffect(() => {
    if (!baslangic) return
    dataGuncelle()
    setBaslangic(false)
  }, [baslangic])


  function BackButton() {
    const navigate = useNavigate();

    const goBack = () => {
      navigate(-1); // Bir önceki sayfaya gider
    };

    return (
      <span className='backButtonDiv'>
        <button onClick={goBack} className='backButtonStyle'>
          ←
        </button>
      </span>
    );
  }

  let ekranGenisligi = props.ekranGenisligi < 641 ? props.ekranGenisligi : props.ekranGenisligi - props.menuGenisligi
  return (
    <>
      <div style={{ display: "flex", alignItems: "stretch", flexDirection: "column", width: "100%", overflowX: "hidden", backgroundColor: "#42494f" }}>
        <BackButton></BackButton>
        <div style={{ marginTop: 10, marginLeft: "auto", marginRight: 40, marginBottom: 5 }}>
          <Button
            color="primary"
            //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
            onClick={excelDatasiIndir}
            startIcon={<FileDownloadIcon />}
            variant="contained"
            style={{ marginLeft: 10, marginBottom: 5 }}
          >
            EXCEL
          </Button>
          <Button
            color="primary"
            //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
            onClick={pdfDataIndir}
            startIcon={<FileDownloadIcon />}
            variant="contained"
            style={{ marginLeft: 10, marginBottom: 5 }}
          >
            PDF
          </Button>
        </div>
        <div style={{ marginLeft: "auto", marginRight: "auto", width: ekranGenisligi - 50 }}>
          <MaterialReactTable
            columns={sutunlar}
            data={data}
            positionToolbarAlertBanner="bottom"
            enableDensityToggle={false}
            initialState={{ density: 'compact' }}
            muiTablePaperProps={{
              sx: {
                borderRadius: '10',
                border: '1px solid white',
              },
            }}
            renderTopToolbarCustomActions={({ table }) => (
              <Box
                sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
              >


              </Box>
            )}
          />
        </div>

        <div className='rapor-not'>
          <span id='rapor-not-span'><h3>NOT:</h3></span>
          <span>Bu sayfada sadece son 7 günün verileri görüntülenebilir. <br></br>
            7 günden daha eski verileri görebilmek için lütfen cihaz sayfasında; <b>Cihaz İşlemleri</b> menüsü <b>Cihaz Raporlama Ayarları</b> sekmesinden rapor almak istediğiniz süreyi girerek mail talebinde bulununuz.<br></br>
            Böylece, cihaz verileri raporu istediğiniz sıklıkta, Excel formatında mail olarak size iletilecektir.</span>
        </div>
      </div>
    </>
  );
};

export default Tablo;
