import React from "react"
import "./analog-cikis.css"

const AnalogCikis = (props)=>{

    // // console.log(props)
    let pozisyonHesplama = (pozisyonBilgisi)=>{
        if(pozisyonBilgisi < 10)
            return 0
        else if(pozisyonBilgisi > 90)
            return 80
        return pozisyonBilgisi - 10
    }

    let yuzde = props.cikisBilgisi.yuzde > 100 ? 100 : props.cikisBilgisi.yuzde
    yuzde = yuzde === null ? 0 : yuzde

    return(
        <div onClick={()=>props.popupGoster(props.cikisBilgisi)} className="analog-cikis-temel-pencere">
            <div className="analog-cikis-deger-txt">
                <span>SET2</span>
                <span>{props.cikisBilgisi.Set2}</span>
            </div>
            <div className="analog-cikis-yuzde">
                <div style={{left: pozisyonHesplama(yuzde) + "px"}} className="analog-cikis-yuzde-pozisyon"><span>{yuzde}%</span></div>
                <div className="analog-cikis-yuzde-bar">
                    <div style={{width: yuzde + "px"}}></div>
                </div>
                <span className="analog-cikis-cihaz-bilgisi">{props.kacinciRoleOlduguBilgisi} / {props.cikisBilgisi.Cihaz}</span>
            </div>
            <div className="analog-cikis-deger-txt">
                <span>SET1</span>
                <span>{props.cikisBilgisi.Set1}</span>
            </div>
        </div>
    )
}

export default AnalogCikis