import React from "react"
import Select from "react-select";
import "./multiSelect.css";

class MultiSelect extends React.Component {
  state = {
    selectedOption: null
  };
  handleChange = (selectedOption) => {
    this.setState({ selectedOption }, () =>
      (localStorage.setItem("selectedOption", JSON.stringify(this.state.selectedOption)))
      // // console.log(`Option selected:`, JSON.stringify(this.state.selectedOption))
    );
  };
  render() {
    const altKullanici = [];
    const gruplar = [];

    this.props.grupList.map((e,i) => 
      gruplar[i]= {value:this.props.grupList[i], label:this.props.grupList[i]}
    )

    const { selectedOption } = this.state;

    const customStyles = {
      option: (defaultStyles, state) => ({
        ...defaultStyles,
        color: state.isSelected ? "#000" : "#42494F",
        backgroundColor: state.isSelected ? "#fff" : "#fff",
        fontSize: "12px",
        zIndex: "9999"
      }),
  
      control: (defaultStyles) => ({
        ...defaultStyles,
        color: "#42494F",
        backgroundColor: "transparent",
        border: "1px solid aliceblue",
        borderRadius: "4px",
        boxShadow: "none",
        fontSize: "12px",
        minHeight: "15px",
        height: "18,5px",
        width: "250px"
      }),

      placeholder: (defaultStyles) => ({
        ...defaultStyles,
        padding: "0",
        color: "aliceblue",
      }),

      singleValue: (defaultStyles) => ({ ...defaultStyles, color: "#fff" }),
    };

    return (
      <>
      <Select isMulti isSearchable value={selectedOption} onChange={this.handleChange} options={gruplar} styles={customStyles} placeholder="Lütfen Seçiniz"/>
    </>
    );
  }
}

export default MultiSelect