import React from "react"
import { faCirclePause, faCirclePlay, faCircleStop } from "@fortawesome/free-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import "./role.css"

let icolar = {
            "Otomatik": <FontAwesomeIcon icon={faCirclePause} style={{color: "#FFF642", fontSize: "22px", marginLeft: "5px"}}/>,
            "Açık": <FontAwesomeIcon icon={faCirclePlay} style={{color: "#00D647", fontSize: "22px", marginLeft: "5px"}}/>,
            "Kapalı": <FontAwesomeIcon icon={faCircleStop} style={{color: "#FF5F85", fontSize: "22px", marginLeft: "5px"}}/>
        }

const roleFonksiyonIsmi = {
                            'Fonksiyon Yok':0,'Yükseltme Kontrol':1,'Düşürme Kontrol':2,'PID Yükseltme Kontrol':3,
                            'Ön Düşürme Kontrol':8, 'Oransal':9,'Sayıcı':10,'Periyodik Kontrol':11,'Alt Role':12,'Boost Feeder':13,
                            'PID Düşürme Kontrol':4, 'Periyodik Yükseltme Kontrol':5, 'Periyodik Düşürme Kontrol':6, 'Ön Yükseltme Kontrol':7,
                            'Slave Shaker':14, 'Alarm':15
                          }

const Role = (props)=>{
    return(
        <div onClick={()=>{props.popupGoster(props.cikisBilgisi)}} className="on-taraf-role-cerceve" >
            {icolar[props.cikisBilgisi["Çalışma Tipi"]]}
            <div className="on-taraf-role-orta-kisim">
                <span>Röle {props.kacinciRoleOlduguBilgisi}</span>
                <span>{props.cikisBilgisi.Cihaz}</span>
            </div>
            <img src={require("../img/"+roleFonksiyonIsmi[props.cikisBilgisi.Fonksiyon]+"-rly.png")} className="on-taraf-role-ico" alt={"role"}/>
        </div>
    )
}

export default Role