import React, { useEffect, useState } from "react"
import { faGear, faRotateLeft } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import SaltYukleme from "./salt-yukleme.js"
import SaltOnTaraf from "./salt-on-taraf.js"
import saltDataIslemleri from "./data-islemleri/salt-data"
import CookieManager from "../../../../../cookieManager.js"
const REGEXP_SPECIAL_CHAR =
    /[\!\#\$\%\^\&\*\)\(\+\=\.\<\>\{\}\[\]\:\;\'\"\|\~\`\_\-]/g

const SaltBilgiKartiInner = (props) => {
    let [cihazTarafBilgisi, setCihazTarafBilgisi] = useState(true)
    let [cihazDondurme, setCihazDondurme] = useState(true)
    let [cihazDatalari, setCihazDatalari] = useState([])
    let [timeOutIslemde, setTimeOutIslemde] = useState(false)
    let [componentOlusturuldu, setComponentOlusturuldu] = useState(false)

    useEffect(() => {
        const datalariOlustur = () => {
            saltDataIslemleri(CookieManager.getCookie("token")).then((e) => {
                if (e) {
                    if(JSON.stringify(cihazDatalari) != e.data) {
                        let a = JSON.parse(e.data)
                        setCihazDatalari(a)
                    }
                }       
            })
            setTimeOutIslemde(false)
        }
        if (!timeOutIslemde) {
            setTimeout(datalariOlustur, 5000)
            setTimeOutIslemde(true)
        }
        if (!componentOlusturuldu)
        setComponentOlusturuldu(true)        
    }, [timeOutIslemde, componentOlusturuldu])

    const pencereyiCevir = () => {
        setCihazDondurme(false)
        setTimeout(() => { setCihazTarafBilgisi(!cihazTarafBilgisi) }, 450)
        setTimeout(() => { setCihazDondurme(true) }, 700)
    }

    // console.log(cihazDatalari)

    let width = props.ekranGenisligi>790 ? "700px" : (props.ekranGenisligi>520) ? "500px" : "300px"
    let classNovaKart = props.ekranGenisligi > 790 ? "nova-bilgi-kart-cerceve" : (props.ekranGenisligi>520) ? "nova-bilgi-kart-cerceve tablet" : "nova-bilgi-kart-cerceve mobil"

    return (
        <>
            <div style={{ transform: cihazDondurme ? "rotateY(0deg)" : "rotateY(90deg)", backgroundColor: "#129490", width:width }} id="AA" className={classNovaKart}>
                <div className="nova-ico-div">
                    <FontAwesomeIcon icon={cihazTarafBilgisi ? faGear : faRotateLeft} className="cihaz-bilgi-kart-ust-taraf-ico" onClick={pencereyiCevir} />
                </div>
                { 
                cihazDatalari.mode != undefined ?
                    <SaltOnTaraf tokenBilgileri={props.tokenBilgileri}
                                    ekranGenisligi={props.ekranGenisligi}
                                    menuGenisligi={props.menuGenisligi}
                                    bildirimleriYonet={props.bildirimleriYonet}
                                    cihazDatalari={cihazDatalari}>
                    </SaltOnTaraf> : 
                    <SaltYukleme></SaltYukleme>
                } 
                
            </div>
        </>
    )
}


export default SaltBilgiKartiInner