import React, {useState, useEffect} from "react"
import "../nova/manuel-on-taraf.css"
import "./salt-on-taraf.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight, faArrowLeft } from "@fortawesome/free-solid-svg-icons"
import saltLogo from "../../../../assests/SALT IONIC A.png"
import hucreLogo from "../../../../assests/hucre.png"

const SaltOnTaraf = (props)=>{
    const [kanalSet, kanalSetAyar] = useState(true)
    const [isShown, setIsShown] = useState(false)
    const [isShown2, setIsShown2] = useState(false)
    let [cihazDatalari, setCihazDatalari] = useState([])

    useEffect(() => {
        setCihazDatalari(props.cihazDatalari)      
    }, [props])

    let mode = {
        0: "Manuel",
        1: "Sensör"
    }

    let status = {
        0: "Kapalı",
        1: "Açık",
        2: "Auto"
    }
    return(
        <>
        { kanalSet ? 
        <div className="cihaz-bilgi-kart-icerik-cerceve2" style={{justifyContent: "space-evenly"}}>
            <div className="nova-bilgi-kart-ust">
                <div className="nova-isletim-modu" style={{display: "flex", alignItems: "center" }}>
                    Cihaz Modu : {mode[cihazDatalari.mode]}
                </div>
            </div>
            
            <div className="nova-bilgi-kart-orta" style={{flexDirection: "column"}}>
                <div style={{display: "flex", flexDirection: "row", columnGap: "25px", marginBottom: "20px"}}>
                    <div className="calisma-modux" style={{fontSize: "25px", marginBottom: "20px"}}>
                        <div>Anlık Üretim </div> {cihazDatalari.currentOutput} %
                    </div>
                    <hr></hr>
                    <div className="calisma-modux" style={{fontSize: "25px", marginBottom: "20px"}}>
                        <div>Üretim Miktarı </div>{cihazDatalari.outputCount} g/L
                    </div>
                </div>
                <div className="salt-uretim">
                    <div className="calisma-modux" style={{width: "120px"}}>
                        <div>Üretim  </div><div>{status[cihazDatalari.outputStatus]}</div>
                    </div>
                    <div className="calisma-modux">
                        <div>Üretim Set  </div><div> {cihazDatalari.outputSet} %</div>
                    </div>
                </div>
            </div>
            {/* <hr style={{width: "350px"}}></hr> */}
            <div className="salt-alt">
                <div className="salt-role">
                    Röleler
                    <hr style={{width: "150px"}}></hr>
                    <div className="salt-role-inner">
                        <div>pH  </div>{status[cihazDatalari.ph]}
                    </div>
                    <div className="salt-role-inner">
                        <div>Filtre  </div>{status[cihazDatalari.filter]}
                    </div>
                    <div className="salt-role-inner">
                        <div>Aux  </div>{status[cihazDatalari.aux]}
                    </div>
                    <div className="salt-role-inner">
                        <div>Aux 2  </div>{status[cihazDatalari.auxTwo]}
                    </div>
                </div>
                <hr style={{marginTop: "30px"}}></hr>
                <div className="salt-role">
                    Sensörler
                    <hr style={{width: "160px"}}></hr>
                    <div className="salt-role-inner">
                        <div>pH Anlık  </div> {cihazDatalari.currentPh} pH
                    </div>
                    <div className="salt-role-inner">
                        <div>Cl Anlık  </div> {cihazDatalari.currentCl} mV
                    </div>
                    <div className="salt-role-inner">
                        <div>Su Sıcaklığı  </div> {cihazDatalari.waterTemp} C
                    </div>
                </div>
            </div>
        </div> : 
        <div className="cihaz-bilgi-kart-icerik-cerceve2" style={{justifyContent: "space-evenly"}}>
            <div className="salt-alt">
                <div className="salt-role">
                    <span style={{fontSize: "35px"}}>Hücre</span>
                    <hr style={{width: "250px"}}></hr>
                    <div className="salt-role-inner">
                        <div>Akım  </div> {cihazDatalari.current} A
                    </div>
                    <div className="salt-role-inner">
                        <div>Voltaj  </div> {cihazDatalari.voltage} V
                    </div>
                    <div className="salt-role-inner">
                        <div>Güç  </div> {cihazDatalari.power} W
                    </div>
                    <div className="salt-role-inner">
                        <div>Toplam Çalışma Süresi  </div> {cihazDatalari.workingDuration} saat
                    </div>
                </div>
            </div>
        </div>
        }
        <div style={{ width: "100%", display: "flex" }}>
                <div onClick={() => kanalSetAyar(!kanalSet)} style={{ width: "100%" }}>
                    <div style={{ display: "flex", 'justifyContent': "space-between" }}>
                        <>
                            <div style={{ opacity: isShown ? "1" : "0", display: "flex", alignItems: "flex-end", marginLeft: "10px", justifyContent: "flex-start" }}>
                                <span style={{ color: "aliceblue", 'fontSize': "x-small" }}>{kanalSet ? "" : "Genel Bilgiler"}</span>
                            </div>
                            <div style={{ display: "flex", marginLeft: kanalSet ? "47px" : "0", marginRight: kanalSet ? "0" : "80px" }}>
                                <FontAwesomeIcon onMouseEnter={() => setIsShown(true)} onMouseLeave={() => setIsShown(false)} style={{ marginTop: "2px", opacity: kanalSet ? "0" : "1" }} icon={faArrowLeft} className="cihaz-bilgi-kart-ust-taraf-ico tooltip" />
                                <span className="cihaz-bilgi-kart-ust-taraf-ico">- <>{kanalSet ? 1 : 2}</> -</span>
                                <FontAwesomeIcon onMouseEnter={() => setIsShown2(true)} onMouseLeave={() => setIsShown2(false)} style={{ marginTop: "2px", opacity: kanalSet ? "1" : "0" }} icon={faArrowRight} className="cihaz-bilgi-kart-ust-taraf-ico tooltip" />
                            </div>
                            <div style={{ opacity: isShown2 ? "1" : "0", display: "flex", alignItems: "flex-end", marginRight: "10px" }}>
                                <span style={{ color: "aliceblue", 'fontSize': "x-small" }}>{kanalSet ? "Hücre Bilgileri" : ""}</span>
                            </div>
                            {
                                kanalSet ? <img src={saltLogo} className="modal-alt-icon-salt" alt="saltionic"/> : <img src={hucreLogo} className="modal-alt-icon-salt" alt="saltionichücre" style={{bottom: "10px", right: "-85px"}} />
                            }
                        </>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SaltOnTaraf