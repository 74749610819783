import { React, useState } from "react"
import "./cihaz-arka-taraf-potansiyostatik.css"
import axios from "axios"
import CookieManager from "../../../../../../cookieManager"

function inputOlustur(baslik, valueDegeri, valueDegeriFonksiyonu, renk){
    return(
        <div className="arka-taraf-potansiyostatik-input">
            <span>{baslik}</span>
            <input className="arka-taraf-potansiyostatik-input2" style={{width: "169px", outline: "none", paddingLeft: "4px", backgroundColor: renk}} value={valueDegeri} onChange={(e)=>valueDegeriFonksiyonu(e.target.value)}/>
        </div>
    )
}

async function manuelTemizlikIslemleri(datalar, bildirimleriYonet, setPotasiyostatikManuelTemizlemede, geriSayim){
    let gonderilecekData = {
        manualStart: datalar.manualStart,
        recoveryState: datalar.recoveryState,
        number: datalar.number,
        seriNo: datalar.seriNo
    }

    try{
        const res = await axios.post('https://api.enelsa.com:5015/api/cihazAyar',  JSON.stringify(gonderilecekData), {
            headers: {
                'Content-Type': 'application/json',
                'authorization': 'Bearer ' + CookieManager.getCookie("token")
            }
        })
        setPotasiyostatikManuelTemizlemede(true)
        setTimeout(()=>{geriSayimYap(geriSayim[0],geriSayim[1],setPotasiyostatikManuelTemizlemede)},1000)
    }catch (e) {
        // console.log(e)
    }
}

function geriSayimYap(geriSayimDatasi, setGeriSayim, setPotasiyostatikManuelTemizlemede){
    let gecici  = geriSayimDatasi
    setGeriSayim(--gecici)
    if(gecici === 0)
    {
        setGeriSayim(60)
        setPotasiyostatikManuelTemizlemede(false)
        return
    }
    setTimeout(()=>{geriSayimYap(gecici, setGeriSayim, setPotasiyostatikManuelTemizlemede)},1000)
}

const CihazArkaTarafBrimler = (props)=>{
    let keyler = Object.keys(props.icerikVerileri)


    let [potasiyostatikManuelTemizlemede, setPotasiyostatikManuelTemizlemede] = useState(false)
    let [geriSayim, setGeriSayim] = useState(60)
    let [pencereAcik, setPencereAcik] = useState(false)
    let [manuelTemizlikModu, setManuelTemizlikModu] = useState(1)

    let varsayilanDegerler = {durum: props.icerikVerileri.durum, acikKenUzunlugu: 85}
    // let varsayilanDegerler = {fonksiyon: props.baslaSaat, offset: props.baslaSaat, set: props.baslaSaat, calismaTipi: props.baslaSaat, cihaz: props.baslaSaat, acikKenUzunlugu: null, tipi: null}


    return(
        <div className="arka-taraf-potansiyostatik-cerceve">
            <div style={{height: pencereAcik ? varsayilanDegerler.acikKenUzunlugu : 30 + "px"}} className="arka-taraf-potansiyostatik-alt-cerceve">
                <div className="arka-taraf-potansiyostatik-ust-cerceve" onClick={()=>setPencereAcik(!pencereAcik)}  style={{backgroundColor: props.renk2, transition: "all 0.5s"}}>
                    <span style={{marginLeft: "10px"}}></span>
                    <span style={{fontSize: "13px"}}>Potansiyostatik</span>
                    <span style={{rotate: (pencereAcik ? -1 : 1) * 90 + "deg", marginRight: "10px"}}>{">"}</span>
                </div>
                <div className="arka-taraf-potansiyostatik-icerik">
                    <span>Manuel Temizleme</span>
                    <div style={{display: "flex", marginTop: "5px", alignItems: "center"}}>
                        <span style={{marginRight: "3px", fontSize: "13px", lineHeight: "0px"}}>1</span>
                        <div onClick={()=>setManuelTemizlikModu(1)} style={{width: "20px", height: "20px", border: "2px solid " + props.renk2, borderRadius: "100%", marginRight: "10px", cursor: "pointer", display: "flex"}}>
                            {   
                                manuelTemizlikModu === 1 &&
                                    <div style={{height: "14px", width: "14px", backgroundColor: props.renk2, borderRadius: "100%", margin: "auto"}}></div> 
                            }
                        </div>
                        <span style={{marginRight: "3px", fontSize: "13px", lineHeight: "0px"}}>2</span>
                        <div onClick={()=>setManuelTemizlikModu(2)} style={{width: "20px", height: "20px", border: "2px solid " + props.renk2, borderRadius: "100%", marginRight: "10px", cursor: "pointer", display: "flex"}}>
                            {   
                                manuelTemizlikModu === 2 &&
                                    <div style={{height: "14px", width: "14px", backgroundColor: props.renk2, borderRadius: "100%", margin: "auto"}}></div> 
                            }
                        </div>
                        <span style={{marginRight: "3px", fontSize: "13px", lineHeight: "0px"}}>3</span>
                        <div onClick={()=>setManuelTemizlikModu(3)} style={{width: "20px", height: "20px", border: "2px solid " + props.renk2, borderRadius: "100%", marginRight: "auto", cursor: "pointer", display: "flex"}}>
                            {   
                                manuelTemizlikModu === 3 &&
                                    <div style={{height: "14px", width: "14px", backgroundColor: props.renk2, borderRadius: "100%", margin: "auto"}}></div> 
                            }
                        </div>
                        {
                            !potasiyostatikManuelTemizlemede ? 
                            <>
                                <div onClick={()=>manuelTemizlikIslemleri({ 
                                manualStart: 4, 
                                recoveryState: 4, 
                                number: props.kanal, 
                                seriNo: window.location.pathname.split("r/")[1] }, props.bildirimleriYonet, setPotasiyostatikManuelTemizlemede, [geriSayim, setGeriSayim])} className="arka-taraf-potansiyostatik-buton" style={{marginRight: "5px", marginTop: "0px"}}>
                                    <span>Durdur</span>
                                </div>
                                <div onClick={()=>manuelTemizlikIslemleri({ 
                                manualStart: manuelTemizlikModu, 
                                recoveryState: manuelTemizlikModu, 
                                number: props.kanal, 
                                seriNo: window.location.pathname.split("r/")[1] }, props.bildirimleriYonet, setPotasiyostatikManuelTemizlemede, [geriSayim, setGeriSayim])} className="arka-taraf-potansiyostatik-buton" style={{marginTop: "0px"}}>
                                    <span>Başlat</span>
                                </div>
                            </> 
                            : 
                            <div style={{marginRight: "15px", marginBottom: "5px"}}>recoveride: {geriSayim}</div>    
                        }
                        
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CihazArkaTarafBrimler