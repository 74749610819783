import axios from "axios"
import CookieManager from "../../../../cookieManager"
const bekleyenOnaylar = async (token)=>{
    let gonderilecekData = {
        kullaniciAdi: CookieManager.getCookie("kullaniciAdi")
    }
    try{
        let data =  await axios.post("https://api.enelsa.com:5015/api/bekleyenOnaylar", gonderilecekData,
            {
                headers: {
                    'authorization': 'Bearer ' + CookieManager.getCookie("token")
                }
            }
        )
        if (data.status === 200) {
            return data.data
        }
    }
    catch(e){
        // console.log(e)
    }

}

export default bekleyenOnaylar