import React, { useEffect, useState } from "react"
import { faGear, faRotateLeft,faChartLine, faWaveSquare } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import "./sinyal-oran-on-taraf.css"
import { Link } from "react-router-dom"
import { 
    isletimModu,
    mode,
    state,
    unitSelection2 as unitSelection,
    icolarMod,
    icolarState
 } from "./dictionaries"

const SinyalOranOnTarafi = (props)=>{

    let altMode = {
        "0": "Sinyal Frekans",
        "1": "Sinyal Genişlik",
        "2": "Sinyal Oranı"
    }

    let classNovaAlt = props.ekranGenisligi < 520 ? "nova-bilgi-kart-alt-mobil" : "nova-bilgi-kart-alt" 

    return(
        <>
        <div className="cihaz-bilgi-kart-icerik-cerceve2">
            <div className="nova-bilgi-kart-ust">
                <div className="nova-isletim-modu">
                   <div>İşletim Modu : {isletimModu[props.veriler["operatingMode"]]}</div> 
                   <div>Sinyal Giriş Modu : {altMode[props.veriler["pulseRatioSubMode"]]}</div> 
                </div>
            </div>
            <div className="nova-bilgi-kart-orta-ppm">
                <div className="vurus-sayisi">
                    <div className="vurus-ust">
                        Vuruş Oranı
                    </div>
                    <hr className="vurus-cizgi"></hr>
                    <div className="vurus-alt">
                        <span>{props.veriler["strokeRate"]} {unitSelection[props.veriler["strokeRateUnitSelection"]]}</span>
                    </div>
                    <div>
                        <hr className=""></hr>
                        <div className="icos">
                            <div className="calisma-modux">
                                <div>Çalışma Modu : <span>{mode[props.veriler["runMode"]]}</span></div>
                                <span>{icolarMod[props.veriler["runMode"]]} </span>
                            </div>
                            <div className="calisma-modux">
                                <div>Durum : <span>{state[props.veriler["runModeState"]]}</span></div>
                                <span>{icolarState[props.veriler["runModeState"]]} </span>
                            </div>
                        </div> 
                    </div>
                </div>
            </div>
            <div className= {classNovaAlt}>
                <div className="kapasite-limit">
                    <div>Kapasite Alt Limit : <span>{props.veriler["strokeRateLowLimit"]} {unitSelection[props.veriler["strokeRateUnitSelection"]]}</span> </div>
                    <div>Kapasite Üst Limit : <span>{props.veriler["strokeRateHighLimit"]} {unitSelection[props.veriler["strokeRateUnitSelection"]]}</span> </div>
                </div>
                { (props.veriler["pulseRatioSubMode"] == "0") &&
                <div className="kapasite-limit">
                    <div>Sinyal Frekans Min : <span>{props.veriler["minimumFrequency"]} Hz</span></div>
                    <div>Sinyal Frekans Max  : <span>{props.veriler["maximumFrequency"]} Hz</span></div>
                </div>
                }
                { (props.veriler["pulseRatioSubMode"] == "1") &&
                <div className="kapasite-limit">
                    <div>Sinyal Genişlik Min : <span>{props.veriler["minimumPulseWidth"]} ms</span></div>
                    <div>Sinyal Genişlik Max  : <span>{props.veriler["maximumPulseWidth"]} ms</span></div>
                </div>
                }
                { (props.veriler["pulseRatioSubMode"] == "2") &&
                <div className="kapasite-limit">
                    <div>Sinyal Oran Min : <span>{props.veriler["minimumDuty"]} % </span></div>
                    <div>Sinyal Oran Max : <span>{props.veriler["maximumDuty"]} % </span></div>
                </div>
                }
            </div>
        </div>
        <Link id="cihaz-on-taraf-grafik-ico" to={"/grafik/" + window.location.pathname.split("r/")[1]}>
            <FontAwesomeIcon icon={faChartLine}/>
        </Link>
        </>
    )
}

export default SinyalOranOnTarafi