import axios from "axios"
import CookieManager from "../../../../cookieManager"

async function cihazAnlikVeri(seriNo) {
    let gonderilecekData = {
        seriNo: seriNo,
        id: CookieManager.getCookie("id")
    }
    try {
        let veriler = await axios.get("https://api.enelsa.com:5015/api/search/" + seriNo, {
            headers: {
                'authorization': 'Bearer ' + CookieManager.getCookie("token")
            }
        })

        // kanalRenkleri, isim, kanalIsimlerini, alarmBildirimSMS, alarmBildirimMail çekmek için 
        let data2 = await axios.post("https://api.enelsa.com:5015/api/device-channel-name", gonderilecekData, {
            headers: {
                'authorization': 'Bearer ' + CookieManager.getCookie("token")
            }
        })

        veriler.data.isim = data2.data.isim
        veriler.data.kanalIsimleri = data2.data.kanalIsimleri

        return veriler.data
    } catch (e) {
        // console.log(e)
    }

}

export default cihazAnlikVeri