
let isletimModu = {
    "0" : "Manuel",
    "1" : "Analog",
    "2" : "Sinyal Giriş",
    "3" : "Sinyal Oran",
    "4" : "Paket",
    "5" : "Kontrol"
}

export default isletimModu