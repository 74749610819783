import axios from "axios"
import CookieManager from "../../../../cookieManager"

//!!!!!!! LOGIN DURUMU
const referansCodesData = async (token) => {
    let gonderilecekData = {
        userId: CookieManager.getCookie("id")
    }
    try {
        let data = await axios.post("https://api.enelsa.com:5015/api/referansCodes", gonderilecekData,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': 'Bearer ' + CookieManager.getCookie("token")
                }
            }
        )
        if (data.status === 200) {
            return data.data
        }
    }
    catch (e) {
        // // console.log("*****", window.location.pathname.split("r/")[1])   
        // console.log(e, "referansCode error")
    }

}

export default referansCodesData