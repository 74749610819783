import { faX, faWaveSquare } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

let unitSelection2 = {
    "0" : "Lt/Saat",
    "1" : "%",
    "2" : <span><FontAwesomeIcon icon={faWaveSquare}></FontAwesomeIcon>s</span>,
    "3" : <span><FontAwesomeIcon icon={faWaveSquare}></FontAwesomeIcon>d</span>
}

export default unitSelection2