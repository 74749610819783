import React, { useEffect, useState } from "react"
import axios from "axios"
import { Link, useNavigate } from "react-router-dom"
import { faBell, faGear, faRotateLeft, faPen, faTable, faSms, faFileLines, faPalette, faTrashCan, faEnvelope } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import cihazDatalariniEldeEt from "../../../cihazlar-sayfasi/data-islemleri/cihazlar-data.js"
import SaltBilgiKartiInner from "./salt-bilgi-karti-inner.js"

const REGEXP_SPECIAL_CHAR =
    /[\!\#\$\%\^\&\*\)\(\+\=\.\<\>\{\}\[\]\:\;\'\"\|\~\`\_\-]/g

const SaltBilgiKarti = (props) => {
    let [baslangic, setBaslangic] = useState(false)
    const [underline, setUnderline] = useState(false);

    let [cihazAdi, setCihazAdi] = useState("")
    let [cihazAdiT, setCihazAdiT] = useState("")

    let [popupDurum, setPopupDurum] = useState(false)
    let [popupKonum, setPopupKonum] = useState(-250)

    let [popupDurum3, setPopupDurum3] = useState(false)
    let [popupKonum3, setPopupKonum3] = useState(-250)

    const popupGoster3 = () => {
        setPopupDurum3(true)
        setTimeout(() => setPopupKonum3(100), 100)
    }

    let [seriNo, setSeriNo] = useState(window.location.pathname.split("t/")[1])
    let [cihazKullanici, setCihazKullanici] = useState("")

    let [showCihazSilApprovalPopup, setShowCihazSilApprovalPopup] = useState(false) 

    const popupGoster = () => {
        setPopupDurum(true)
        setUnderline(true)
        setTimeout(() => setPopupKonum(100), 100)
    }

    const popupGizle = () => {
        setPopupKonum(-250)
        setUnderline(false)
        setTimeout(() => setPopupDurum(false), 320)
    }

    useEffect(() => {
        setBaslangic(true)
    }, [baslangic])

    let cihazVerileriniGuncelle = async (seriN) => {
        let cihazDatalari = await cihazDatalariniEldeEt()
        try {
            cihazKullanici = setCihazKullanici(cihazDatalari.cihazlar[seriN]?.kullaniciAdi)
        } catch(e) {
            // console.log(e)
        }
    }

    let isimKaydet = async (isim) => {
        let regexIsim = isim.cihazAdi.replace(REGEXP_SPECIAL_CHAR, '')
        let kaydedilecekİsim = {
            isim: regexIsim,
            cihaz: seriNo
        }
        try {
            let res = await axios.put("http://localhost:5001/api/cihazIsmiGuncelle", JSON.stringify(kaydedilecekİsim), {
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': 'Bearer ' + props.tokenBilgileri.token
                }
            })
            // props.temaAyarDegistir()
            let yeniBildirim = { mesaj: "Cihaz İsmi", bildirim: 1, key: "kalanRenk-" + Math.random(10), sagaGorePozisyonu: -250, uyari: 0 }
            props.bildirimleriYonet("kanalRenk", yeniBildirim)
            setCihazAdiT(regexIsim)
            popupGizle()
        } catch {
            let yeniBildirim = { mesaj: "Cihaz İsmi", bildirim: 0, key: "kalanRenk-" + Math.random(10), sagaGorePozisyonu: -250, uyari: 0 }
            props.bildirimleriYonet("kanalRenk", yeniBildirim)
        }
    }

    cihazVerileriniGuncelle(seriNo)

    function cihazSilApprove() {
        let location = window.location.href
        location = location.split("#")[0]
        location = location + "#modal-closed"
        window.location.href = location
        setShowCihazSilApprovalPopup(true)
    }

    return (
        <>
        <div style={{backgroundColor: "#42494f"}}>
            <div className="cihaz-isim" >
                <div style={{display: "flex", flexDirection: "column", rowGap: "3px"}} onClick={popupGizle}>
                    <span className="isim-text">Cihaz Kullanıcı  : <em>{cihazKullanici}</em>  </span>
                    <span className="isim-text">Cihaz İsim : <em>{cihazAdiT}</em> </span>
                    <span className="isim-text">Cihaz Seri No : <em>{seriNo}</em> </span>
                    <span className="isim-text">Son Güncelleme : <em></em> </span>
                </div>
                {/* {
                    (Math.floor((new Date().getTime() - new Date(data?.Time).getTime())/1000/60) < 10) ?
                    <div className="omnicon-lock-ico">
                        <ConfigLock show={configLock} onClick={mailPass}></ConfigLock>
                    </div> : 
                    <div className="omnicon-lock-ico" style={{display: "flex"}} title="Cihaz çevrimdışı olduğu için ayar yapılamıyor.">
                        <FontAwesomeIcon icon={faWifi} style={{fontSize: "25px",marginLeft: "15px",marginTop: "40px", color: "red"}} beatFade/>
                        <FontAwesomeIcon icon={faSlash} style={{fontSize: "23px",fontWeight: "bolder" ,marginLeft: "-30px", marginTop: "40px", color: "red"}} beatFade/>
                    </div>
                }
                <div>
                    <Countdown eventBus={eventBus} onComplete={()=>{lockConfigClient()}} onClick={()=>{setShowConfigLockApprovalPopup(true)}}></Countdown>
                </div> */}
                <div className="rapor-alarm-text">
                    <Link to={"/tablo/" + window.location.pathname.split("r/")[1]}  style={{marginLeft: "auto", marginRight: 45, display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer", color: "#F7FBFF", textDecoration: "none"}}>
                        <FontAwesomeIcon icon={faTable} style={{ height: 24, marginRight: 8 }}/>
                        <span>Raporlar</span>
                    </Link>
                    <div>
                        <a href="#ayarlar-modal">
                            <FontAwesomeIcon icon={faGear} className="cihaz-bilgi-kart-ust-taraf-ico2" onClick={popupGoster3} style={{ height: 24, marginRight: 8 }}/>
                            <span>Cihaz İşlemleri</span>
                        </a>
                    </div>
                </div>
            </div>
            <div id="kartlar-grid" className="kartlar-grid">
                <SaltBilgiKartiInner tokenBilgileri={props.tokenBilgileri}
                                    ekranGenisligi={props.ekranGenisligi}
                                    menuGenisligi={props.menuGenisligi}
                                    bildirimleriYonet={props.bildirimleriYonet}>
                </SaltBilgiKartiInner>
            </div>
            <div className="modal-container" id="ayarlar-modal" >
                <div className="modal" style={{overflow: "hidden"}}> 
                    <div className="modal__details">
                        <h1 className="modal__title">Cihaz İşlemleri</h1>
                    </div>
                    <div className="modal__text" style={{ cursor: "pointer"}}>
                        {/* <a href="#bildirim-modal">
                            <div className="cihaz-islemleri-text" style={{flexDirection: "row" }} onClick={popupGoster4}>
                                <FontAwesomeIcon icon={faSms} style={{ marginRight: "11px" }}/>
                                <span>Cihaz Alarm Bildirimleri</span>
                            </div>
                        </a> */}
                        {/* <hr style={{width: "350px"}}></hr> */}
                        {/* <a href="#rapor-modal">
                            <div className="cihaz-islemleri-text" style={{flexDirection: "row" }} onClick={popupGoster5}>
                                <FontAwesomeIcon icon={faFileLines} style={{ marginRight: "15px" }}/>
                                <span>Cihaz Raporlama Ayarları</span>
                            </div>
                        </a>
                        <hr style={{width: "350px"}}></hr> */}
                        <a href="#isim-modal">
                        <div className="cihaz-islemleri-text" style={{flexDirection: "row" }} onClick={popupGoster}>
                            <FontAwesomeIcon icon={faPen} style={{ marginRight: "11px", rotate: "270deg" }}/>
                            <span>Cihaz İsmini Düzenle</span>
                        </div></a>
                        <hr style={{width: "350px"}}></hr>
                        {/* TODO : Sadece kendine ait cihazlarda bu seçenek çıksın!
                        <a href="#yetkilendir-modal">
                            <div className="cihaz-islemleri-text" style={{flexDirection: "row" }} onClick={popupGoster6}>
                                <FontAwesomeIcon icon={faUserEdit} style={{ marginRight: "7px" }}/>
                                <span>Kullanıcı Yetkilendir</span>
                            </div>
                        </a> */}
                        {/* <hr style={{width: "350px"}}></hr> */}
                        <div className="cihaz-sil-text" style={{flexDirection: "row" }} onClick={() => {cihazSilApprove()}}>
                            <FontAwesomeIcon icon={faTrashCan} style={{ marginRight: "15px" }}/>
                            <span>Cihazı Sil</span>
                        </div>
                    </div>
                    <a href="#modal-closed" className="link-2"></a>
                    <FontAwesomeIcon className="modal-alt-icon" style={{ rotate: "45deg"}} icon={faGear}/>
                </div>
            </div>
            <div className="modal-container" id="isim-modal" >
                <div className="modal"> 
                    <div className="modal__details">
                        <h1 className="modal__title">Cihaz İsmi Düzenle</h1>
                    </div>

                    <p className="isim-modal__text">
                    <span>Cihaz Kullanıcı  </span>: <em>{cihazKullanici}</em>  
                        <span>Cihaz Seri No </span>: <em>{seriNo}</em>
                        <span>Cihaz İsim </span>:
                        <input style={{ width: "169px", outline: "none", paddingLeft: "4px", color: "#42494F" }} className="arka-taraf-birimler-input2"  value={cihazAdi} onChange={(e) => setCihazAdi(e.target.value)} />
                    
                    </p>
                    <button className="modal__btn" onClick={() => { isimKaydet({ cihazAdi }, props.bildirimleriYonet) }}>KAYDET</button>
                    <a href="#modal-closed" className="link-2"></a>
                </div>
            </div>
        </div>
        </>
    )
}


export default SaltBilgiKarti