import "./cihaz-ekle.css"
import React, { useEffect, useState, useRef } from "react"
import omniconLogo from "../../../assests/Varlık 2.png"
import novaLogo from "../../../assests/nova.png"
import fytLogo from "../../../assests/fyt.png"
import referansCodesData from "../data-islemleri/referans-codes-data"
import Swal from 'sweetalert2'
import axios from "axios"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faWarning } from "@fortawesome/free-solid-svg-icons"
import CookieManager from "../../../../cookieManager"

const CihazEkle = (props) => {

	// // console.log(props)
	const [baslangic, setBaslangic] = useState(false)
	const [deviceValue, setDeviceValue] = useState("omnicon")
	const [referansCodesObj, setReferansCodesObj] = useState({})
	const [referansCodes, setReferansCodes] = useState([])
	const [referansCode, setReferansCode] = useState("0")
	const [verificationCode, setVerificationCode] = useState("")


	function selectMenusuOlusturCihazEkle(baslik, valueDegeri, valueDegeriFonksiyonu, secimleri) {
		return (
			<div className="cihaz-ekle-icerik cihaz-ekle-icerik-ust">
				<span>{baslik}</span>
				<select name="deviceType" id="deviceType" className="cihaz-ekle-select" value={valueDegeri} onChange={(e) => setDeviceValueFunc(e.target.value)} >
					{
						Object.keys(secimleri).map((e, i) => {
							return (<option value={i}>{referansCodes[e]}</option>)
						})
					}
				</select>
			</div>
		)
	}



	const inputRefs = useRef([]);

	useEffect(() => {
		referansCodes.length !== 0 &&
			inputRefs.current[0].focus();
	}, []);

	const handleInputChange = (event, index) => {
		const value = event.target.value;
		const maxLength = parseInt(event.target.getAttribute('maxLength'));

		let updatedVerificationCode = verificationCode;

		if (value === '') {
			updatedVerificationCode = updatedVerificationCode.slice(0, index);
		} else {
			updatedVerificationCode = updatedVerificationCode.slice(0, index) + value + updatedVerificationCode.slice(index + 1);
		}

		setVerificationCode(updatedVerificationCode);

		if (value.length >= maxLength) {
			if (index < inputRefs.current.length - 1) {
				inputRefs.current[index + 1].focus();
			} else {
				event.target.blur();
			}
		} else if (index > 0 && value === '') {
			inputRefs.current[index - 1].focus();
		}
	};

	useEffect(() => {
		if (baslangic) return
		setBaslangic(true)
		try {
			props.kullaniciBilgileriGuncelle()
			props.konumGuncelle("cihazekle")
			referansCodesData(CookieManager.getCookie("token")).then((e) => {
				if (e) {
					setReferansCodesObj(e)
					let keys = Object.keys(e)
					keys.forEach(i => {
						referansCodes.push(e[i].referansCode)
					});
				}
			})
		} catch (e) {
			// // console.log(e, "cihaz-ekle")
		}
	}, [baslangic, props, referansCodes, referansCodesObj])

	const setDeviceValueFunc = (value) => {
		setReferansCode(value)
		setDeviceValue(referansCodesObj[value].cihazModeli.toLowerCase());
	};

	async function verificationCodeKaydet() {
		let gonderilecekData = {
			id: CookieManager.getCookie("id"),
			obj: referansCodesObj[referansCode],
			cihazType: deviceValue
		}
		if (verificationCode === referansCodesObj[referansCode].verificationCode) {
			const res = await axios.post("https://api.enelsa.com:5015/api/cihazEkle", gonderilecekData, {
				headers: {
					'Content-Type': 'application/json',
					'authorization': 'Bearer ' + CookieManager.getCookie("token")
				}
			})
			// // console.log(res)
			if (res.data === "duplicate") {
				Swal.fire({
					icon: 'error',
					title: 'Varolan Seri Numarası',
					text: 'Bu seri numarası zaten daha önce kaydedilmiş.',
					color: "aliceblue",
					position: "center",
					background: "#505d68",
					confirmButtonText: 'Tamam',
					confirmButtonColor: 'rgb(70, 85, 105)',
					width: '25em'
				})
			} else if (res.data === "hata") {
				Swal.fire({
					icon: 'error',
					title: 'Hata',
					text: 'Cihaz kurulumu sırasında bir sorun oluştu. Lütfen cihazı yeniden kurunuz.',
					color: "aliceblue",
					position: "center",
					background: "#505d68",
					confirmButtonText: 'Tamam',
					confirmButtonColor: 'rgb(70, 85, 105)',
					width: '25em'
				})
			} else {
				Swal.fire({
					icon: 'success',
					title: 'Yeni Cihaz Kayıt İşlemi Başarılı',
					text: 'Cihazınızı görüntüleyebilirsiniz.',
					confirmButtonText: 'Tamam',
					confirmButtonColor: 'rgb(70, 85, 105)',
					width: '25em',
				})
			}
		} else {
			Swal.fire({
				icon: 'error',
				title: 'Doğrulama Kodu Hatası',
				text: 'Lütfen doğrulama kodunu kontrol ediniz.',
				color: "aliceblue",
				position: "center",
				background: "#505d68",
				confirmButtonText: 'Tamam',
				confirmButtonColor: 'rgb(70, 85, 105)',
				width: '25em'
			})
		}
		// // console.log(referansCodesObj[referansCode].verificationCode)
	}

	return (
		<>
			<div className="cihaz-ekle-page">
				{referansCodes.length !== 0 ?
					<div className="cihaz-ekle-container" style={{ backgroundColor: deviceValue === "omnicon" ? "#b0b0a985" : deviceValue === "nova" ? "#fffb2b42" : "#00abe462" }}>
						{selectMenusuOlusturCihazEkle("Referans Kodunu Seçiniz", referansCode, setReferansCode, referansCodes)}
						<div className="cihaz-ekle-icerik cihaz-ekle-icerik-orta">
							<img src={deviceValue.toLocaleLowerCase() === "omnicon" ? omniconLogo : deviceValue.toLowerCase() === "nova" ? novaLogo : fytLogo} className="deviceImg" alt="" />
							<div className="cihaz-bilgi-kart-popup-gorsel-golge-cihaz-ekle">
								<div></div>
							</div>
						</div>
						<div className="cihaz-ekle-icerik cihaz-ekle-icerik-alt">
							<span>
								Doğrulama Kodunu Giriniz
							</span>
							<div className="inputContainer">
								{Array.from({ length: 6 }, (_, index) => (
									<div className="cod" key={index}>
										<input type="text" maxLength={1} ref={(el) => (inputRefs.current[index] = el)} onChange={(event) => handleInputChange(event, index)} value={verificationCode[index] || ''} />
									</div>
								))}
							</div>
						</div>
						<div className="cihaz-ekle-icerik cihaz-ekle-icerik-alt">
							<button className="cihaz-ekle-button" onClick={() => verificationCodeKaydet()}>KAYDET</button>
						</div>
					</div>
					:
					<div className="kurulum-yapiniz-div">
						<span className="warningIco">
							<FontAwesomeIcon icon={faWarning} flip></FontAwesomeIcon>
						</span>
						<span>
							Kurulumu tamamlanmış hiç cihazınız görünmüyor.
						</span>
						<span>
							Lütfen önce cihaz üzerinden kurulum işlemini tamamlayınız.
						</span>
						<span>
							Kullanıcı adı ve telefon numaranızı doğru girdiğinizden emin olun.
						</span>
					</div>
				}
			</div>
		</>
	)
}
export default CihazEkle

