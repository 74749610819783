import axios from "axios"
import { React, useState } from "react"
import "./cihaz-arka-taraf.css"
import { CihazArkaTarafBrimler, inputOlusturFunc } from "./arka-taraf-birimler/cihaz-arka-taraf-birimler"
import CihazArkaTarafPotansiyostatik from "./arka-taraf-birimler/potansiyostatik/cihaz-arka-taraf-potansiyostatik"
import CookieManager from "../../../../cookieManager"
import Swal from "sweetalert2"

function varsayilanDegerleriOlustur2(veriler) {
    let varsayilanDegerler = { acikKenUzunlugu: null, tipi: null, altLimit: null, ustLimit: null }
    Object.keys(varsayilanDegerler).forEach((e, i) => varsayilanDegerler[e] = veriler[i])
    return varsayilanDegerler
}

let gosterilecekDatalarinOlusturulmasi = (veriler, bildirimleriYonet, kanal, renk, renk2, limitler, zamanAsimi, token) => {
    let gosterilecekDatalar = []

    if (!veriler.potansiyostatikCikisVerileri)
        veriler.potansiyostatikCikisVerileri = {}
    // console.log(veriler)

    Object.keys(veriler).forEach(e => {
        if (Object.keys(veriler[e]).length === 0)
            return
        if (e === "potansiyostatikCikisVerileri") {
            gosterilecekDatalar.push(<CihazArkaTarafPotansiyostatik renk={renk} renk2={renk2} icerikVerileri={veriler[e]} bildirimleriYonet={bildirimleriYonet} kanal={kanal} />)
        } else {
            Object.keys(veriler[e]).forEach(index => {
                gosterilecekDatalar.push(<CihazArkaTarafBrimler token={token} limitler={limitler} zamanAsimi={zamanAsimi} renk={renk} renk2={renk2} icerikVerileri={veriler[e][index]} index={index} bildirimleriYonet={bildirimleriYonet} kanal={kanal} />)
            })
        }
    })
    return gosterilecekDatalar
}

async function ayarlariKaydetLimitler(datalar, bildirimleriYonet, token) {
    let gonderilecekData = {
        altLimit: datalar.altLimit,
        ustLimit: datalar.ustLimit,
        limit: 1,
        seriNo: datalar.seriNo
    }

    // gonderilecekData["number"] = datalar.index
    gonderilecekData["number"] = datalar.kanal

    const res = await axios.post('https://api.enelsa.com:5015/api/cihazAyar', JSON.stringify(gonderilecekData), {
        headers: {
            'Content-Type': 'application/json',
            'authorization': 'Bearer ' + token
        }
    })

    if (res.data === "Degisen Data Yok") {
        Swal.fire({
            icon: 'error',
            title: 'Değişen Data Yok',
            confirmButtonText: 'Tamam',
            confirmButtonColor: 'rgb(70, 85, 105)',
            width: '25em',
        })
    }

    if (res.status == 500) {
        // console.log("500")
    }

    if (res.data === "ok") {
        let gonderilecekData = {
            seriNo: datalar.seriNo
        }
        try {
            const res = await axios.post('https://api.enelsa.com:5015/api/cihazAyarKontrol', JSON.stringify(gonderilecekData), {
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': 'Bearer ' + token
                }
            })
            if (res.status == 200) {
                Swal.fire({
					icon: 'success',
					title: 'Ayar Başarılı',
					text: 'Güncelleme işlemi 1-5 saniye içinde gerçekleşecektir.',
					confirmButtonText: 'Tamam',
					confirmButtonColor: 'rgb(70, 85, 105)',
					width: '25em',
				})
            }
            
        } catch (e) {
            // console.log(e)
        }

    }

}


const CihazArkaTaraf = (props) => {

    // console.log(props.tokenBilgileri.token)
    let varsayilanDegerler = {}
    varsayilanDegerler = varsayilanDegerleriOlustur2([136.5, "Röle", [props.limitler.kanalAltLimit], [props.limitler.kanalUstLimit]])

    const [altLimit, setAltLimit] = useState(varsayilanDegerler.altLimit)
    const [ustLimit, setUstLimit] = useState(varsayilanDegerler.ustLimit)

    let parametreSifirlamaFonksiyonlariLimitler = {
        altLimit: () => { setAltLimit(varsayilanDegerler.altLimit) },
        ustLimit: () => { setUstLimit(varsayilanDegerler.ustLimit) }
    }

    let [pencereAcik, setPencereAcik] = useState(false)
    
    const seriNo = window.location.pathname.split("r/")[1]?.split("#")[0]

    return (
        <>
            {
                <>
                    <div className="arka-pencere">
                        {
                            <>
                                {
                                    gosterilecekDatalarinOlusturulmasi(props.veriler, props.bildirimleriYonet, props.kanal, props.renk, props.renk2, props.limitler, props.zamanAsimi, CookieManager.getCookie("token")).map(e => e)
                                }
                                {
                                    <div className="arka-taraf-birimler-cerceve">
                                        <div style={{ height: pencereAcik ? varsayilanDegerler.acikKenUzunlugu : 30 + "px" }} className="arka-taraf-birimler-alt-cerceve">
                                            <div className="arka-taraf-birimler-ust-cerceve" onClick={() => setPencereAcik(!pencereAcik)} style={{ backgroundColor: props.renk2, transition: "all 0.5s" }}>
                                                <span style={{ marginLeft: "10px" }}></span>
                                                <span style={{ fontSize: "13px" }}>Alarm Limit Ayarları</span>
                                                <span style={{ rotate: (pencereAcik ? -1 : 1) * 90 + "deg", marginRight: "10px" }}>{">"}</span>
                                            </div>
                                            <div className="arka-taraf-birimler-icerik" style={{ height: "auto" }}>
                                                <>
                                                    {inputOlusturFunc("Alarm Alt Limit", altLimit, setAltLimit, props.renk2)}
                                                    {inputOlusturFunc("Alarm Üst Limit", ustLimit, setUstLimit, props.renk2)}
                                                </>
                                                <div className="arka-taraf-birimler-buton-cerceve">
                                                    <div onClick={() => Object.keys(parametreSifirlamaFonksiyonlariLimitler).forEach(e => parametreSifirlamaFonksiyonlariLimitler[e]())} className="arka-taraf-birimler-buton" style={{ marginRight: "5px" }}>
                                                        <span>Vazgeç</span>
                                                    </div>
                                                    <div onClick={() => {
                                                        ayarlariKaydetLimitler({
                                                            altLimit: altLimit,
                                                            ustLimit: ustLimit,
                                                            limit: 1,
                                                            index: props.index,
                                                            kanal: props.kanal,
                                                            seriNo: seriNo
                                                        }, props.bildirimleriYonet, CookieManager.getCookie("token"))
                                                    }} className="arka-taraf-birimler-buton" >
                                                        <span>Kaydet</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </>
                        }
                    </div>
                </>
            }
        </>
    )
}

export default CihazArkaTaraf