import axios from "axios"
import CookieManager from "../../../../cookieManager";

async function cihazDatalariniEldeEt(kullaniciId) {
    // TODO : Bazen undefined gelebiliyor eğer çok hızlı davranılırsa ondan dolayı null check eklendi.

    if(kullaniciId == undefined){
        kullaniciId = CookieManager.getCookie("id");
    }
    let gonderilecekData = {
        id: kullaniciId
        // id: this.props.kullaniciBilgileri.kullaniciId
    }
   
    try {
        let veriler = await axios.post("https://api.enelsa.com:5015/api/anasayfa", gonderilecekData, {
            headers: {
                'authorization': 'Bearer ' + CookieManager.getCookie("token")
            }
        })
        return veriler.data

    }
    catch (e) {
        //window.location.replace("/anasayfa")
    }

}

export default cihazDatalariniEldeEt