import React, { useEffect, useState } from "react"
import axios from "axios"
import ReactDOM from "react-dom"
import { Link, useNavigate } from "react-router-dom"
import { faBell, faGear, faRotateLeft, faPen, faTable, faSms, faFileLines, faPalette, faTrashCan, faEnvelope } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import "./nova-bilgi-karti.css"
import NovaYukleme from "./nova-yukleme.js"
import ManuelOnTarafi from "./manuel-on-taraf.js"
import ManuelArkaTaraf from "./manuel-arka-taraf.js"
import SinyalPpmOnTarafi from "./sinyal-ppm-on-taraf.js"
import SinyalPpmArkaTaraf from "./sinyal-ppm-arka-taraf.js"
import SinyalOranOnTarafi from "./sinyal-oran-on-taraf.js"
import SinyalOranArkaTaraf from "./sinyal-oran-arka-taraf.js"
import PaketOnTarafi from "./paket-on-taraf.js"
import PaketArkaTaraf from "./paket-arka-taraf.js"
import KontrolOnTarafi from "./kontrol-on-taraf.js"
import KontrolArkaTaraf from "./kontrol-arka-taraf.js"
import { alarmsBasic, alarmsControl } from "./nova-alarms.js"
import { isletimModu } from "./dictionaries/keywords/index.js"
import cihazDatalariniEldeEt from "../../../cihazlar-sayfasi/data-islemleri/cihazlar-data.js"
const REGEXP_SPECIAL_CHAR =
    /[\!\#\$\%\^\&\*\)\(\+\=\.\<\>\{\}\[\]\:\;\'\"\|\~\`\_\-]/g

const NovaBilgiKarti = (props) => {
    let [baslangic, setBaslangic] = useState(false)
    let [componentOlusturuldu, setComponentOlusturuldu] = useState(false)
    let [timeOutIslemde, setTimeOutIslemde] = useState(false)
    let [cihazDatalari, setCihazDatalari] = useState([])
    let [cihazTarafBilgisi, setCihazTarafBilgisi] = useState(true)
    let [cihazDondurme, setCihazDondurme] = useState(true)
    let [cihazAdi, setCihazAdi] = useState("")
    let [cihazAdiT, setCihazAdiT] = useState("")
    let [operatingMode, setOperatingMode] = useState("")
    let [alarmBasicList, setAlarmBasicList] = useState([])
    let [alarmBasic, setAlarmBasic] = useState(0)
    let [alarmContList, setAlarmContList] = useState([])
    let [alarmCont, setAlarmCont] = useState(0)
    let [alarmIco, setAlarmIco] = useState(false)

    let [seriNo, setSeriNo] = useState("")
    let [cihazKullanici, setCihazKullanici] = useState("")

    let [showAlarmsMenu, setShowAlarmsMenu] = useState(false)

    function deviceAlarms() {
        alarmBasicList = []
        alarmContList = []
        for (let i = 0; i <= 7; i++) {
            alarmBasicList.push((alarmBasic >> i) & 1);
        }
        for (let i = 0; i <= 10; i++) {
            alarmContList.push((alarmCont >> i) & 1);
        }
        setAlarmBasicList(alarmBasicList)
        setAlarmContList(alarmContList)
    }

    useEffect(() => {
        setOperatingMode(isletimModu[props.veriler?.operatingMode])
        setCihazDatalari(props.veriler)

    })

    // useEffect(() => {
    //     const datalariOlustur = () => {
    //         novaDataIslemleri(CookieManager.getCookie("token")).then((e) => {
    //             if (e) {
    //                 if (JSON.stringify(cihazDatalari) != e.data) {
    //                     let a = JSON.parse(e.data)
    //                     setCihazDatalari(a)
    //                     setSeriNo(e.seriNo)
    //                     setOperatingMode(isletimModu[a["operatingMode"]])
    //                     setAlarmBasic(a["errorBasic"])
    //                     setAlarmCont(a["errorControl"])
    //                     setAlarmIco(true)
    //                     e.isim && setCihazAdi(e.isim)
    //                     e.isim && setCihazAdiT(e.isim)
    //                 }
    //             }
    //         })
    //         setTimeOutIslemde(false)
    //     }
    //     if (!timeOutIslemde) {
    //         setTimeout(datalariOlustur, 5000)
    //         setTimeOutIslemde(true)
    //     }
    //     if (!componentOlusturuldu)
    //         setComponentOlusturuldu(true)
    // }, [timeOutIslemde, componentOlusturuldu])

    useEffect(() => {
        setBaslangic(true)
        deviceAlarms()
    }, [baslangic, alarmBasic, alarmCont])

    let cihazVerileriniGuncelle = async (seriN) => {
        let cihazDatalari = await cihazDatalariniEldeEt()
        try {
            cihazKullanici = setCihazKullanici(cihazDatalari.cihazlar[seriN]?.kullaniciAdi)
        } catch (e) {
            // console.log(e)
        }
    }

    cihazVerileriniGuncelle(seriNo)
    const pencereyiCevir = () => {
        setCihazDondurme(false)
        setTimeout(() => { setCihazTarafBilgisi(!cihazTarafBilgisi) }, 450)
        setTimeout(() => { setCihazDondurme(true) }, 700)
    }

    let width = props.ekranGenisligi > 790 ? "700px" : (props.ekranGenisligi > 520) ? "500px" : "300px"
    let classNovaKart = props.ekranGenisligi > 790 ? "nova-bilgi-kart-cerceve" : (props.ekranGenisligi > 520) ? "nova-bilgi-kart-cerceve tablet" : "nova-bilgi-kart-cerceve mobil"

    return (
        <>
            <div style={{ transform: cihazDondurme ? "rotateY(0deg)" : "rotateY(90deg)", backgroundColor: props.renk, width: width }} id="AA" className={classNovaKart}>
                {
                    (!props.configLock) &&
                    <div>
                        <FontAwesomeIcon icon={cihazTarafBilgisi ? faGear : faRotateLeft} className="cihaz-bilgi-kart-ust-taraf-ico" onClick={pencereyiCevir} style={{
                            position: "absolute",
                            top: "10px",
                            right: "10px", 
                        }} />
                    </div>
                }
                {
                    (((cihazTarafBilgisi) && (alarmBasic !== 1 || alarmCont !== 0)) && (alarmIco)) &&
                    <div className="nova-ico-div2">
                        <a href="#alarm-opened" >
                            <FontAwesomeIcon icon={faBell} className="cihaz-bilgi-kart-ust-taraf-ico2" onClick={() => setShowAlarmsMenu(true)} shake />
                        </a></div>
                }
                {
                    (operatingMode === "Manuel" || operatingMode === "Analog") ? cihazTarafBilgisi ? <ManuelOnTarafi veriler={cihazDatalari} ekranGenisligi={props.ekranGenisligi} tokenBilgileri={props.tokenBilgileri}></ManuelOnTarafi> : <ManuelArkaTaraf cihazKullanici={cihazKullanici} veriler={cihazDatalari} ekranGenisligi={props.ekranGenisligi} tokenBilgileri={props.tokenBilgileri} bildirimleriYonet={props.bildirimleriYonet}></ManuelArkaTaraf>
                        : operatingMode === "Sinyal Giriş" ? cihazTarafBilgisi ? <SinyalPpmOnTarafi veriler={cihazDatalari} ekranGenisligi={props.ekranGenisligi} tokenBilgileri={props.tokenBilgileri} ></SinyalPpmOnTarafi> : <SinyalPpmArkaTaraf cihazKullanici={cihazKullanici} veriler={cihazDatalari} ekranGenisligi={props.ekranGenisligi} tokenBilgileri={props.tokenBilgileri} bildirimleriYonet={props.bildirimleriYonet}></SinyalPpmArkaTaraf>
                            : operatingMode === "Sinyal Oran" ? cihazTarafBilgisi ? <SinyalOranOnTarafi veriler={cihazDatalari} ekranGenisligi={props.ekranGenisligi} tokenBilgileri={props.tokenBilgileri}></SinyalOranOnTarafi> : <SinyalOranArkaTaraf cihazKullanici={cihazKullanici} veriler={cihazDatalari} ekranGenisligi={props.ekranGenisligi} tokenBilgileri={props.tokenBilgileri} bildirimleriYonet={props.bildirimleriYonet}></SinyalOranArkaTaraf>
                                : operatingMode === "Paket" ? cihazTarafBilgisi ? <PaketOnTarafi veriler={cihazDatalari} ekranGenisligi={props.ekranGenisligi} tokenBilgileri={props.tokenBilgileri}></PaketOnTarafi> : <PaketArkaTaraf cihazKullanici={cihazKullanici} veriler={cihazDatalari} ekranGenisligi={props.ekranGenisligi} tokenBilgileri={props.tokenBilgileri} bildirimleriYonet={props.bildirimleriYonet}></PaketArkaTaraf>
                                    : operatingMode === "Kontrol" ? cihazTarafBilgisi ? <KontrolOnTarafi veriler={cihazDatalari} ekranGenisligi={props.ekranGenisligi} tokenBilgileri={props.tokenBilgileri}></KontrolOnTarafi> : <KontrolArkaTaraf cihazKullanici={cihazKullanici} veriler={cihazDatalari} ekranGenisligi={props.ekranGenisligi} tokenBilgileri={props.tokenBilgileri} bildirimleriYonet={props.bildirimleriYonet}></KontrolArkaTaraf>
                                        : <NovaYukleme></NovaYukleme>
                }
            </div>
        </>
    )
}


export default NovaBilgiKarti