function ChooseMenu(valueDegeri, valueDegeriFonksiyonu, secimleri) {
    return (
        <div>
            <ul className="choose-menu-secenekler">
                {            
                Object.values(secimleri).map((e, i) => {
                    return (
                    <li key={i}>
                        <button 
                            className={[i==valueDegeri && 'choose-menu-button-selected', "choose-menu-button", "choose-menu-button"].filter(Boolean).join(' ')}
                            value={i}
                            onClick={()=>
                                {
                                    valueDegeriFonksiyonu(i)
                                }
                                }>
                                {e}
                        </button>
                    </li>)
                }) 
                }
            </ul>
        </div>
    )
}

export default ChooseMenu