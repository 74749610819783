class CookieManager {
    static nameMapping = {
        token: "FTKN",
        hesaplar: "ACCN",
        login: "OGN",
        email: "MJC",
        tokenVerilisTarihi: "DTVT",
        kullaniciAdi: "UNA",
        id: "SSDN",
        beniHatirla: "FGGME",
        kullaniciBilgileri: "FERTK",
        kullaniciAdiSoyadi: "KASD",
        phone: "PHOASD"
    };

    static encrypt(data) {
        try {
            return btoa(unescape(encodeURIComponent(data)));
        } catch (e) {
            console.error("Encryption error: ", e);
            return null;
        }
    }

    static decrypt(data) {
        try {
            return decodeURIComponent(escape(atob(data)));
        } catch (e) {
            return null;
        }
    }

    static setCookie(name, value, days) {
        const secretName = this.nameMapping[name];
        if (!secretName) {
            return;
        }

        let expires = "";
        if (days) {
            const date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = "; expires=" + date.toUTCString();
        }
        const encryptedValue = this.encrypt(value);
        if (encryptedValue) {
            document.cookie = secretName + "=" + (encryptedValue || "") + expires + "; path=/";
        }
    }

    static getCookie(name) {
        const secretName = this.nameMapping[name];
        if (!secretName) {
            return null;
        }

        const nameEQ = secretName + "=";
        const ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) === 0) {
                const encryptedValue = c.substring(nameEQ.length, c.length);
                return this.decrypt(encryptedValue);
            }
        }
        return null;
    }
    static deleteCookie(name) {
        const secretName = this.nameMapping[name];
        if (!secretName) {
            return;
        }

        document.cookie = secretName + "=; Max-Age=-99999999; path=/";
    }
}

export default CookieManager;