import React, { useEffect, useState } from "react"
import { faX } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import axios from "axios"
import "./manuel-arka-taraf.css"
import CookieManager from "../../../../../cookieManager"
import Swal from "sweetalert2"
import {
    mode,
    state,
    isletimModu,
    unitSelection,
    unitSelection2,
    icolarMod,
    icolarState
} from './dictionaries'
import {
    Input,

} from "./common-components"

const ManuelArkaTaraf = (props) => {
    // console.log(props)
    let [baslangic, setBaslangic] = useState(false)

    let unit = {
        "0": "4-20 mA",
        "1": "0-20 mA",
        "2": "20-4 mA",
        "3": "20-0 mA"
    }

    let [strokeRate, setStrokeRate] = useState(null)
    let [strokeRateLowLimit, setStrokeRateLowLimit] = useState(null)
    let [strokeRateHighLimit, setStrokeRateHighLimit] = useState(null)
    let [strokeRateUnitSelection, setStrokeRateUnitSelection] = useState(null)
    let [runMode, setRunMode] = useState(null)
    let [icoRunMode, setIcoRunMode] = useState(null)
    let [showSettingsMenu, setShowSettingsMenu] = useState(false)
    let [showLoadScreen, setShowLoadScreen] = useState(false)
    let [editx, setEditx] = useState(false)
    //for things that should only run once

    let location = window.location.pathname.split("r/")[1]
    let seriNo = location.split("#")[0]

    async function manuelKaydet(cihazKullanici, data) {
        let gonderilecek = {
            data: data,
            seriNo: seriNo
        }
        try {
            let res = await axios.post("https://api.enelsa.com:5015/api/cihazAyarNova", JSON.stringify(gonderilecek), {
                headers: {
                    "Content-Type": "application/json",
                    "authorization": "Bearer " + CookieManager.getCookie("token")
                }
            })
            if (res.data === "ok") {
                let gonderilecekData = {
                    seriNo: seriNo
                }
                try {
                    const res = await axios.post('https://api.enelsa.com:5015/api/cihazAyarKontrol', JSON.stringify(gonderilecekData), {
                        headers: {
                            'Content-Type': 'application/json',
                            'authorization': 'Bearer ' + CookieManager.getCookie("token")
                        }
                    })
                    if (res.status == 200) {
                        Swal.fire({
                            icon: 'success',
                            title: 'Ayar Başarılı',
                            text: 'Güncelleme işlemi 1-5 saniye içinde gerçekleşecektir.',
                            confirmButtonText: 'Tamam',
                            confirmButtonColor: 'rgb(70, 85, 105)',
                            width: '25em',
                        })
                        setEditx(true)
                    }

                } catch (e) {
                    // console.log(e)
                }
            }
            setEditx(true)
            setShowLoadScreen(true)
            setShowSettingsMenu(false)
        } catch (e) {
            // console.log(e)
            setShowLoadScreen(false)
        }
    }

    async function varsayilanlaraSifirla() {
        setStrokeRate(props.veriler["strokeRate"])
        setStrokeRateLowLimit(props.veriler["strokeRateLowLimit"])
        setStrokeRateHighLimit(props.veriler["strokeRateHighLimit"])
        setRunMode(props.veriler["runMode"])
        setIcoRunMode(props.veriler["runMode"])
    }

    useEffect(() => {
        setBaslangic(true)
        setStrokeRate(props.veriler["strokeRate"])
        setStrokeRateLowLimit(props.veriler["strokeRateLowLimit"])
        setStrokeRateHighLimit(props.veriler["strokeRateHighLimit"])
        setStrokeRateUnitSelection(props.veriler["strokeRateUnitSelection"])
        setRunMode(props.veriler["runMode"])
        setIcoRunMode(props.veriler["runMode"])
    }, [baslangic, editx])

    useEffect(() => {
        setShowLoadScreen(false)
        setShowSettingsMenu(false)
        setEditx(false)
    }, [props.veriler])

    function runModeEdit() {
        runMode == 0 ? setRunMode("1") : setRunMode("0")
        icoRunMode == 0 ? setIcoRunMode("1") : setIcoRunMode("0")
    }

    let flexDirection = props.ekranGenisligi < 520 ? "column" : "row"

    return (
        <>
            <div className="cihaz-bilgi-kart-icerik-cerceve2">
                <div className="nova-bilgi-kart-ust">
                    <div className="nova-isletim-modu">
                        İşletim Modu : {isletimModu[props.veriler["operatingMode"]]}
                        {props.veriler["operatingMode"] != "0" && <div>Analog Giriş Modu : {unit[props.veriler["analogInputType"]]}</div>}
                    </div>
                </div>
                <div className="nova-bilgi-kart-orta">
                    <div className="vurus-sayisi">
                        <div className="vurus-ust">
                            Vuruş Oranı
                        </div>
                        <hr className="vurus-cizgi"></hr>
                        <div className="vurus-alt">
                            {props.veriler["operatingMode"] != "0" ? props.veriler["strokeRate"] : Input(strokeRate, setStrokeRate, "nova-manuel-input")} {((props.veriler["strokeRate"] != strokeRate) && props.veriler["operatingMode"] != "1") && <span className="editStar2">*</span>}
                            <button className="nova-bilgi-kart-orta-unit-selection-button" onClick={() => { setShowSettingsMenu(true) }} >{unitSelection[strokeRateUnitSelection]}</button>
                        </div>
                    </div>
                    {
                        props.veriler["operatingMode"] != "0" &&
                        <div className="analog">
                            <div className="vertical"></div>
                            <div className="vurus-sayisi analog-vurus-sayisi">
                                <div className="vurus-alt analog-vurus-alt">
                                    <span>{props.veriler["mA"]} mA</span>
                                </div>
                            </div>
                        </div>
                    }
                </div>
                <div>
                    <hr className=""></hr>
                    <div className="icos">
                        <div className="calisma-modux">
                            <div>Çalışma Modu : <span>{mode[runMode]}</span> {<span className={(mode[props.veriler["runMode"]] != mode[runMode]) ? "editStar" : "star"}>*</span>}</div>
                            <span style={{ cursor: "pointer" }} onClick={runModeEdit}>{icolarMod[icoRunMode]} </span>
                        </div>
                        <div className="calisma-modux">
                            <div>Durum : <span>{state[props.veriler["runModeState"]]}</span><span className="star">*</span></div>
                            <span>{icolarState[props.veriler["runModeState"]]} </span>
                        </div>
                    </div>
                </div>
                <div className="nova-bilgi-kart-alt">
                    <div className="kapasite-limit" style={{ flexDirection: flexDirection, columnGap: "50px" }}>
                        <div>Kapasite Alt Limit : <span>{Input(strokeRateLowLimit, setStrokeRateLowLimit, "nova-manuel-alt-input")} {<span className={(props.veriler["strokeRateLowLimit"] != strokeRateLowLimit) ? "editStar" : "star"}>*</span>} {unitSelection2[strokeRateUnitSelection]}</span> </div>
                        <div>Kapasite Üst Limit : <span>{Input(strokeRateHighLimit, setStrokeRateHighLimit, "nova-manuel-alt-input")} {<span className={(props.veriler["strokeRateHighLimit"] != strokeRateHighLimit) ? "editStar" : "star"}>*</span>} {unitSelection2[strokeRateUnitSelection]}</span> </div>
                    </div>
                </div>
            </div>
            <div className="buttonDiv">
                <button onClick={() => varsayilanlaraSifirla()} className="nova-kaydet-button">TEMİZLE</button>
                <button onClick={() => manuelKaydet(props.cihazKullanici,
                    {
                        strokeRate: parseInt(strokeRate),
                        strokeRateLowLimit: parseInt(strokeRateLowLimit),
                        strokeRateHighLimit: parseInt(strokeRateHighLimit),
                        runMode: parseInt(runMode)
                    }
                )} className="nova-kaydet-button">KAYDET</button>
            </div>
        </>
    )


}
export default ManuelArkaTaraf