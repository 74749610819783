import React, { useEffect, useState } from "react"
import "./sinyal-ppm-on-taraf.css"
import axios from "axios"
import Swal from "sweetalert2"
import CookieManager from "../../../../../cookieManager"
import {
    mode,
    state,
    isletimModu,
    unitSelection,
    unitSelection2,
    icolarMod,
    icolarState
} from './dictionaries'
import {
    Input,
} from "./common-components"

const SinyalOranArkaTaraf = (props) => {

    let altMode = {
        "0": "Sinyal Frekans",
        "1": "Sinyal Genişlik",
        "2": "Sinyal Oranı"
    }

    let [baslangic, setBaslangic] = useState(false)
    let [showSettingsMenu, setShowSettingsMenu] = useState(false)
    let [strokeRateUnitSelection, setStrokeRateUnitSelection] = useState(null)
    let [runMode, setRunMode] = useState(null)
    let [editx, setEditx] = useState(false)
    let [strokeRate, setStrokeRate] = useState(null)
    let [strokeRateLowLimit, setStrokeRateLowLimit] = useState(null)
    let [strokeRateHighLimit, setStrokeRateHighLimit] = useState(null)
    let [minimumFrequency, setMinimumFrequency] = useState(null)
    let [maximumFrequency, setMaximumFrequency] = useState(null)
    let [minimumPulseWidth, setMinimumPulseWidth] = useState(null)
    let [maximumPulseWidth, setMaximumPulseWidth] = useState(null)
    let [minimumDuty, setMinimumDuty] = useState(null)
    let [maximumDuty, setMaximumDuty] = useState(null)
    let [icoRunMode, setIcoRunMode] = useState(null)
    let [showLoadScreen, setShowLoadScreen] = useState(true)
    let [configLock, setConfigLock] = useState(true)
    //for things that should only run once

    let location = window.location.pathname.split("r/")[1]
    let seriNo = location.split("#")[0]

    async function manuelKaydet(cihazKullanici, data) {
        // console.log(data)
        let gonderilecekData = {}
        let keys = Object.keys(data)
        keys.map((e) => {
            if (!isNaN(data[e])) {
                gonderilecekData[e] = data[e]
            }
        })
        let gonderilecek = {
            seriNo: seriNo,
            data: gonderilecekData
        }
        // console.log(gonderilecekData)
        try {
            const res = await axios.post("https://api.enelsa.com:5015/api/cihazAyarNova", JSON.stringify(gonderilecek), {
                headers: {
                    "Content-Type": "application/json",
                    "authorization": "Bearer " + CookieManager.getCookie("token")
                }
            })
            if (res.data === "ok") {
                let gonderilecekData = {
                    seriNo: seriNo
                }
                try {
                    const res = await axios.post('https://api.enelsa.com:5015/api/cihazAyarKontrol', JSON.stringify(gonderilecekData), {
                        headers: {
                            'Content-Type': 'application/json',
                            'authorization': 'Bearer ' + CookieManager.getCookie("token")
                        }
                    })
                    if (res.status == 200) {
                        Swal.fire({
                            icon: 'success',
                            title: 'Ayar Başarılı',
                            text: 'Güncelleme işlemi 1-5 saniye içinde gerçekleşecektir.',
                            confirmButtonText: 'Tamam',
                            confirmButtonColor: 'rgb(70, 85, 105)',
                            width: '25em',
                        })
                        setEditx(true)
                    }

                } catch (e) {
                    // console.log(e)
                }
            }
            setEditx(true)
            setShowLoadScreen(true)
            setShowSettingsMenu(false)
        } catch (e) {
            setShowLoadScreen(false)
        }
    }

    let classNovaAlt = props.ekranGenisligi < 520 ? "nova-bilgi-kart-alt-mobil" : "nova-bilgi-kart-alt"


    async function varsayilanlaraSifirla() {
        setStrokeRate(props.veriler["strokeRate"])
        setStrokeRateLowLimit(props.veriler["strokeRateLowLimit"])
        setStrokeRateHighLimit(props.veriler["strokeRateHighLimit"])
        setStrokeRateUnitSelection(props.veriler["strokeRateUnitSelection"])
        setMinimumFrequency(props.veriler["minimumFrequency"])
        setMaximumFrequency(props.veriler["maximumFrequency"])
        setMinimumPulseWidth(props.veriler["minimumPulseWidth"])
        setMaximumPulseWidth(props.veriler["maximumPulseWidth"])
        setMinimumDuty(props.veriler["minimumDuty"])
        setMaximumDuty(props.veriler["maximumDuty"])
        setRunMode(props.veriler["runMode"])
        setIcoRunMode(props.veriler["runMode"])
    }

    useEffect(() => {
        setBaslangic(true)
        setStrokeRate(props.veriler["strokeRate"])
        setStrokeRateLowLimit(props.veriler["strokeRateLowLimit"])
        setStrokeRateHighLimit(props.veriler["strokeRateHighLimit"])
        setStrokeRateUnitSelection(props.veriler["strokeRateUnitSelection"])
        setMinimumFrequency(props.veriler["minimumFrequency"])
        setMaximumFrequency(props.veriler["maximumFrequency"])
        setMinimumPulseWidth(props.veriler["minimumPulseWidth"])
        setMaximumPulseWidth(props.veriler["maximumPulseWidth"])
        setMinimumDuty(props.veriler["minimumDuty"])
        setMaximumDuty(props.veriler["maximumDuty"])
        setRunMode(props.veriler["runMode"])
        setIcoRunMode(props.veriler["runMode"])
    }, [baslangic, editx && props])

    useEffect(() => {
        setShowLoadScreen(false)
        setShowSettingsMenu(false)
        setEditx(false)
    }, [props.veriler])

    function runModeEdit() {
        runMode == 0 ? setRunMode("1") : setRunMode("0")
        icoRunMode == 0 ? setIcoRunMode("1") : setIcoRunMode("0")
    }

    return (
        <>
            <div className="cihaz-bilgi-kart-icerik-cerceve2">
                <div className="nova-bilgi-kart-ust">
                    <div className="nova-isletim-modu">
                        <div>İşletim Modu : {isletimModu[props.veriler["operatingMode"]]}</div>
                        <div>Sinyal Giriş Modu : {altMode[props.veriler["pulseRatioSubMode"]]}</div>
                    </div>
                </div>
                <div className="nova-bilgi-kart-orta-ppm">
                    <div className="vurus-sayisi">
                        <div className="vurus-ust">
                            Vuruş Oranı
                        </div>
                        <hr className="vurus-cizgi"></hr>
                        <div className="vurus-alt">
                            <span>{props.veriler["strokeRate"]}
                                {configLock ?
                                    <button className="nova-bilgi-kart-orta-unit-selection-button" >{unitSelection[strokeRateUnitSelection]}</button>
                                    :
                                    <button className="nova-bilgi-kart-orta-unit-selection-button" onClick={() => { setShowSettingsMenu(true) }}>{unitSelection[strokeRateUnitSelection]}</button>
                                }
                            </span>

                        </div>
                        <div>
                            <hr className=""></hr>
                            <div className="icos">
                                <div className="calisma-modux">
                                    <div>Çalışma Modu : <span>{mode[runMode]}</span> {<span className={(mode[props.veriler["runMode"]] != mode[runMode]) ? "editStar" : "star"}>*</span>}</div>
                                    <span style={{ cursor: "pointer" }} onClick={runModeEdit}>{icolarMod[icoRunMode]} </span>
                                </div>
                                <div className="calisma-modux">
                                    <div>Durum : <span>{state[props.veriler["runModeState"]]} {<span className="star">*</span>}</span></div>
                                    <span>{icolarState[props.veriler["runModeState"]]} </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={classNovaAlt}>
                    <div className="kapasite-limit">
                        <div>Kapasite Alt Limit : <span>{Input(strokeRateLowLimit, setStrokeRateLowLimit, "nova-manuel-alt-input")} {<span className={(props.veriler["strokeRateLowLimit"] != strokeRateLowLimit) ? "editStar" : "star"}>*</span>} {unitSelection2[strokeRateUnitSelection]}</span> </div>
                        <div>Kapasite Üst Limit : <span>{Input(strokeRateHighLimit, setStrokeRateHighLimit, "nova-manuel-alt-input")} {<span className={(props.veriler["strokeRateHighLimit"] != strokeRateHighLimit) ? "editStar" : "star"}>*</span>} {unitSelection2[strokeRateUnitSelection]}</span> </div>
                    </div>
                    {(props.veriler["pulseRatioSubMode"] == "0") &&
                        <div className="kapasite-limit">
                            <div>Sinyal Frekans Min : <span>{Input(minimumFrequency, setMinimumFrequency, "nova-manuel-alt-input")} {<span className={(props.veriler["minimumFrequency"] != minimumFrequency) ? "editStar" : "star"}>*</span>} Hz</span></div>
                            <div>Sinyal Frekans Max  : <span>{Input(maximumFrequency, setMaximumFrequency, "nova-manuel-alt-input")} {<span className={(props.veriler["maximumFrequency"] != maximumFrequency) ? "editStar" : "star"}>*</span>} Hz</span></div>
                        </div>
                    }
                    {(props.veriler["pulseRatioSubMode"] == "1") &&
                        <div className="kapasite-limit">
                            <div>Sinyal Genişlik Min : <span>{Input(minimumPulseWidth, setMinimumPulseWidth, "nova-manuel-alt-input")} {<span className={(props.veriler["minimumPulseWidth"] != minimumPulseWidth) ? "editStar" : "star"}>*</span>} ms</span></div>
                            <div>Sinyal Genişlik Max  : <span>{Input(maximumPulseWidth, setMaximumPulseWidth, "nova-manuel-alt-input")} {<span className={(props.veriler["maximumPulseWidth"] != maximumPulseWidth) ? "editStar" : "star"}>*</span>} ms</span></div>
                        </div>
                    }
                    {(props.veriler["pulseRatioSubMode"] == "2") &&
                        <div className="kapasite-limit">
                            <div>Sinyal Oran Min : <span>{Input(minimumDuty, setMinimumDuty, "nova-manuel-alt-input")} {<span className={(props.veriler["minimumDuty"] != minimumDuty) ? "editStar" : "star"}>*</span>} % </span></div>
                            <div>Sinyal Oran Max : <span>{Input(maximumDuty, setMaximumDuty, "nova-manuel-alt-input")} {<span className={(props.veriler["maximumDuty"] != maximumDuty) ? "editStar" : "star"}>*</span>} % </span></div>
                        </div>
                    }
                </div>
            </div>
            <div className="buttonDiv">
                {(!showSettingsMenu && !showLoadScreen) && <>
                    <button onClick={() => varsayilanlaraSifirla()} className="nova-kaydet-button">TEMİZLE</button>
                    <button onClick={() => manuelKaydet(props.cihazKullanici,
                        {
                            strokeRate: parseInt(strokeRate),
                            strokeRateLowLimit: parseInt(strokeRateLowLimit),
                            strokeRateHighLimit: parseInt(strokeRateHighLimit),
                            runMode: parseInt(runMode),
                            minimumFrequency: parseInt(minimumFrequency),
                            maximumFrequency: parseInt(maximumFrequency),
                            minimumPulseWidth: parseInt(minimumPulseWidth),
                            maximumPulseWidth: parseInt(maximumPulseWidth),
                            minimumDuty: parseInt(minimumDuty),
                            maximumDuty: parseInt(maximumDuty),
                        }
                    )} className="nova-kaydet-button">KAYDET</button>
                </>
                }
            </div>
        </>
    )
}

export default SinyalOranArkaTaraf