import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';
import axios from "axios"
import ReactApexChart from 'react-apexcharts';

let cihazIcerikTimeOut = null
let grafikBilgileri = async (durum) => {
    // if(window.location.pathname !== "/grafikler/" + window.location.pathname.split('r/')[1])
    //     return
    // try{
    let res = await axios.get("http://localhost:3001/api/grafik" + window.location.pathname.split('k/')[1], {
        headers: {
            'authorization': 'Bearer ' + this.props.tokenBilgileri.token
        }
    })
    // // console.log(res.data," 44545")
    // }
    // catch{
    //     if(durum)
    //         //window.location.replace("/anasayfa")
    //     throw new Error("Hata")
    // }
}
var trigoStrength = 3;
var iteration = 11;
function generateMinuteWiseTimeSeries(baseval, count, yrange) {
    var i = 0;
    var series = [];
    while (i < count) {
        var x = baseval;
        var y =
            (Math.sin(i / trigoStrength) * (i / trigoStrength) +
                i / trigoStrength +
                1) *
            (trigoStrength * 2);

        series.push([x, y]);
        baseval += 300000;
        i++;
    }
    return series;
}

class Grafikler extends React.Component {
    constructor(props) {
        super(props);
        // // console.log(this.props)
        this.updateCharts = this.updateCharts.bind(this);

        this.state = {
            optionsMixedChart: {
                chart: {
                    id: "basic-bar",
                    toolbar: {
                        show: true
                    }
                },
                plotOptions: {
                    bar: {
                        columnWidth: "50%"
                    }
                },
                stroke: {
                    width: [4, 0, 0]
                },
                xaxis: {
                    type: "datetime",
                    range: 2700000
                },
                markers: {
                    size: 6,
                    strokeWidth: 3,
                    fillOpacity: 0,
                    strokeOpacity: 0,
                    hover: {
                        size: 8
                    }
                },
                yaxis: {
                    tickAmount: 5,
                    min: 0,
                    max: 100
                }
            },
            seriesMixedChart: [{
                name: "series-1",
                type: "line",
                data: generateMinuteWiseTimeSeries(
                    new Date("12/12/2016 00:20:00").getTime(),
                    12,
                    {
                        min: 30,
                        max: 110
                    }
                )
            }],
        }
    }
    updateCharts() {
        const max = 90;
        const min = 30;
        const newMixedSeries = [];
        if (cihazIcerikTimeOut !== null)
            clearTimeout(cihazIcerikTimeOut)
        this.state.seriesMixedChart.forEach((s) => {
            const data = s.data.map(() => {
                return Math.floor(Math.random() * (max - min + 1)) + min;
            });
            newMixedSeries.push({ data: data, type: s.type });
        });

        this.setState({
            seriesMixedChart: newMixedSeries
        });
        cihazIcerikTimeOut = setTimeout(this.updateCharts, 5000)
        // grafikBilgileri(true)
    }

    componentDidMount() {
        this.updateCharts()
    }

    render() {
        return (
            <>
                <div className='grafikler-div'>
                    <p>pH</p>
                    <ReactApexChart options={this.state.optionsMixedChart} series={this.state.seriesMixedChart} type="line" width={500} height={320} />
                </div>
            </>
        )
    }
}


export default Grafikler