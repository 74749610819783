function SelectMenu(valueDegeri, valueDegeriFonksiyonu, secimleri) {
    return (
        <div className="custom-select">
            <select className="nova-select-menu" value={valueDegeri} onChange={(e) => valueDegeriFonksiyonu(e.target.value)}>
                {
                    Object.values(secimleri).map((e, i) => {
                        return (<option value={i}>{e}</option>)
                    })
                }
            </select>
        </div>
    )
}

export default SelectMenu