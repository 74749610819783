import axios from "axios"
import CookieManager from "../../../../cookieManager"


const cihazSayfaDataIslemleri = async (token, id) => {
    let cihaz = (window.location.pathname.split("r/")[1])?.split("#")[0]
    let gonderilecekData = {
        seriNo: cihaz,
        id: CookieManager.getCookie("id")
    }
    // console.log(gonderilecekData)
    try {
        // info'dan kullaniciAdi,cihazAdi ve tüm verileri çekmek için
        let data = await axios.post("https://api.enelsa.com:5015/api/device-data", gonderilecekData, {
            headers: {
                'authorization': 'Bearer ' + CookieManager.getCookie("token")
            }
        })

        // anlık veri son tarihini çekmek için 
        let time = await axios.get("https://api.enelsa.com:5015/api/get-time/"+ cihaz, {
            headers: {
                'authorization': 'Bearer ' + CookieManager.getCookie("token")
            }
        })

        // kanalRenkleri, isim, kanalIsimlerini, alarmBildirimSMS, alarmBildirimMail çekmek için 
        let data2 = await axios.post("https://api.enelsa.com:5015/api/device-channel-name", gonderilecekData, {
            headers: {
                'authorization': 'Bearer ' + CookieManager.getCookie("token")
            }
        })

        let data4 = await axios.post("https://api.enelsa.com:5015/api/raporFreqSorgula", gonderilecekData, {
            headers: {
                'authorization': 'Bearer ' + CookieManager.getCookie("token")
            }
        })

        // cihaz yetkilileri
        let data5 = await axios.post("https://api.enelsa.com:5015/api/yetkililer", gonderilecekData, {
            headers: {
                'authorization': 'Bearer ' + CookieManager.getCookie("token")
            }
        })
       
        //console.log(data5.data)
        data.data.ilkIsim = data.data.cihazAdi // kurulum yaparken verilen isim
        data.data.kullaniciAdi = data.data.cihazKullanici
        data.data.kanalRenkleri = data2.data.kanalRenkleri
        data.data.kanalIsimleri = data2.data.kanalIsimleri
        data.data.isim = data2.data.isim // kullanıcının verdiği isim
        data.data.tarih = time.data.data ? time.data.data : data.data.cihazTarih
        data.data.cihaz = cihaz //serinumarası
        data.data.alarmSayisi = data.data.alarmlar.alarmSayisi
        data.data.alarmlar = data.data.alarmlar.alarmlar
        data.data.alarmBildirimSMS = data2.data.alarmBildirimSMS
        data.data.alarmBildirimMail = data2.data.alarmBildirimMail
        data.data.periyot = data4?.data[0]?.periyot
        data.data.zamanDamgasi = data4?.data[0]?.zamandamgasi
        data.data.dataYokMaili = data4?.data[0]?.dataYokMaili
        data.data.yetkiliList = data5?.data;
 
        return data.data
    }
    catch (e) {
        // console.log(e)   
    }
}

export default cihazSayfaDataIslemleri