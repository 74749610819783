import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLock } from "@fortawesome/free-solid-svg-icons"

function ConfigLock (props) {
    const {show, onClick} = props
    return (               
        <>
            {show  &&
                <>
                    <div className={ "nova-lock" }></div>
                    <div className="nova-ico-lock">
                        <FontAwesomeIcon icon={faLock} className="cihaz-bilgi-kart-ust-taraf-ico2" onClick={onClick} title="Ayar yapmak için lütfen kilidi açın."/>
                    </div>
                </>
                        
            }
        </>
    )
}
export default ConfigLock