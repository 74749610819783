import { React, useEffect, useState } from "react"
import "./cihaz-bilgi-karti.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faGear, faPen, faRotateLeft, faX } from "@fortawesome/free-solid-svg-icons"
import Omnicon from "./img/Varlık 22.png"
import CihazOnTarafi from "./cihaz-on-taraf"
import CihazArkaTaraf from "./cihaz-arka-taraf"
import { faArrowRight, faArrowLeft } from "@fortawesome/free-solid-svg-icons"
import { CihazArkaTarafBrimlerAlarmDuy } from "./arka-taraf-birimler/cihaz-arka-taraf-birimler"
import { inputOlusturFunc as inputOlusturFunc } from "../components/arka-taraf-birimler/cihaz-arka-taraf-birimler"
import axios from "axios"
import CookieManager from "../../../../cookieManager"

const REGEXP_SPECIAL_CHAR =
    /[\!\#\$\%\^\&\*\)\(\+\=\.\<\>\{\}\[\]\:\;\'\"\|\~\`\_\-]/g

const CihazBilgiKarti = (props) => {
    let gosterilecekDatalarinOlusturulmasiAlarmDuy = (veriler, bildirimleriYonet, kanal, renk, renk2, roleAlarmlari) => {
        let gosterilecekDatalarAlarmDuy = []

        veriler.potansiyostatikCikisVerileri = {}

        Object.keys(veriler).forEach(e => {
            Object.keys(veriler[e]).forEach(index => {
                gosterilecekDatalarAlarmDuy.push(<CihazArkaTarafBrimlerAlarmDuy renk={renk} renk2={renk2} icerikVerileri={veriler[e][index]} index={index} bildirimleriYonet={bildirimleriYonet} kanal={kanal} />)
            })
        })
        return gosterilecekDatalarAlarmDuy
    }

    let [popupDurum, setPopupDurum] = useState(false)
    let [popupKonum, setPopupKonum] = useState(-250)
    let [popupDurum2, setPopupDurum2] = useState(false)
    let [popupKonum2, setPopupKonum2] = useState(-250)

    let [popupBilgi, setPopupBilgi] = useState([])
    let [cihazTarafBilgisi, setCihazTarafBilgisi] = useState(true)
    let [cihazDondurme, setCihazDondurme] = useState(true)

    const [isShown, setIsShown] = useState(false)
    const [isShown2, setIsShown2] = useState(false)
    const [isShown3, setIsShown3] = useState(false)

    const [kanalSet, kanalSetAyar] = useState(true)
    const [kanalIsimT, setKanalIsimT] = useState("")

    const popupGoster = (datalar) => {
        let popupDatasi = []
        let dataBasliklari = Object.keys(datalar)
        dataBasliklari.forEach(e => {
            if (e === "yuzde" || e === "analog alarm duyarlılığı" || e === "Röle Alarm Duyarlılığı" || e === "analog alarm duyarlılığı2" || e === "Röle Alarm Duyarlılığı2")
                return
            popupDatasi.push(<span style={{ height: "20px", display: "flex", alignItems: "center" }}>{e}: {datalar[e]}</span>)
        })
        setPopupBilgi(popupDatasi)
        setPopupDurum(true)
        setTimeout(() => setPopupKonum(100), 100)
    }

    const popupGizle = () => {
        setPopupKonum(-250)
        setTimeout(() => setPopupDurum(false), 320)
    }

    const popupGoster2 = (datalar) => {
        setPopupDurum2(true)
        setTimeout(() => setPopupKonum2(50), 50)
    }

    const popupGizle2 = () => {
        setPopupKonum2(-250)
        setTimeout(() => setPopupDurum2(false), 320)
    }

    const pencereyiCevir = () => {
        setCihazDondurme(false)
        setTimeout(() => { setCihazTarafBilgisi(!cihazTarafBilgisi) }, 450)
        setTimeout(() => { setCihazDondurme(true) }, 700)
    }

    let kanalIsim = ""
    if (props.kanalIsim !== "") {
        kanalIsim = props.kanalIsim
    } else {
        kanalIsim = "Kanal " + props.kanal
    }


    useEffect(() => {
        // window.location.reload();
    }, [kanalIsim])

    let kanalIsimKaydet = async (isim) => {
        let regexIsim = isim.replace(REGEXP_SPECIAL_CHAR, '')

        let kaydedilecekİsim = {
            isim: regexIsim,
            id: CookieManager.getCookie("id"),
            cihaz: props.cihaz,
            kanal: props.kanal
        }
        // console.log(kaydedilecekİsim, "1212")
        try {
            let res = await axios.put("https://api.enelsa.com:5015/api/kanalIsmiGuncelle", JSON.stringify(kaydedilecekİsim), {
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': 'Bearer ' + CookieManager.getCookie("token")
                }
            })
            // props.temaAyarDegistir()
            setKanalIsimT(regexIsim)
            popupGizle2()
        } catch (e) {
            // console.log(e)
        }
    }

    return (
        <>
            <div style={{ transform: cihazDondurme ? "rotateY(0deg)" : "rotateY(90deg)", backgroundColor: props.renk }} id={props.kanal} className="cihaz-bilgi-kart-cerceve">
                <div onClick={popupGizle} className="cihaz-bilgi-kart-popup" style={{ display: popupDurum ? "flex" : "none" }}>
                    <div className="cihaz-bilgi-kart-popup-cerceve" style={{ top: popupKonum + "px", backgroundColor: props.renk }}>
                        <img src={Omnicon} alt="omnicon" className="cihaz-bilgi-kart-popup-gorsel" />
                        <div className="cihaz-bilgi-kart-popup-gorsel-golge">
                            <div></div>
                        </div>
                        <div className="cihaz-bilgi-kart-popup-icerk">
                            {popupBilgi.map((e) => { return e })}
                        </div>
                    </div>
                </div>

                <div className="cihaz-bilgi-kart-icerik-cerceve">
                    <div className="cihaz-bilgi-kart-ust-taraf" style={{ backgroundColor: props.renk2 }}>
                        <div>
                            <span style={{ color: props.renk, transition: "all 0.5s" }}>{props.kanal}</span>
                        </div>
                        <p>{props.veriler?.ismi}</p>
                    </div>
                    <div className={isShown3 ? "underline kanalIsim" : "kanalIsim"}>
                        {kanalIsim} {props.temaAyar && <FontAwesomeIcon onMouseEnter={() => setIsShown3(true)} onMouseLeave={() => setIsShown3(false)} icon={faPen} onClick={() => popupGoster2(true)} className="cihaz-bilgi-kart-ust-taraf-ico" />}
                    </div>
                    {
                        (kanalSet && (!props.configLock)) &&
                        <div>
                            <FontAwesomeIcon icon={cihazTarafBilgisi ? faGear : faRotateLeft} className="cihaz-bilgi-kart-ust-taraf-ico" onClick={pencereyiCevir} />
                        </div>
                    }
                </div>
                <>
                    {
                        kanalSet
                            ?
                            <>
                                {
                                    cihazTarafBilgisi
                                        ?
                                        <>
                                            <CihazOnTarafi popupGoster={popupGoster} veriler={props.veriler} />
                                            <>
                                                {
                                                    (Object.keys(props.veriler?.kanalRoleleri).length !== 0 || Object.keys(props.veriler?.kanalAnalogCikislari).length !== 0) &&
                                                    <div onClick={() => kanalSetAyar(!kanalSet)} style={{ width: "-webkit-fill-available" }}>
                                                        <div style={{ display: "flex", 'justifyContent': "space-between" }}>
                                                            <>
                                                                <div style={{ opacity: isShown ? "1" : "0", display: "flex", alignItems: "flex-end", marginLeft: "10px" }}>
                                                                    <span style={{ color: "aliceblue", 'fontSize': "x-small" }}>{kanalSet ? "" : "Genel Bilgiler"}</span>
                                                                </div>
                                                                <div style={{ display: "flex", marginLeft: kanalSet ? "47px" : "0", marginRight: kanalSet ? "0" : "80px" }}>
                                                                    <FontAwesomeIcon onMouseEnter={() => setIsShown(true)} onMouseLeave={() => setIsShown(false)} style={{ marginTop: "2px", opacity: kanalSet ? "0" : "1" }} icon={faArrowLeft} className="cihaz-bilgi-kart-ust-taraf-ico tooltip" />
                                                                    <span className="cihaz-bilgi-kart-ust-taraf-ico">- <>{kanalSet ? 1 : 2}</> -</span>
                                                                    <FontAwesomeIcon onMouseEnter={() => setIsShown2(true)} onMouseLeave={() => setIsShown2(false)} style={{ marginTop: "2px", opacity: kanalSet ? "1" : "0" }} icon={faArrowRight} className="cihaz-bilgi-kart-ust-taraf-ico tooltip" />
                                                                </div>
                                                                <div style={{ opacity: isShown2 ? "1" : "0", display: "flex", alignItems: "flex-end", marginRight: "10px" }}>
                                                                    <span style={{ color: "aliceblue", 'fontSize': "x-small" }}>{kanalSet ? "Alarm Duyarlılığı" : ""}</span>
                                                                </div>
                                                            </>
                                                        </div>
                                                    </div>
                                                }
                                            </>
                                        </>
                                        : <>
                                            <CihazArkaTaraf tokenBilgileri={props.tokenBilgileri} zamanAsimi={props.veriler.zamanAsimi} limitler={props.veriler.limitler} renk={props.renk} renk2={props.renk2} veriler={{ "roleVerileri": props.veriler.kanalRoleleri, "analogCikisVerileri": props.veriler.kanalAnalogCikislari, "potansiyostatikCikisVerileri": props.veriler.potansiyostatik }} bildirimleriYonet={props.bildirimleriYonet} kanal={props.kanal} />
                                        </>
                                }
                            </>
                            :
                            <>
                                <div id="cihaz-on-taraf">
                                    <div id="cihaz-on-taraf-kanal-degeri">
                                        <span>{props.veriler.kanalDegeri + " " + props.veriler.kanalBirimi}</span>
                                    </div>
                                    <div className="arka-pencere">
                                        {gosterilecekDatalarinOlusturulmasiAlarmDuy(props.veriler, props.bildirimleriYonet, props.kanal, props.renk, props.renk2, props.roleAlarmlari).map(e => e)}
                                    </div>
                                </div>
                                <div style={{ width: "100%", display: "flex" }}>
                                    <div onClick={() => kanalSetAyar(!kanalSet)} style={{ width: "100%" }}>
                                        <div style={{ display: "flex", 'justifyContent': "space-between" }}>
                                            <>
                                                <div style={{ opacity: isShown ? "1" : "0", display: "flex", alignItems: "flex-end", marginLeft: "10px", justifyContent: "flex-start" }}>
                                                    <span style={{ color: "aliceblue", 'fontSize': "x-small" }}>{kanalSet ? "" : "Genel Bilgiler"}</span>
                                                </div>
                                                <div style={{ display: "flex", marginLeft: kanalSet ? "47px" : "0", marginRight: kanalSet ? "0" : "80px" }}>
                                                    <FontAwesomeIcon onMouseEnter={() => setIsShown(true)} onMouseLeave={() => setIsShown(false)} style={{ marginTop: "2px", opacity: kanalSet ? "0" : "1" }} icon={faArrowLeft} className="cihaz-bilgi-kart-ust-taraf-ico tooltip" />
                                                    <span className="cihaz-bilgi-kart-ust-taraf-ico">- <>{kanalSet ? 1 : 2}</> -</span>
                                                    <FontAwesomeIcon onMouseEnter={() => setIsShown2(true)} onMouseLeave={() => setIsShown2(false)} style={{ marginTop: "2px", opacity: kanalSet ? "1" : "0" }} icon={faArrowRight} className="cihaz-bilgi-kart-ust-taraf-ico tooltip" />
                                                </div>
                                                <div style={{ opacity: isShown2 ? "1" : "0", display: "flex", alignItems: "flex-end", marginRight: "10px" }}>
                                                    <span style={{ color: "aliceblue", 'fontSize': "x-small" }}>{kanalSet ? "Alarm Duyarlılığı" : ""}</span>
                                                </div>
                                            </>
                                        </div>
                                    </div>
                                </div>
                            </>
                    }
                </>
                <div className="cihaz-bilgi-kart-popup" style={{ display: popupDurum2 ? "flex" : "none" }}>
                    <div className="cihaz-bilgi-kart-popup-cerceve" style={{ width: "290px", height: "180px", top: "25%", background: "linear-gradient(to right bottom, hsl(236, 50%, 50%), hsl(195, 50%, 50%))" }}>
                        <div className="cihaz-bilgi-kart-popup-icerk icerik">
                            <FontAwesomeIcon icon={faX} style={{ alignSelf: "end", color: "#F7FBFF", cursor: "pointer", width: "0.8rem", height: "0.8rem", border: "1px solid hsla(0, 0%, 100%, .4)", borderRadius: "100rem", display: "flex", padding: "10px" }} onClick={popupGizle2} />
                            <p style={{ margin: "auto", fontSize: "17px", marginBottom: "15px", borderBottom: "1px solid hsla(0, 0%, 100%, .4)", textAlign: "center", paddingBottom: "1rem" }}>Kanal İsmi Düzenle</p>
                            <div style={{ marginBottom: "10px" }}>
                                <span>Kanal İsim : </span>
                                <input style={{ width: "169px", outline: "none", paddingLeft: "4px", color: "#42494F" }} className="arka-taraf-birimler-input2" value={kanalIsimT} onChange={(e) => setKanalIsimT(e.target.value)} />
                            </div>
                            <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                <div onClick={() => { kanalIsimKaydet(kanalIsimT) }} className="modal__btn" style={{ padding: "0.8rem", fontSize: "15px" }}>
                                    <span>KAYDET</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CihazBilgiKarti