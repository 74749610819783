import startIcon from '../../../img/1-1-03.png'
import stopIcon from "../../../img/1-1-02.png"
import triggerIcon from "../../../img/1-1-04.png"
import initialTriggerIcon from "../../../img/1-1-05.png"
import startIconBlack from '../../../img/1-1-03-black.png'
import stopIconBlack from "../../../img/1-1-02-black.png"
import triggerIconBlack from "../../../img/1-1-04-black.png"
import initialTriggerIconBlack from "../../../img/1-1-05-black.png"

let icolarState = {
    "1": <img src={startIcon} style={{marginRight: "0px"}} className="on-taraf-role-ico" alt={"Açık"}/>,
    "0": <img src={stopIcon} style={{marginRight: "0px"}} className="on-taraf-role-ico" alt={"Kapalı"}/>,
    "2": <img src={triggerIcon} style={{marginRight: "0px"}} className="on-taraf-role-ico" alt={"Tetik"}/>,
    "3": <img src={initialTriggerIcon} style={{marginRight: "0px"}} className="on-taraf-role-ico" alt={"Başlangıçta"}/>
}

let icolarStateBlack = {
    "1": <img src={startIconBlack} style={{marginRight: "0px"}} className="on-taraf-role-ico" alt={"Açık"}/>,
    "0": <img src={stopIconBlack} style={{marginRight: "0px"}} className="on-taraf-role-ico" alt={"Kapalı"}/>,
    "2": <img src={triggerIconBlack} style={{marginRight: "0px"}} className="on-taraf-role-ico" alt={"Tetik"}/>,
    "3": <img src={initialTriggerIconBlack} style={{marginRight: "0px"}} className="on-taraf-role-ico" alt={"Başlangıçta"}/>
}

export { icolarState, icolarStateBlack }