import React from "react";
import "./ana-sayfa.css"
import { faWifi, faCircleArrowRight, faTriangleExclamation, faCirclePlay, faMicrochip, faSlash, faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import axios from "axios"
import TemaBirCihaz from "../cihazlar/pages/cihazlar-sayfasi/components/tema-bir/tema-bir-cihaz.js";
import TemaBirCihazYuklemeAnimasyonu from "../cihazlar/pages/cihazlar-sayfasi/components/tema-bir/tema-bir-cihaz-yukleme";
import cihazDatalariniEldeEt from "../cihazlar/pages/cihazlar-sayfasi/data-islemleri/cihazlar-data";
import cihazVerileriniEldeEt from "../cihazlar/pages/cihazlar-sayfasi/data-islemleri/cihazlar-verileri.js";
import { Map, Marker, ZoomControl } from "pigeon-maps";
import CookieManager from "../cookieManager.js";

let ilkOlusturma = true
const simgelerinSagaOlanUzakligiWeb = [-25, -20, -15, -10, -20, -15, -55, -50]
const simgelerinSagaOlanUzakligiMobil = [-17, -14, -11, -8, -20, -15, -43, -40]

const temelArkaPlanRenkleri = ["#005fab", "#02bb4a", "#f59c1a", "#ff5b57", "#009ceb"]
const ustPerdeArkaPlanRenkleri = ["#005eab85", "#02bb4985", "#f59d1a85", "#ff5a5791", "#009ceb85"]
const cihazDurumBilgisiTxt = ["Tüm Cihazlar", "Stabil Cihazlar", "Alarm Veren Cihazlar", "Haberleşmeyen Cihazlar", "Favori Cihazlar"]
const simgeler = [faMicrochip, faCirclePlay, faTriangleExclamation, faWifi, faSlash, faStar]
let cihazIcerikTimeOut = null

class Anasayfa extends React.Component {
    state = {
        acikPencereKonumu: 0,
        cihazDatalari: [],
        cihazData: [],
        pinliler: [],
        ekranGenisligi: window.innerWidth,
        cihazVerileri: []
    }

    yanyanaOlacakMiktariHesapla() {
        let miktar = 4
        if (this.props.ekranGenisligi - this.props.menuGenisligi < 1580)
            miktar = 2
        return miktar
    }


    simgeleriOlustur(sira, wifiCizgisi) {
        return (
            <>
                <FontAwesomeIcon icon={simgeler[wifiCizgisi ? sira : sira + 1]} style={{ right: (this.props.ekranGenisligi > 1000 ? simgelerinSagaOlanUzakligiWeb[sira * 2] : simgelerinSagaOlanUzakligiMobil[sira * 2]) + "px" }} className="simge-golge" />
                <FontAwesomeIcon icon={simgeler[wifiCizgisi ? sira : sira + 1]} style={{ right: (this.props.ekranGenisligi > 1000 ? simgelerinSagaOlanUzakligiWeb[(sira * 2) + 1] : simgelerinSagaOlanUzakligiMobil[(sira * 2) + 1]) + "px" }} className="simge" />
            </>
        )
    }

    cihazBilgileriGuncelle = async (durum) => {
        if (window.location.pathname !== "/anasayfa")
            return
        let gonderilecekData = {
            id: CookieManager.getCookie("id")
        }
        try {
            let res = await axios.post("https://api.enelsa.com:5015/api/anasayfa", gonderilecekData, {
                headers: {
                    'authorization': 'Bearer ' + CookieManager.getCookie("token")
                }
            })
            let keys = Object.keys(res.data["cihazMiktarlari"])
            let cihazBilgileri = Array(5)
            keys.forEach((e, i) => {
                cihazBilgileri[i] = res.data["cihazMiktarlari"][e]
            })
            this.props.cihazBilgileriGuncelle2(res.data.pinliler)
            this.props.cihazBilgileriGuncelle(cihazBilgileri)

            setTimeout(() => { this.cihazBilgileriGuncelle(true) }, 10000)
        }
        catch {
            if (durum)
                //window.location.replace("/anasayfa")
                throw new Error("Hata")
        }
    }

    cihazVerileriniGuncelleData = async () => {
        let gonderilecekData = {
            id: CookieManager.getCookie("id")
        }
        if (CookieManager.getCookie("token")) {
            if (cihazIcerikTimeOut !== null)
                clearTimeout(cihazIcerikTimeOut)
            let dataIsimleri = await axios.post("https://api.enelsa.com:5015/api/alarmBilgileri", gonderilecekData, {
                headers: {
                    'authorization': 'Bearer ' + CookieManager.getCookie("token")
                }
            })
            this.setState({ cihazData: dataIsimleri })
        }
    }
    intervalId = null; // Interval ID'sini saklamak için

    tekrarDene = async () => {
        if (CookieManager.getCookie("id") === "-") {
            // console.log("Kullanıcı bilgileri henüz yüklenmedi, tekrar denenecek...");
            setTimeout(this.tekrarDene, 1000);
        } else {
            await this.cihazBilgileriGuncelle(false);
            await this.cihazVerileriniGuncelle();
            await this.cihazVerileriniGuncelleData();
        }
    }

    async componentDidMount() {
        this.props.konumGuncelle("anasayfa");
        try {
            await this.props.kullaniciBilgileriGuncelle();
            this.tekrarDene();
            this.intervalId = setInterval(async () => {
                await this.cihazVerileriniGuncelle();
                await this.cihazVerileriniGuncelleData();
            }, 20000); // 10000 ms 
        }
        catch (e) { 
            // console.log(e) 
        }
    }

    componentWillUnmount() {
        // Bileşen kaldırıldığında interval'i temizle
        if (this.intervalId) {
            clearInterval(this.intervalId);
        }
    }

    cihazVerileriniGuncelle = async () => {
        if (CookieManager.getCookie("token")) {
            if (cihazIcerikTimeOut !== null)
                clearTimeout(cihazIcerikTimeOut)
            let cihazDatalari = await cihazDatalariniEldeEt(CookieManager.getCookie("id"))
            Object.keys(cihazDatalari.cihazlar).forEach(e => {
                let pozisyon = true
                if (!ilkOlusturma && this.state.cihazDatalari[e] !== undefined) {
                    if (this.state.cihazDatalari[e]["icerikEkraniAcikKapali"] !== undefined)
                        pozisyon = this.state.cihazDatalari[e]["icerikEkraniAcikKapali"]
                }
                cihazDatalari.cihazlar[e]["icerikEkraniAcikKapali"] = pozisyon
            })
            let cihazBilgileri = Array(5)
            let cihazVerileri = await cihazVerileriniEldeEt(cihazDatalari.pinliler)

            Object.keys(cihazDatalari.cihazMiktarlari).forEach((e, i) => {
                cihazBilgileri[i] = cihazDatalari.cihazMiktarlari[e]
            })

            this.setState({ cihazDatalari: cihazDatalari.cihazlar, cihazBilgileri: cihazBilgileri, cihazVerileri: cihazVerileri, pinliler: cihazDatalari.pinliler, alarmVerenCihazlar: cihazDatalari.alarmVerenCihazlar, haberlesmeyenCihazlar: cihazDatalari.haberlesmeyenCihazlar })
            ilkOlusturma = false
            cihazIcerikTimeOut = setTimeout(this.cihazVerileriniGuncelle, 1000)
        }
    }


    ekranBoyutunaGoreYanYanaGosterilecekEnFazlaElementSayisi() {
        let kullanılabilirGenislik = this.state.ekranGenisligi - this.props.menuGenisligi
        if (kullanılabilirGenislik > 2030) {
            return 6
        } else if (kullanılabilirGenislik > 1690) {
            return 5
        } else if (kullanılabilirGenislik > 1355) {
            return 4
        } else if (kullanılabilirGenislik > 1045) {
            return 3
        } else if (kullanılabilirGenislik > 720) {
            return 2
        }
        return 1
    }

    icerikEkraniBoyutuAyarla = (cihaz) => {
        let guncellenecekData = this.state.cihazDatalari
        guncellenecekData[cihaz].icerikEkraniAcikKapali = !guncellenecekData[cihaz].icerikEkraniAcikKapali
        this.setState({ cihazDatalari: guncellenecekData })
    }

    ekrandaGosterilecekDatalariOlustur(tokenBilgileri, filtrelenmisData) {
        const { cihazDatalari, alarmVerenCihazlar, haberlesmeyenCihazlar, stabilCihazlar, cihazVerileri, cihazData } = this.state;
        const { tema, kullaniciBilgileri, gosterilecekCihaziGuncelle } = this.props;

        let gosterilecekElemanSayisi = this.ekranBoyutunaGoreYanYanaGosterilecekEnFazlaElementSayisi()

        if (this.state.cihazDatalari?.length === 0) {

            let olusturulacak = [];
            return olusturulacak
        }
        if (gosterilecekElemanSayisi > filtrelenmisData?.length)
            gosterilecekElemanSayisi = filtrelenmisData?.length

        let olusturulacak = [...Array(gosterilecekElemanSayisi)].map(e => Array(1))
        if (Object.keys(filtrelenmisData).length > 0) {
            filtrelenmisData.forEach((e, i) => {
                if (cihazDatalari[e]) {
                    let m = 1; // Default value for stabil cihazlar

                    if (alarmVerenCihazlar.includes(e)) {
                        m = 3;
                    } else if (haberlesmeyenCihazlar.includes(e)) {
                        m = 4;
                    }

                    const component = <TemaBirCihaz
                        durum={m}
                        kullaniciId={kullaniciBilgileri.kullaniciId}
                        tokenBilgileri={tokenBilgileri}
                        key={e}
                        cihazVerileri={cihazDatalari[e]}
                        cihazData={cihazData}
                        cihazDegerleri={cihazVerileri[e] || []}
                        icerikEkraniBoyutuAyarla={this.icerikEkraniBoyutuAyarla}
                        gosterilecekCihaziGuncelle={gosterilecekCihaziGuncelle} />

                    olusturulacak[i % gosterilecekElemanSayisi].push({ component, id: e });
                }
            }
            )
        }

        return olusturulacak
    }

    render() {
        return (
            this.props.deneme ?
                <div className="anasayfa">
                    <div className="cihaz-bilgi-penceresi" style={this.props.ekranGenisligi > 640 ? { gridTemplateColumns: this.props.ekranGenisligi > 640 ? "repeat(" + this.yanyanaOlacakMiktariHesapla() + ", 1fr)" : "repeat(1, 1fr)" } : {}}>
                        {
                            Array.from(Array(4).keys()).map(i => {
                                return (
                                    i === 1
                                        ?
                                        <div style={{ display: "flex" }} className="gradientPin">
                                            <div style={{ borderRadius: "10px 0 0 10px" }} className="ayrinti-genel-pencere " key={i + Math.random()}>
                                                {this.simgeleriOlustur(i, true)}
                                                <div style={{ backgroundColor: ustPerdeArkaPlanRenkleri[i] }} className="ust-perde"></div>
                                                <div className="cihaz-bilgi-yazisi-divi">
                                                    <p className="cihaz-bilgi-yazisi">{this.props.cihazBilgileri[i]}</p>
                                                </div>
                                                <p className="cihaz-durum-bilgisi-txt">{cihazDurumBilgisiTxt[i]}</p>
                                                <Link to="/cihazlar" onClick={() => { this.props.temelFiltreDegistir(temelArkaPlanRenkleri[i]); this.props.konumGuncelle("cihazlar") }}>
                                                    <div className="ayrintilar-git-peneresi">
                                                        <p className="ayrintilar-git-text">Ayrıntıları Gör</p>
                                                        <FontAwesomeIcon icon={faCircleArrowRight} className="ayrintilar-git-simge" />
                                                    </div>
                                                </Link>
                                            </div>
                                            <div style={{ borderRadius: "0 10px 10px 0" }} className="ayrinti-genel-pencere" key={i + Math.random()}>
                                                <div className="starIco">{this.simgeleriOlustur(5, true)}</div>
                                                <div style={{ backgroundColor: ustPerdeArkaPlanRenkleri[4] }} className="ust-perde"></div>
                                                <p className="cihaz-durum-bilgisi-txt">{cihazDurumBilgisiTxt[4]}</p>
                                                <div className="cihaz-bilgi-yazisi-divi">
                                                    <p className="cihaz-bilgi-yazisi">{this.props.cihazBilgileri[4]}</p>
                                                </div>
                                                <Link to="/cihazlar" onClick={() => { this.props.temelFiltreDegistir(temelArkaPlanRenkleri[4]); this.props.konumGuncelle("cihazlar") }}>
                                                    <div className="ayrintilar-git-peneresi">
                                                        <p className="ayrintilar-git-text">Ayrıntıları Gör</p>
                                                        <FontAwesomeIcon icon={faCircleArrowRight} className="ayrintilar-git-simge" />
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                        :
                                        <div style={{ backgroundColor: temelArkaPlanRenkleri[i] }} className="ayrinti-genel-pencere" key={i + Math.random()}>
                                            {this.simgeleriOlustur(i, true)}
                                            {
                                                i === 3 && this.simgeleriOlustur(i, false)
                                            }
                                            <div style={{ backgroundColor: ustPerdeArkaPlanRenkleri[i] }} className="ust-perde"></div>
                                            <div className="cihaz-bilgi-yazisi-divi">
                                                <p className="cihaz-bilgi-yazisi">{this.props.cihazBilgileri[i]}</p>
                                            </div>
                                            <p className="cihaz-durum-bilgisi-txt">{cihazDurumBilgisiTxt[i]}</p>
                                            <Link to="/cihazlar" onClick={() => { this.props.temelFiltreDegistir(temelArkaPlanRenkleri[i]); this.props.konumGuncelle("cihazlar") }}>
                                                <div className="ayrintilar-git-peneresi">
                                                    <p className="ayrintilar-git-text">Ayrıntıları Gör</p>
                                                    <FontAwesomeIcon icon={faCircleArrowRight} className="ayrintilar-git-simge" />
                                                </div>
                                            </Link>
                                        </div>
                                )
                            })
                        }
                    </div>
                    {this.props.cihazBilgileri2.length > 0 && this.ekrandaGosterilecekDatalariOlustur(CookieManager.getCookie("token"), this.props.cihazBilgileri2).length > 0 ? (
                        <div className="cihaz-bilgi-penceresi" style={(this.props.ekranGenisligi > 320 && this.props.ekranGenisligi < 460) ? { maxWidth: "300px" } : (this.props.ekranGenisligi > 460 && this.props.ekranGenisligi < 720) ? { maxWidth: "500px" } : { maxWidth: "90%" }}>
                            <div className="anasayfa-pinli">
                                <p className="pinli-text">Favori Cihazlar</p>
                                <div style={{ display: "flex", justifyContent: "space-evenly", flexWrap: "wrap" }}>
                                    {this.ekrandaGosterilecekDatalariOlustur(CookieManager.getCookie("token"), this.props.cihazBilgileri2).map((e) => (
                                        <div key={e[1]?.id} style={{ display: "flex", flexDirection: "column" }}>
                                            {e.map(e => e.component)}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    ) : null}
                    {/* <div className="cihaz-bilgi-penceresi" style={(this.props.ekranGenisligi > 320 && this.props.ekranGenisligi < 460) ? { maxWidth: "300px" } : (this.props.ekranGenisligi > 460 && this.props.ekranGenisligi < 720) ? { maxWidth: "500px" } : { maxWidth: "90%" }}>
                        <div className="anasayfa-pinli" style={{ marginTop: 10 }}>
                            <p className="pinli-text" style={{ marginBottom: 10 }}>Aktif Cihaz Konumları</p>
                            {
                                <>
 
                                </>
                            }
                        </div>
                    </div> */}
                </div> : <div></div>

        )
    }
}

export default Anasayfa



//<Map height={300} defaultCenter={[39.130, 33.475]} defaultZoom={5}>
//<Marker width={40} anchor={[41.02195, 28.62419]} /> {/**Tuyap */}
//<Marker width={40} anchor={[36.90213, 30.61379]} /> {/**Duraliler */}
//<Marker width={40} anchor={[38.64475, 35.45124]} /> {/**Hacılar */}
//<Marker width={40} anchor={[39.0929, 35.5684]} /> {/*Felahiye*/}
//<Marker width={40} anchor={[38.77786, 35.5799]} />  {/*Beyazşehir*/}
//<Marker width={40} anchor={[38.71416, 35.52229]} />  {/*Köşk*/}
//<Marker width={40} anchor={[38.8812, 35.628]} />  {/*Bahçelievler*/}
//<Marker width={40} anchor={[39.13802, 35.93936]} />  {/*Yahyalı*/}
//<Marker width={40} anchor={[38.62331, 35.18395]} />  {/*İncesu*/}
//<Marker width={40} anchor={[39.07678, 35.96671]} />  {/*Sarıoğlan*/}
//<Marker width={40} anchor={[39.026, 35.86631]} />  {/*Palas*/}
//<Marker width={40} anchor={[38.3976, 35.4996]} />  {/*Develi*/}
//<Marker width={40} anchor={[41.123158, 37.292005]} />  {/*Ordu*/}
//<Marker width={40} anchor={[37.084124, 30.607412]} />  {/*Enelsa*/}
//<ZoomControl />
//</Map>  */}