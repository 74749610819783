import React from "react"
import "./manuel-on-taraf.css"

const NovaYukleme = (props)=>{

    let flexDirection = props.ekranGenisligi < 520 ? "column" : "row" 

    return(
        <>
        <div className="cihaz-bilgi-kart-icerik-cerceve2">
            <div className="nova-bilgi-kart-ust">
                <div className="nova-isletim-modu" style={{display: "flex", alignItems: "center" }}>
                    İşletim Modu : <div style={{height: "20px", width: "70px", margin: "5px" }} className="tema-iki-yukleme-efekti"></div>
                </div>
            </div>
            <div className="nova-bilgi-kart-orta">
                <div className="vurus-sayisi">
                    <div className="vurus-ust">
                        Vuruş Oranı
                    </div>
                    <hr className="vurus-cizgi"></hr>
                    <div className="vurus-alt">
                        <div style={{height: "100px", width: "100px"}} className="tema-iki-yukleme-efekti"></div>
                    </div>
                </div>
            </div>
            <div>
                <hr className=""></hr>
                <div className="icos">
                    <div className="calisma-modux">
                        <div>Çalışma Modu : <div style={{height: "20px", width: "70px", margin: "5px" }} className="tema-iki-yukleme-efekti"></div></div>
                    </div>
                    <div className="calisma-modux">
                        <div>Durum : <div style={{height: "20px", width: "70px", margin: "5px" }} className="tema-iki-yukleme-efekti"></div></div>
                    </div>
                </div> 
            </div>
            <div className="nova-bilgi-kart-alt">
                <div className="kapasite-limit" style={{flexDirection: flexDirection, columnGap: "50px"}}>
                    <div>Kapasite Alt Limit :<div style={{height: "20px", width: "70px", margin: "5px" }} className="tema-iki-yukleme-efekti"></div></div>
                    <div>Kapasite Üst Limit :<div style={{height: "20px", width: "70px", margin: "5px" }} className="tema-iki-yukleme-efekti"></div></div>
                </div>
            </div>
        </div>
        </>
    )
}

export default NovaYukleme