import { React, useState } from "react"
import "./cihaz-arka-taraf-birimler.css"
import axios from "axios"
import { alarms, alarms2 } from "./alarms"
import Swal from "sweetalert2"

const roleFonskiyonlari = {
    "Yükseltme Kontrol": 1, "Düşürme Kontrol": 2, "PID Yükseltme Kontrol": 3, "PID Düşürme Kontrol": 4,
    "Periyodik Yükseltme Kontrol": 5, "Periyodik Düşürme Kontrol": 6, "Ön Yükseltme Kontrol": 7, "Ön Düşürme Kontrol": 8,
    "Oransal": 9, "Sayıcı": 10, "Periyodik Kontrol": 11, "Alt Role": 12, 'Boost Feeder': 13, 'Slave Shaker': 14, 'Alarm': 15
}

const analogCikisFonskiyonlari = {
    "Doğrusal": 1, "Yükseltme Kontrol": 2, "Düşürme Kontrol": 3, "PID Yükseltme Kontrol": 4, "PID Düşürme Kontrol": 5,
    "Limit Contact": 6, "Pulse Ratio": 7, "Alarm": 8
}

const degerler = [1, 2, 9]
const analogDegerler = [1, 2, 3]

const cihazlar = { "-": 0, "Asit Pompası": 1, "Baz Pompası": 2, "Klor Pompası": 3, "Amine Pump": 4, "Peroksit Pompası": 5, "Bioksid Pompası": 6, "Selonoid V.": 7, "Electro V.": 8, "Rezerve": 9 }

const roleCalismaTipi = { "Kapalı": 0, "Otomatik": 1, "Açık": 2 }


function selectMenusuOlustur(baslik, valueDegeri, valueDegeriFonksiyonu, secimleri, renk, varsayilanDegeri) {
    return (
        <div className="arka-taraf-birimler-input">
            <span>{baslik}</span>
            <select className="arka-taraf-birimler-input2" style={{ width: "175px", backgroundColor: renk }} value={valueDegeri} onChange={(e) => valueDegeriFonksiyonu(e.target.value)}>
                {
                    Object.keys(secimleri).map((e, i) => {
                        if (secimleri === roleFonskiyonlari) {
                            if (!degerler.includes(i + 1) && i + 1 === varsayilanDegeri) {
                                return (<option value={i + 1} disabled>{e}</option>)
                            } else if (degerler.includes(i + 1)) {
                                return (<option value={i + 1}>{e}</option>)
                            }
                        } else if (secimleri === analogCikisFonskiyonlari) {
                            if (!analogDegerler.includes(i + 1) && i + 1 === varsayilanDegeri) {
                                return (<option value={i + 1} disabled>{e}</option>)
                            } else if (analogDegerler.includes(i + 1)) {
                                return (<option value={i + 1}>{e}</option>)
                            }
                        } else {
                            return (<option value={i}>{e}</option>)
                        }
                        // return (<option value={(secimleri == roleFonskiyonlari) || (secimleri == analogCikisFonskiyonlari) ? i + 1 : i} disabled={(secimleri == roleFonskiyonlari) && degerler.includes(valueDegeri) ? false :(secimleri == analogCikisFonskiyonlari) && analogDegerler.includes(valueDegeri) ? false : true } >{e}</option>)
                    })
                }
            </select>
        </div>
    )
}


function inputOlustur(baslik, valueDegeri, valueDegeriFonksiyonu, renk) {
    return (
        <div className="arka-taraf-birimler-input">
            <span>{baslik}</span>
            <input className="arka-taraf-birimler-input2" style={{ width: "169px", outline: "none", paddingLeft: "4px", backgroundColor: renk }} value={valueDegeri} onChange={(e) => valueDegeriFonksiyonu(e.target.value)} />
        </div>
    )
}

const inputOlusturFunc = inputOlustur

function varsayilanDegerleriOlustur(veriler) {
    let varsayilanDegerler = { fonksiyon: null, offset: null, set: null, calismaTipi: null, cihaz: null, acikKenUzunlugu: null, tipi: null }
    Object.keys(varsayilanDegerler).forEach((e, i) => varsayilanDegerler[e] = veriler[i])
    return varsayilanDegerler
}

function varsayilanDegerleriOlusturAlarmDuy(veriler) {
    let varsayilanDegerler = { acikKenUzunlugu: null, tipi: null, alarmduyar: null }
    Object.keys(varsayilanDegerler).forEach((e, i) => varsayilanDegerler[e] = veriler[i])
    return varsayilanDegerler
}

async function ayarlariKaydet(datalar, bildirimleriYonet, token) {
    // // console.log(zamanAsimix)
    let gonderilecekData = {
        function: datalar.fonksiyon,
        set: (datalar.set).replace(',', '.'),
        offset: (datalar.offset).replace(',', '.'),
        dev: datalar.cihaz,
        calismaTipi: datalar.calismaTipi,
        tip: datalar.tip,
    }

    // console.log(gonderilecekData)
    // return

    gonderilecekData["number"] = datalar.index
    gonderilecekData["channel"] = datalar.kanal
    gonderilecekData["seriNo"] = datalar.seriNo

    const res = await axios.post('https://api.enelsa.com:5015/api/cihazAyar', JSON.stringify(gonderilecekData), {
        headers: {
            'Content-Type': 'application/json',
            'authorization': 'Bearer ' + token
        }
    })

    if (res.data === "Degisen Data Yok") {
        Swal.fire({
            icon: 'error',
            title: 'Değişen Data Yok',
            confirmButtonText: 'Tamam',
            confirmButtonColor: 'rgb(70, 85, 105)',
            width: '25em',
        })
    }

    if (res.status == 500) {
        // console.log("500")
    }

    if (res.data === "ok") {
        let gonderilecekData = {
            seriNo: datalar.seriNo
        }
        try {
            const res = await axios.post('https://api.enelsa.com:5015/api/cihazAyarKontrol', JSON.stringify(gonderilecekData), {
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': 'Bearer ' + token
                }
            })
            if (res.status == 200) {
                Swal.fire({
					icon: 'success',
					title: 'Ayar Başarılı',
					text: 'Güncelleme işlemi 1-5 saniye içinde gerçekleşecektir.',
					confirmButtonText: 'Tamam',
					confirmButtonColor: 'rgb(70, 85, 105)',
					width: '25em',
				})
            }
            
        } catch (e) {
            // console.log(e)
        }

    }
}

export const CihazArkaTarafBrimler = (props) => {
    let keyler = Object.keys(props.icerikVerileri)

    let yuzdeIndeksi = keyler.indexOf("yuzde")
    if (yuzdeIndeksi !== -1) {
        keyler.splice(yuzdeIndeksi, 1)
    }

    let [pencereAcik, setPencereAcik] = useState(false)

    let varsayilanDegerler = {}

    if (keyler.includes("Set2")) {
        varsayilanDegerler = varsayilanDegerleriOlustur([analogCikisFonskiyonlari[props.icerikVerileri[keyler[0]]], props.icerikVerileri[keyler[1]], props.icerikVerileri[keyler[2]], -1,
        cihazlar[props.icerikVerileri[keyler[3]]], 136.5, "Analog Çıkış"])
    } else {
        varsayilanDegerler = varsayilanDegerleriOlustur([roleFonskiyonlari[props.icerikVerileri[keyler[1]]], props.icerikVerileri[keyler[3]], props.icerikVerileri[keyler[2]],
        roleCalismaTipi[props.icerikVerileri[keyler[0]]], cihazlar[props.icerikVerileri[keyler[4]]], 177, "Röle"])
    }

    const [fonksiyon, setFonksiyon] = useState(varsayilanDegerler.fonksiyon)
    const [offset, setOffset] = useState(varsayilanDegerler.offset)
    const [set, setSet] = useState(varsayilanDegerler.set)
    const [calismaTipi, setCalismaTipi] = useState(varsayilanDegerler.calismaTipi)
    const [cihaz, setCihaz] = useState(varsayilanDegerler.cihaz)

    let parametreSifirlamaFonksiyonlari = {
        fonksiyon: () => { setFonksiyon(varsayilanDegerler.fonksiyon) },
        offset: () => { setOffset(varsayilanDegerler.offset) },
        set: () => { setSet(varsayilanDegerler.set) },
        calismaTipi: () => { setCalismaTipi(varsayilanDegerler.calismaTipi) },
        cihaz: () => { setCihaz(varsayilanDegerler.cihaz) }
    }

    return (
        <div className="arka-taraf-birimler-cerceve">
            <div style={{ height: pencereAcik ? varsayilanDegerler.acikKenUzunlugu : 30 + "px" }} className="arka-taraf-birimler-alt-cerceve">
                <div className="arka-taraf-birimler-ust-cerceve" onClick={() => setPencereAcik(!pencereAcik)} style={{ backgroundColor: props.renk2, transition: "all 0.5s" }}>
                    <span style={{ marginLeft: "10px" }}></span>
                    <span style={{ fontSize: "13px" }}>{varsayilanDegerler.tipi} {props.index}</span>
                    <span style={{ rotate: (pencereAcik ? -1 : 1) * 90 + "deg", marginRight: "10px" }}>{">"}</span>
                </div>
                <div className="arka-taraf-birimler-icerik">
                    {
                        <>
                            {keyler.includes("Offset") && selectMenusuOlustur("Çalışma Tipi", calismaTipi, setCalismaTipi, roleCalismaTipi, props.renk2)}
                            {selectMenusuOlustur("Fonksiyon", fonksiyon, setFonksiyon, keyler.includes("Offset") ? roleFonskiyonlari : analogCikisFonskiyonlari, props.renk2, varsayilanDegerler.fonksiyon)}
                            {keyler.includes("Offset") ? inputOlustur(keyler[2], set, setSet, props.renk2) : inputOlustur(keyler[2], set, setSet, props.renk2)}
                            {keyler.includes("Offset") ? inputOlustur(keyler[3], offset, setOffset, props.renk2) : inputOlustur(keyler[1], offset, setOffset, props.renk2)}
                            {selectMenusuOlustur("Cihaz", cihaz, setCihaz, cihazlar, props.renk2)}
                        </>
                    }

                    <div className="arka-taraf-birimler-buton-cerceve">

                        <div onClick={() => Object.keys(parametreSifirlamaFonksiyonlari).forEach(e => parametreSifirlamaFonksiyonlari[e]())} className="arka-taraf-birimler-buton" style={{ marginRight: "5px" }}>
                            <span>Vazgeç</span>
                        </div>
                        <div onClick={() => {
                            ayarlariKaydet({
                                fonksiyon: fonksiyon,
                                offset: offset,
                                set: set,
                                calismaTipi: calismaTipi,
                                cihaz: cihaz,
                                index: props.index,
                                kanal: props.kanal,
                                tip: keyler.includes("Set2") ? "1" : "2",
                                seriNo: window.location.pathname.split("r/")[1]
                            }, props.bildirimleriYonet, props.token)
                        }} className="arka-taraf-birimler-buton" >
                            <span>Kaydet</span>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export const CihazArkaTarafBrimlerAlarmDuy = (props) => {
    let keyler1 = Object.keys(props.icerikVerileri)
    let alarmlist = []
    let alarmlist2 = []
    let varsayilanDegerler = {}
    let roleOrAnalog = false

    const [alarmText, setAlarmText] = useState("");

    function alarmDuyarTextGoster(text) {
        setIsShown(true)
        setAlarmText(text)
    }

    if (keyler1.includes("Offset") || keyler1.includes("Set2")) {
        let keyler = Object.keys(props.icerikVerileri)
        roleOrAnalog = true
        alarmlist = props.icerikVerileri[keyler[5]]
        alarmlist2 = props.icerikVerileri[keyler[6]]
        varsayilanDegerler = varsayilanDegerleriOlusturAlarmDuy(["auto", keyler1.includes("Offset") ? "Röle" : "Analog", props.icerikVerileri[keyler[5]]])
    }

    const [isShown, setIsShown] = useState(false);

    let listItems = null
    let listItems2 = null
    if (alarmlist !== undefined) {
        if (alarmlist.length > 0) {
            listItems = alarmlist.map((e, i) => {
                return (
                    <>
                        <div className="out-circle" onMouseEnter={() => alarmDuyarTextGoster(alarms[i])}
                            onMouseLeave={() => setIsShown(false)}>
                            <div className={e === 1 ? "inner-circle" : undefined}>
                            </div>
                        </div>
                    </>
                )
            });
            listItems2 = alarmlist2.map((e, i) => {
                return (
                    <>
                        <div className="out-circle" onMouseEnter={() => alarmDuyarTextGoster(alarms2[i])}
                            onMouseLeave={() => setIsShown(false)}>
                            <div className={e === 1 ? "inner-circle" : undefined}>
                            </div>
                        </div>
                    </>
                )
            });
        }
    }

    let [pencereAcik, setPencereAcik] = useState(false)

    return (
        <> {
            roleOrAnalog &&
            <div className="arka-taraf-birimler-cerceve">
                <div style={{ height: pencereAcik ? varsayilanDegerler.acikKenUzunlugu : 30 + "px" }} className="arka-taraf-birimler-alt-cerceve">
                    <div className="arka-taraf-birimler-ust-cerceve" onClick={() => setPencereAcik(!pencereAcik)} style={{ backgroundColor: props.renk2, transition: "all 0.5s" }}>
                        <span style={{ marginLeft: "10px" }}></span>
                        <span style={{ fontSize: "13px" }}>{varsayilanDegerler.tipi} {props.index}</span>
                        <span style={{ rotate: (pencereAcik ? -1 : 1) * 90 + "deg", marginRight: "10px" }}>{">"}</span>
                    </div>
                    <div className="arka-taraf-birimler-icerik2">
                        <div className="circles-div">{listItems}</div>
                        <div className="circles-div">{listItems2}</div>
                        <span style={{ height: "15px", display: "block" }}>
                            {isShown && alarmText}
                        </span>
                    </div>
                </div>
            </div>
        }
        </>
    )
}

export { inputOlusturFunc }
