import React from "react"
import "./tema-iki-cihaz-yukleme.css"
import "./tema-iki-cihaz.css"


const TemaIkiCihazYukleme = ()=>{

    return(
        <div className="tema-iki-cihaz-cerceve" style={{border: "#02bb4a solid 2px"}}>
            <div className="tema-iki-cihaz-bilgisi">
                <div id="tema-iki-cihaz-bilgi-yukleme" className="tema-iki-yukleme-efekti"></div>
            </div>
            
            <div className="tema-iki-cihaz-bilgi-durum">
                    {Array.from(Array(6).keys()).map(e=>{
                        return(
                            <div className="tema-iki-yukleme-efekti tema-iki-cihaz-bilgi-durum-yukleme" key={e}></div>
                        )
                    })}
            </div>
        </div>
    )
}

export default TemaIkiCihazYukleme