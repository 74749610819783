import { useEffect, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLock, faX } from "@fortawesome/free-solid-svg-icons"
import { ConfigClient } from "../../common-requests"
import { AutoFocusInput } from "../input"
import axios from "axios"
import CookieManager from "../../../../../../../cookieManager"


function MailPopup(props) {
    const [show, setShow] = useState(false)
    const [mailVerificationCode, setMailVerificationCode] = useState("")
    const [error, setError] = useState(null)
    const [referansCode, setReferansCode] = useState("")
    const {eventBus} = props

    let referansKoduGuncelle = async () => {
        let gonderilecekData = {
            kullaniciAdi: CookieManager.getCookie("kullaniciAdi")
        }
        try{
            let referansKodu = await axios.post("https://api.enelsa.com:5015/api/referansKodu", gonderilecekData, {
            headers: {
                'authorization': 'Bearer ' + CookieManager.getCookie("token")
            }
            })
            setReferansCode(referansKodu.data)
        } catch (e) {
            // console.log(e)
            //window.location.replace('/anasayfa')
        }
    }

    useEffect(()=>{
        //setup event listeners on mount
        eventBus.subscribe('ask mail verification', async ({success, reject, token})=>{
            //there is no error handling because
            //async function returns a promise and when error occurs it will
            //be returned as rejected in promise but
            //it will not be handled by the EventBus
            setShow(true)
            setError(null)
            setMailVerificationCode("")
            await ConfigClient.generateMail(token)

            eventBus.subscribe('send mail verification', async (mailVerificationCode)=>{
                // console.log(mailVerificationCode, "----")
                // // console.log(canUpdate)
                setShow(false)
                const res = await ConfigClient.approveMail(mailVerificationCode, token)
                const {canUpdate, exp} = res.data
                if (canUpdate === false) {
                    setError("Hatalı doğrulama kodu")
                    reject({canUpdate, exp})
                }
                else if (canUpdate === true) {
                    success({canUpdate, exp})
                }
            })
            referansKoduGuncelle()

        })

        //teardown event listeners on dismount
        return () =>{
            eventBus.clear('ask mail verification')
            eventBus.clear('send mail verification')
        }

    }, [])


    return (
        <>
            {
            show &&
            <div className="ayar-popup-arkaplan">
                {
                <div className='ayar-popup'>
                    <span>Referans Kodu : {referansCode}</span>
                    <span className="ayar-popup-title">Ayar Yapma Doğrulama Kodu</span>
                    {AutoFocusInput(
                        mailVerificationCode,
                        (targetValue)=>{
                            const lastChar = targetValue[targetValue.length - 1]
                            if (targetValue.length > 6) return
                            setMailVerificationCode(targetValue)
                        },
                        "nova-manuel-input nova-mail-verification-input"
                        )}
                    <span className="ayar-popup-not">*Doğrulama kodunu email ve/veya SMS ile alacaksınız. <br></br>
                    *Doğrulama kodunu alabilmek için mail adresinizin/telefon numaranızın doğruluğundan emin olun.</span>
                    {error && <span className="ayar-popup-error">{error}</span>}
                    <button className="ayar-popup-kaydet" onClick={()=>{eventBus.publish('send mail verification',mailVerificationCode)}}>Gönder</button>
                    <a href="#modal-closed" className="link-2" onClick={()=>{setShow(false)}} ></a>
                    <FontAwesomeIcon className="modal-alt-icon" icon={faLock}/>
                </div>
                }
            </div>
            }
        </>
    )
}

export default MailPopup