import startIcon from '../../../img/1-1-03.png'
import stopIcon from "../../../img/1-1-02.png"
import startIconBlack from '../../../img/1-1-03-black.png'
import stopIconBlack from "../../../img/1-1-02-black.png"

let icolarMod = {
    "1": <img src={startIcon} style={{marginRight: "0px"}} className="on-taraf-role-ico" alt={"Açık"}/>,
    "0": <img src={stopIcon} style={{marginRight: "0px"}} className="on-taraf-role-ico" alt={"Kapalı"}/>
}

let icolarModBlack = {
    "1": <img src={startIconBlack} style={{marginRight: "0px"}} className="on-taraf-role-ico" alt={"Açık"}/>,
    "0": <img src={stopIconBlack} style={{marginRight: "0px"}} className="on-taraf-role-ico" alt={"Kapalı"}/>
}

export { icolarMod, icolarModBlack }