import React from "react"
import "./gruplar-on-taraf.css"
import { yetkiler } from "../../kullanici-islemleri/utils/kullanici-yetkileri"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck, faXmark } from "@fortawesome/free-solid-svg-icons"

const GruplarOnTarafi = (props)=>{

    function listOlustur(datalar,yetkiler) {
		let ekrandaGosterilecekler = []
		let keys = Object.keys(datalar)
		let values = Object.values(datalar)
		let yetkilerValues = Object.values(yetkiler)
		// console.log(yetkilerValues[0], "yetkilervalues")
		keys.forEach((e) => {
			ekrandaGosterilecekler.push(
			<div style={{margin:"3px 5px 0 5px", display: "flex", alignItems: "center"}}>
				{yetkilerValues[e] === true ? <FontAwesomeIcon icon={faCheck} style={{color:"green", width:"17px", marginRight: "5px"}}></FontAwesomeIcon> : <FontAwesomeIcon icon={faXmark} style={{color:"red", width:"17px", marginRight: "5px"}}></FontAwesomeIcon>}
				{values[e]}
			</div>
			)
		})
		return ekrandaGosterilecekler
	} 
    
    const ekrandaGosterilecekleriOlustur = (datalar) => {
		if(datalar === undefined || datalar === []) {
			return
		} else {
			let ekrandaGosterilecekler = []
            let yetkililer = (JSON.parse(datalar["grup_yetkilileri"])).yetkililer
            let yetkilerx = (JSON.parse(datalar["grup_yetkileri"])).yetkiler
            let kullanicilar= (JSON.parse(datalar["gruptaki_kullanicilar"])).grupKullanicilari
            let grupAdi = datalar["grup_adi"]
            let keys = Object.keys(yetkiler)
            let yetkiList = []
            keys.forEach((i) => {
                yetkiList.push(yetkiler[keys[i]]["name"])
            })
            ekrandaGosterilecekler.push(
                <>
                <div className={"grup"}>
                    <span><div> Grup Adı</div><em> {": " +grupAdi}</em></span>
                    <span><div> Grup Yetkilileri</div><em> {": " +yetkililer}</em> </span>
                    <span><div> Grup Kullanıcıları </div><em> {": " +kullanicilar}</em> </span>
                    <span><div style={{marginBottom: "5px"}}> Grup Yetkileri </div><em> {": "} {listOlustur(yetkiList,yetkilerx)}</em> </span>
                </div>
                </>
            )
			return ekrandaGosterilecekler
		}
	}
    return(
        <>
            <div id="Gruplar-on-taraf">
                {ekrandaGosterilecekleriOlustur(props.gruplar).map((e) => { return e })}
            </div>
        </>
    )
}

export default GruplarOnTarafi