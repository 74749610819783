import "./gruplar-kart.css"
import React,{ useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPen, faRotateLeft } from "@fortawesome/free-solid-svg-icons"
import GruplarOnTarafi from "./gruplar-on-taraf"
import GruplarArkaTaraf from "./gruplar-arka-taraf"

const GruplarKart = (props) => {
	let [gruplarTarafBilgisi, setGruplarTarafBilgisi] = useState(true)
    let [gruplarDondurme, setGruplarDondurme] = useState(true)
    const [parentDondur, setParentDondur] = useState(false);
    const handleParentDondur = (updatedValues) => {
        setParentDondur(updatedValues);
        pencereyiCevir()
      };

	const pencereyiCevir = () => {
        setGruplarDondurme(false)
        setTimeout(() => { setGruplarTarafBilgisi(!gruplarTarafBilgisi) }, 450)
        setTimeout(() => { setGruplarDondurme(true) }, 700)
    }
	
    return (
        <>
            <div style={{ transform: gruplarDondurme ? "rotateY(0deg)" : "rotateY(90deg)", backgroundColor: props.renk }} className="gruplar-cerceve">
				<FontAwesomeIcon icon={gruplarTarafBilgisi ? faPen : faRotateLeft} className="cihaz-bilgi-kart-ust-taraf-ico" onClick={pencereyiCevir} style={{position: "absolute", margin: gruplarTarafBilgisi ? "18px 400px" : "6px 401px" }}/>
				{gruplarTarafBilgisi ? <GruplarOnTarafi gruplar={props.gruplar} /> : <GruplarArkaTaraf gruplar={props.gruplar} tokenBilgileri={props.tokenBilgileri} bildirimleriYonet={props.bildirimleriYonet} parentDondur={parentDondur} onParentDondurChange={handleParentDondur}/> }
        	</div>
        </>
    )
}
export default GruplarKart