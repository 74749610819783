import { React, useState } from "react"
import "./alt-kullanici-lisans.css"
import axios from "axios"

const AltKullaniciLisans = (props) => {
	// // console.log(props)

    let [kullaniciId, setKullaniciId ] = useState("")
	let [adminS, setAdmin] = useState()
	let [cihazS, setCihaz] = useState()
	let [abonelikS, setAbonelik] = useState()

	let kullaniciIdBul = async () => {
		try {
			let a = await axios.get("http://localhost:5001/api/altKullanicilar/" + props.veriler,
				{
					headers: {
						authorization: "Bearer " + props.tokenBilgileri.token
					}
				}
			)
			setKullaniciId(a.data.id)
		} catch {
			// // console.log(props.veriler, "22")
		}
	}

    let lisansBilgileriniGuncelle = async () => {
		let kullaniciKredileri = await axios.get(
			"http://localhost:5001/api/lisansBilgileri/" + kullaniciId,
			{
				headers: {
					authorization: "Bearer " + props.tokenBilgileri.token,
				},
			}
		)
		// // console.log(kullaniciKredileri.data)
		setAdmin(kullaniciKredileri.data.admin)
		setCihaz(kullaniciKredileri.data.cihaz)
		setAbonelik(kullaniciKredileri.data.abonelik)
	}
    
	kullaniciIdBul()
    lisansBilgileriniGuncelle()

	return (
		<div
			className="lisans"
			style={{
				alignItems: "stretch",
				flexDirection: "column",
				padding: "5px",
			}}>
			Kullanıcı Adı : {props.veriler}
			<span>Admin : {adminS}</span>
			<span>Cihaz : {cihazS}</span>
			<span>Abonelik : {abonelikS}</span>
		</div>
	)
}

export default AltKullaniciLisans
