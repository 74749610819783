import { faLockOpen, faTrashCan } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

function ApprovalPopup(props) {
    const {text, onApprove, onReject, state} = props
    const [showSelf, setShowSelf] = state
    function approve() {
        setShowSelf(false)
        onApprove()
    }
    function reject() {
        setShowSelf(false)
        onReject()
    }
    function close() {
        setShowSelf(false)
    }
    //setShowSelf is a state setter for determining components render
    return (
        <>
            {showSelf &&
            <div className="ayar-popup-arkaplan">
                <div className="approval-popup">
                    <span className="approval-popup-text">{text}</span>
                    <div className="approval-popup-button-container">
                        <button className="approval-popup-button-approve" onClick={approve}>Evet</button>
                        <button className="approval-popup-button-reject" onClick={reject}>Hayır</button>
                    </div>
                    <a href="#modal-closed" className="link-2"  onClick={close} ></a>
                    {
                        text.includes("silmek") ? <FontAwesomeIcon className="modal-alt-icon" icon={faTrashCan}/> : <FontAwesomeIcon className="modal-alt-icon" icon={faLockOpen}/>
                    }
                </div>
            </div>
            }
        </>
    )
}

export default ApprovalPopup