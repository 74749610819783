import React from "react"
import "./tema-bir-cihaz.css"
import "./tema-bir-cihaz-yukleme.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCircleMinus, faCirclePlus } from "@fortawesome/free-solid-svg-icons"

class TemaBirCihazYuklemeAnimasyonu extends React.Component{

    state={
        icerikEkraniBoyutu: 370,
        icerikEkraniBoyutuButonuBasildi: false
    }

    icerikEkraniBoyutuAyarla(){
        this.setState(
            {
                icerikEkraniBoyutu: this.state.icerikEkraniBoyutuButonuBasildi ? 370: 0,
                icerikEkraniBoyutuButonuBasildi: !this.state.icerikEkraniBoyutuButonuBasildi 
            }
        )
    }

    render(){
        return(
            <div className="tema-bir-cihaz-kart">
                <div className="tema-bir-cihaz-kart-baslik">
                    <div id="tema-bir-cihaz-yukleme-baslik-efekt" className="tema-bir-yukleme-efekti"></div>
                    <FontAwesomeIcon icon={ this.state.icerikEkraniBoyutu === 370 ? faCircleMinus : faCirclePlus} className="tema-bir-cihaz-kucultme-ico" onClick={()=>this.icerikEkraniBoyutuAyarla()}/>
                </div>
                <div className="tema-bir-cihaz-icerik-penceresi" style={{height: this.state.icerikEkraniBoyutu+"px"}}>
                    <div className="tema-bir-cihaz-gorseli">
                        <div id="tema-bir-cihaz-yukleme-gorsel-efekt" className="tema-bir-yukleme-efekti"></div>
                    </div>
                    <div className="tema-bir-cihaz-cihaz-bilgileri-penceresi">
                        <div className="tema-bir-cihaz-bilgileri-cihaz">
                            <div id="tema-bir-cihaz-yukleme-cihaz-bilgi-cihaz" className="tema-bir-yukleme-efekti"></div>
                        </div>
                        {
                            Array(6).fill(1).map((e,i)=>{
                                return(
                                    <div key={"c"+i} className="tema-bir-cihaz-bilgileri-txt">
                                        <p style={{margin: "0px 5px 0px 0px"}}>Kanal {i+1}:</p>
                                        <div className="tema-bir-yukleme-efekti tema-bir-cihaz-yukleme-cihaz-bilgi-txt"></div>
                                    </div>
                                )
                            })
                        }
                        <div className="tema-bir-cihaz-bilgileri-txt">
                            <p style={{margin: "0px auto"}}>Son Güncelleme</p> 
                        </div>
                        {
                            Array(2).fill(1).map((e,i)=>{
                                return(
                                    <div key={"d"+i} className="tema-bir-cihaz-bilgileri-txt">
                                        <p style={{margin: "0px 5px 0px 0px"}}>{ i % 2 === 0 ? "Tarih" : "Saat"}: </p>
                                        <div className="tema-bir-yukleme-efekti tema-bir-cihaz-yukleme-cihaz-bilgi-txt"></div> 
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default TemaBirCihazYuklemeAnimasyonu