import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLockOpen } from "@fortawesome/free-solid-svg-icons"
import { useEffect , useState} from "react"

function Countdown(props) {
    const [show, setShow] = useState(false)
    const {onClick, eventBus, onComplete} = props
    const [timeLeft, setTimeLeft] = useState(0)
    const [done, setDone] = useState(false)
    useEffect(()=>{
        eventBus.subscribe('set countdown', (expiration)=>{
            setShow(true)
            setTimeLeft(expiration - Date.now())
            const intervalId = setInterval(() => {
                setTimeLeft((timeLeft) => {
                    timeLeft = expiration - Date.now() 
                    if (timeLeft < 0 || Number.isNaN(timeLeft)) {
                        setShow(false)
                        if (onComplete) onComplete()
                        clearInterval(intervalId)
                        window.location.reload()
                    }
                    return timeLeft
                });
            }, 1000);
            eventBus.subscribe('clear countdown', ()=>{
                setShow(false)
                clearInterval(intervalId)
                setDone(true)
            })
        })
        return () =>{
            eventBus.publish('clear countdown')
            eventBus.clear('clear countdown')
            eventBus.clear('set countdown')
        }
    }, [])

    const totalSeconds = timeLeft/1000
    let showMinutes = Math.floor(totalSeconds/ 60).toString();
    showMinutes = showMinutes.length === 1 ?  "0" + showMinutes : showMinutes

    let showSeconds = Math.floor(totalSeconds % 60).toString();
    showSeconds = showSeconds.length === 1 ?  "0" + showSeconds : showSeconds
    return(
        <>
            {show &&
                <span className="ayar-nova-countdown"> <FontAwesomeIcon className="ayar-nova-countdown-lock-icon" icon={faLockOpen} onClick={()=>{onClick()}}></FontAwesomeIcon> {showMinutes} : {showSeconds}  </span>
            }
        </>
    )
}


export default Countdown