export const yetkiler = [
    {
        name: "Yetki Şablonu Ekleme/Düzenleme/Silme",
        value: "Template_Auth"
    },
    {
        name: "Kullanıcı Ekleme/Düzenleme/Silme",
        value: "User_Edit"
    },
    {
        name: "Kullanıcı Yetkilerini Ekleme/Düzenleme/Silme",
        value: "User_Auth"
    },
    {
        name: "Alarm Durumlarında Mail Alabilme",
        value: "Mail"
    },
    {
        name: "Alarm Durumlarında SMS Alabilme",
        value: "SMS"
    },
    {
        name: "Cihaz Ayarlarını Değiştirebilme",
        value: "Settings"
    },
    {
        name: "Cihazlara Ait Grafikleri Görüntüleyebilme",
        value: "Graphs"
    },
    {
        name: "Cihazlara Ait Alarmları Görüntüleyebilme",
        value: "Alarms"
    },
    {
        name: "Cihazlara Ait Log Kayıtlarını Görüntüleyebilme",
        value: "View_Logs"
    },
    {
        name: "Cihaz Ekleme/Düzenleme/Silme",
        value: "Device"
    },
    {
        name: "Cihaz Yetkililerini Düzenleme",
        value: "DeviceAuth_Edit"
    },
    {
        name: "Alt Kullanıcı Log Kayıtlarını Görüntüleme",
        value: "View_SubUserLog"
    },
    {
        name: "Üst Kullanıcıya İzleme Yetkisi Verme",
        value: "Auth_Access"
    }
];