import React,{ useState, useEffect } from "react"
import { yetkiler } from "../../kullanici-islemleri/utils/kullanici-yetkileri"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck, faXmark, faPen } from "@fortawesome/free-solid-svg-icons"

const ListOlustur = (props)=>{
    const [parentYetkilerValues, setParentYetkilerValues] = useState([]);
    let ekrandaGosterilecekler = []
    let yetkiList = Object.values(props.yetkiler)
    let keys = Object.keys(yetkiList)
    const [yetkilerValues,setYetkilerValues] = useState(yetkiList)
    // console.log(props.yetkiler, "list")
    const yetkiEdit = (e) => {
        const updatedYetkilerValues = [...yetkilerValues];
        updatedYetkilerValues[e] = !updatedYetkilerValues[e];
        setYetkilerValues(updatedYetkilerValues)
        setParentYetkilerValues(updatedYetkilerValues); 
        props.onYetkilerValuesChange(updatedYetkilerValues)
    }

    keys.forEach((e, i) => {
        ekrandaGosterilecekler.push(
        <div style={{margin:"3px 5px 0 5px", display: "flex", alignItems: "center"}}>
            {yetkilerValues[e] == true ? 
                <FontAwesomeIcon icon={faCheck} style={{color:"green", width:"17px", marginRight: "5px", cursor: "pointer"}} onClick={() => yetkiEdit(i)}></FontAwesomeIcon> 
                : <FontAwesomeIcon icon={faXmark} style={{color:"red", width:"17px", marginRight: "5px", cursor: "pointer" }} onClick={() => yetkiEdit(i)} ></FontAwesomeIcon>
            }
            {yetkiler[e].name}
        </div>
        )
    })
    return ekrandaGosterilecekler
}

export default ListOlustur