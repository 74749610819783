import React from "react"
import "./tema-bir-cihaz.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCircleMinus, faCirclePlus, faMicrochip, faCirclePlay, faTriangleExclamation, faWifi, faSlash, faStar as solidStar } from "@fortawesome/free-solid-svg-icons"
import { faStar } from "@fortawesome/free-regular-svg-icons"
import omniconLogo from "../../../../assests/Varlık 2.png"
import novaLogo from "../../../../assests/nova.png"
import saltLogo from "../../../../assests/SALT IONIC A.png"
import { Link } from "react-router-dom"
import axios from "axios"
import { icolarModBlack, icolarStateBlack } from "../../../cihaz-sayfasi/components/nova/dictionaries"
import { isletimModu } from "../../../cihaz-sayfasi/components/nova/dictionaries"
import CookieManager from "../../../../../cookieManager"

class TemaBirCihaz extends React.Component {
    constructor(props) {
        super(props);
        // State'i başlatıyoruz
        this.state = {
            pin: props.cihazVerileri?.pin,
        };
    }

    render() {
        //console.log(this.props, "*/*/")
        // let tarih = "-"

        const butonFiltreRenkler = ["#005fab", "#02bb4a", "#009ceb", "#f59c1a", "#ff5b57"]
        let borderColor = butonFiltreRenkler[this.props.durum]

        let tarih = this.props.cihazDegerleri?.tarih ? (this.props.cihazDegerleri?.tarih).slice(0, -6) : "-"
        let icerikEkraniAcikKapali = this.props.cihazVerileri?.icerikEkraniAcikKapali
        // let pin = this.props.cihazVerileri?.pin
        let cihazVar = this.props.cihazVerileri ? true : false

        let cihazTuru = this.props.cihazData.data !== undefined && this.props.cihazData.data[this.props.cihazVerileri?.seri]?.cihazTuru

        let pinle = async () => {
            const { pin } = this.state;
            // console.log(this.props)
            let pinlenecek = {
                id: CookieManager.getCookie("id"),
                pin: !pin,
                seri: this.props.cihazVerileri?.seri
            }
            try {
                let res = await axios.put("https://api.enelsa.com:5015/api/pinle", JSON.stringify(pinlenecek), {
                    headers: {
                        'Content-Type': 'application/json',
                        'authorization': 'Bearer ' + CookieManager.getCookie("token")
                    }
                })
                this.setState({ pin: !pin });
            } catch {
                // console.log("pinleyemedi")
            }
        }

        let durum = this.props.durum
        let cihazIsim = ""
        if (this.props.cihazData.data !== undefined) {
            cihazIsim = ((Object.keys(this.props.cihazData).length !== 0 && this.props.cihazData.data[this.props.cihazVerileri?.seri]?.isim) && this.props.cihazData.data[this.props.cihazVerileri?.seri]?.isim != undefined) ?
                this.props.cihazData.data[this.props.cihazVerileri?.seri]?.isim
                :
                this.props.cihazVerileri?.cihazAdi != "undefined" ? this.props.cihazVerileri?.cihazAdi
                    : ""
        } else {
            cihazIsim = this.props.cihazVerileri?.cihazAdi != "undefined" ? this.props.cihazVerileri?.cihazAdi : ""
        }
        return (cihazVar &&
            <div className="tema-bir-cihaz-kart">
                <div className="tema-bir-cihaz-kart-baslik" style={{ backgroundColor: this.props.cihazData.data !== undefined && cihazTuru.toLowerCase() === "omnicon" ? "#005FAB" : "#8f8c00" }}>
                    <div>
                        <p id="tema-bir-baslik-txt">{cihazIsim} {cihazIsim && " / "} {this.props.cihazVerileri?.kullaniciAdi + " / " + this.props.cihazVerileri?.seri}</p>
                    </div>
                    <div className="tema-bir-baslik-icos">
                        <div id="tema-bir-baslik-ico">
                            <FontAwesomeIcon icon={this.state.pin ? solidStar : faStar} className="tema-bir-cihaz-kucultme-ico" onClick={() => { pinle() }} />
                        </div>
                        <div id="tema-bir-baslik-ico">
                            <FontAwesomeIcon icon={icerikEkraniAcikKapali ? faCircleMinus : faCirclePlus} className="tema-bir-cihaz-kucultme-ico" onClick={() => { this.props.icerikEkraniBoyutuAyarla(this.props.cihazVerileri?.seri) }} />
                        </div>
                    </div>
                </div>
                {
                    this.props.cihazData.data !== undefined && (cihazTuru).toLowerCase() === "omnicon"
                        ?
                        <Link to={"/cihazlar/" + this.props.cihazVerileri?.seri}>
                            <div className="tema-bir-cihaz-icerik-penceresi" style={{ height: icerikEkraniAcikKapali ? 370 : 0 + "px" }}>
                                <div className="tema-bir-cihaz-gorseli">
                                    <div className="tema-bir-cihaz-durum-ico-div">
                                        <FontAwesomeIcon
                                            icon={durum === 1 ? faCirclePlay : durum === 3 ? faTriangleExclamation : faWifi}
                                            style={{ position: "absolute", color: durum === 1 ? butonFiltreRenkler[1] : durum === 3 ? butonFiltreRenkler[3] : butonFiltreRenkler[4] }}
                                            className="tema-bir-cihaz-durum-ico"
                                        />
                                        {durum === 4 && <FontAwesomeIcon icon={faSlash} style={{ color: butonFiltreRenkler[4] }} className="tema-bir-cihaz-durum-ico-slash" />}
                                    </div>

                                    <img src={omniconLogo} alt="omnicon" style={{ height: "70px", position: 'relative' }} />

                                </div>
                                <div className="tema-bir-cihaz-cihaz-bilgileri-penceresi">
                                    <div className="tema-bir-cihaz-bilgileri-cihaz">
                                        <p style={{ margin: "auto", color: borderColor }}>OMNİCON</p>
                                    </div>
                                    {this.props.cihazDegerleri && this.props.cihazDegerleri.data && [...Array(6)].map((_, i) => {
                                        const kanalVerisi = this.props.cihazDegerleri.data[i + 1];

                                        if (kanalVerisi && kanalVerisi.value) {
                                            return (
                                                <div style={{ padding: "0px 5px 0px 0px", }} className="tema-bir-cihaz-bilgileri-cihaz" key={i}>
                                                    Kanal {i + 1} : &nbsp; <span>{kanalVerisi.value + " " + kanalVerisi.unit}</span>
                                                </div>
                                            );
                                        } else {
                                            return (
                                                <div className="tema-bir-cihaz-bilgileri-cihaz" key={i} style={{ margin: "0px 5px 0px 0px" }}>
                                                    Kanal {i + 1} : &nbsp;  <span>-</span>
                                                </div>
                                            );  // Verisi olmayan kanalları atlıyoruz
                                        }
                                    })}
                                    <div className="tema-bir-cihaz-bilgileri-txt">
                                        <p style={{ margin: "0px auto", color: borderColor }}>Son Güncelleme</p>
                                    </div>
                                    <div key={"b-"} className="tema-bir-cihaz-bilgileri-txt">
                                        <p style={{ margin: "auto" }}>{tarih}</p>
                                    </div>
                                </div>
                            </div>
                        </Link>
                        : this.props.cihazData.data !== undefined && (cihazTuru).toLowerCase() === "nova" ?
                            <Link to={"/cihazlar/" + this.props.cihazVerileri?.seri}>
                                <div className="tema-bir-cihaz-icerik-penceresi" style={{ height: icerikEkraniAcikKapali ? 370 : 0 + "px" }}>
                                    <div className="tema-bir-cihaz-gorseli">
                                        <div className="tema-bir-cihaz-durum-ico-div">
                                            <FontAwesomeIcon
                                                icon={durum === 1 ? faCirclePlay : durum === 3 ? faTriangleExclamation : faWifi}
                                                style={{ position: "absolute", color: durum === 1 ? butonFiltreRenkler[1] : durum === 3 ? butonFiltreRenkler[3] : butonFiltreRenkler[4] }}
                                                className="tema-bir-cihaz-durum-ico"
                                            />
                                            {durum === 4 && <FontAwesomeIcon icon={faSlash} style={{ color: butonFiltreRenkler[4] }} className="tema-bir-cihaz-durum-ico-slash" />}
                                        </div>

                                        <img src={novaLogo} alt="nova" style={{ height: "120px", position: 'relative' }} />

                                    </div>
                                    <div className="tema-bir-cihaz-cihaz-bilgileri-penceresi">
                                        <div className="tema-bir-cihaz-bilgileri-cihaz">
                                            <p style={{ margin: "auto", color: borderColor }}>NOVA</p>
                                        </div>
                                        <div className="tema-bir-cihaz-bilgileri-txt" style={{ color: "#2D353C" }}>
                                            <div style={{ width: "90px" }}><p>İşletim Modu: </p></div><span>{isletimModu[this.props.cihazDegerleri.data.operatingMode]}</span>
                                        </div>
                                        <div className="tema-bir-cihaz-bilgileri-txt" style={{ color: "#2D353C" }}>
                                            <div style={{ width: "90px" }}><p>Vuruş Oranı: </p></div><span>{this.props.cihazDegerleri.data.strokeRate}</span>
                                        </div>
                                        <div className="tema-bir-cihaz-bilgileri-txt" style={{ color: "#2D353C", display: "flex", flexDirection: "row" }}>
                                            <div style={{ width: "90px" }}><p>Çalışma Modu: </p></div><div>{icolarModBlack[this.props.cihazDegerleri.data.runMode]}</div>
                                        </div>
                                        <div className="tema-bir-cihaz-bilgileri-txt" style={{ color: "#2D353C", display: "flex", flexDirection: "row" }}>
                                            <div style={{ width: "90px" }}><p>Durum: </p></div><div>{icolarStateBlack[this.props.cihazDegerleri.data.runModeState]}</div>
                                        </div>
                                        <div className="tema-bir-cihaz-bilgileri-txt">
                                            <p style={{ margin: "0px auto", color: borderColor }}>Son Güncelleme</p>
                                        </div>
                                        <div key={"b-"} className="tema-bir-cihaz-bilgileri-txt">
                                            <p style={{ margin: "0px auto" }}>{tarih}</p>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                            : this.props.cihazData.data !== undefined && (cihazTuru).toLowerCase() === "salt" ?
                                <div className="tema-bir-cihaz-icerik-penceresi" style={{ height: icerikEkraniAcikKapali ? 370 : 0 + "px" }}>
                                    <div className="tema-bir-cihaz-gorseli">
                                        {/* <Link to={"/cihazlar/salt/" + this.props.cihazVerileri?.seri}> */}
                                        <Link to={{ pathname: "/cihazlar/salt/" + this.props.cihazVerileri?.seri, state: { cihazIsim: cihazIsim } }}>
                                            <img src={saltLogo} alt="saltionic" style={{ height: "100px" }} />
                                        </Link>
                                    </div>
                                    <div className="tema-bir-cihaz-cihaz-bilgileri-penceresi">
                                        <div className="tema-bir-cihaz-bilgileri-cihaz">
                                            <p style={{ margin: "auto", color: this.props.cihazVerileri?.renk }}>SALT IONIC</p>
                                        </div>
                                        <div className="tema-bir-cihaz-bilgileri-txt" style={{ color: "#2D353C" }}>
                                            <div style={{ width: "90px" }}><p>Cihaz Modu: </p></div><span>{this.props.cihazVerileri?.mode == "0" ? "Manuel" : "Sensör"}</span>
                                        </div>
                                        <div className="tema-bir-cihaz-bilgileri-txt" style={{ color: "#2D353C" }}>
                                            <div style={{ width: "90px" }}><p>Üretim: </p></div><span>{this.props.cihazVerileri?.outputStatus == "0" ? "Kapalı" : "Açık"}</span>
                                        </div>
                                        <div className="tema-bir-cihaz-bilgileri-txt" style={{ color: "#2D353C", display: "flex", flexDirection: "row" }}>
                                            <div style={{ width: "90px" }}><p>Üretim Set: </p></div><div>{this.props.cihazVerileri?.outputSet}</div>
                                        </div>
                                        <div className="tema-bir-cihaz-bilgileri-txt" style={{ color: "#2D353C", display: "flex", flexDirection: "row" }}>
                                            <div style={{ width: "90px" }}><p>Anlık Üretim: </p></div><div>{this.props.cihazVerileri?.currentOutput}</div>
                                        </div>
                                        <div className="tema-bir-cihaz-bilgileri-txt" style={{ color: "#2D353C", display: "flex", flexDirection: "row" }}>
                                            <div style={{ width: "90px" }}><p>Üretim Miktarı: </p></div><div>{this.props.cihazVerileri?.outputCount}</div>
                                        </div>
                                        <div className="tema-bir-cihaz-bilgileri-txt">
                                            <p style={{ margin: "0px auto", color: this.props.cihazVerileri?.renk === "#ff5b57" ? "#ff5b57" : "#2D353C" }}>Son Güncelleme</p>
                                        </div>
                                        <div key={"b-"} style={{ margin: "0px auto" }} className="tema-bir-cihaz-bilgileri-txt">
                                            <p style={{ margin: "0px auto" }} >{tarih}</p>
                                        </div>
                                    </div>
                                </div>
                                : <div className="tema-bir-cihaz-icerik-penceresi" style={{ height: icerikEkraniAcikKapali ? 370 : 0 + "px" }}>
                                    <div className="tema-bir-cihaz-gorseli">
                                    </div>
                                    <div className="tema-bir-cihaz-cihaz-bilgileri-penceresi">
                                        <div className="tema-bir-cihaz-bilgileri-cihaz">
                                            <p style={{ margin: "auto", color: this.props.cihazVerileri?.renk }}></p>
                                        </div>
                                        <div className="tema-bir-cihaz-bilgileri-txt" style={{ color: "#2D353C" }}>
                                            <div style={{ width: "90px" }}><p>: </p></div><span></span>
                                        </div>
                                        <div className="tema-bir-cihaz-bilgileri-txt" style={{ color: "#2D353C" }}>
                                            <div style={{ width: "90px" }}><p>: </p></div><span></span>
                                        </div>
                                        <div className="tema-bir-cihaz-bilgileri-txt" style={{ color: "#2D353C", display: "flex", flexDirection: "row" }}>
                                            <div style={{ width: "90px" }}><p>: </p></div><div></div>
                                        </div>
                                        <div className="tema-bir-cihaz-bilgileri-txt" style={{ color: "#2D353C", display: "flex", flexDirection: "row" }}>
                                            <div style={{ width: "90px" }}><p>: </p></div><div></div>
                                        </div>
                                        <div className="tema-bir-cihaz-bilgileri-txt" style={{ color: "#2D353C", display: "flex", flexDirection: "row" }}>
                                            <div style={{ width: "90px" }}><p>: </p></div><div></div>
                                        </div>
                                        <div className="tema-bir-cihaz-bilgileri-txt">
                                            <p style={{ margin: "0px auto", color: this.props.cihazVerileri?.renk === "#ff5b57" ? "#ff5b57" : "#2D353C" }}>Son Güncelleme</p>
                                        </div>
                                    </div>
                                </div>
                }
            </div>
        )
    }
}

export default TemaBirCihaz