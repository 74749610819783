import React, { useEffect, useState } from "react"
import "./api.css"
import NovaBilgiKarti from "../cihazlar/pages/cihaz-sayfasi/components/nova/nova-bilgi-karti"
import { Link } from "react-router-dom"
import omniconLogo from "../cihazlar/assests/Varlık 2.png"


const Api = (props) => {

    return(
        <Link to={"/cihazlar/nova/" + "1234567890123"}>
            <img src={omniconLogo} alt="omnicon" style={{ height: "70px" }} />
        </Link>
        //<NovaBilgiKarti></NovaBilgiKarti>
    )
}

export default Api