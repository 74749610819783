export const alarmsBasic = [
    "Alt kart haberleşme hatası",
    "Kimyasal tank seviyesi düşük",
    "Alt Kart aşırı akım",
    "Alt Kart düşük akım",
    "Alt Kart yüksek voltaj",
    "Alt Kart düşük voltaj",
    "Alt Kart Flash hafıza yazma hatası",
    "Aşırı Isı"
];

export const alarmsControl = [
    "Kontrol Kartı Flash hafıza yazma hatası",
    "Kartlar arası haberleşme hatası",
    "Su seviyesi düşük",
    "Akış yok",
    "Ham ölçüm değeri yüksek",
    "Ham ölçüm değeri düşük",
    "Ölçüm değeri yüksek",
    "Ölçüm değeri düşük",
    "Belirlenen basma limitine ulaştı",
    "Kontrol kartı düşük voltaj",
    "Basma geri beslemesinden sinyal gelmiyor"
];