import React, { useState, useEffect } from 'react';
import Chart from "react-apexcharts";
import CookieManager from "../../../cookieManager"
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import "./grafikler.css"
const CizgiGrafik = (props) => {
    const [kanalButonlari, setKanalButonlari] = useState([])
    const [gosterilecekData, setGosterilecekData] = useState([])
    const [gosterilecekKanallar, setGosterilecekKanallar] = useState([])
    const [kanalDatalari, setKanalDatalari] = useState([])
    const [baslangic, setBaslangic] = useState(true)
    let ekranGenisligi = props.ekranGenisligi - props.menuGenisligi


    const dataGuncelle = async () => {
        // console.log('authorization', 'Bearer ' + CookieManager.getCookie("token"))
        try {
            let res = await axios.get("https://api.enelsa.com:5015/api/grafikBilgi/" + window.location.pathname.split("k/")[1],
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'authorization': 'Bearer ' + CookieManager.getCookie("token")
                    }
                })
            let data = res.data;
            // console.log(JSON.stringify(data, null, 2), "*/*/"); // Veriyi JSON formatıda konsola yazdırma işlemi

            let kanallar = Object.keys(data);
            let kanalButonlari = [];
            let grafikDatalari = [];

            kanallar.forEach(kanal => {
                if (data[kanal]) {
                    if (Array.isArray(data[kanal].data)) {
                        if (data[kanal].data.length > 0) {
                            kanalButonlari.push({ kanal: kanal, durum: false });
                            let datalarEklenecekSekil = data[kanal].data.map(veri => veri.y.value); // 'y!nin value değerine erişmemz lazımdı.
                            let datalarTarih = data[kanal].data.map(veri => veri.x);
                            let dataUnit = data[kanal].data.map(veri => veri.y.unit);
                            grafikDatalari.push({ name: data[kanal].isim, data: datalarEklenecekSekil, tarih: datalarTarih, unit: dataUnit });
                        }
                    }
                }
            });

            setKanalButonlari(kanalButonlari);
            setKanalDatalari(grafikDatalari);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    useEffect(() => {
        if (!baslangic) return
        dataGuncelle()
        setBaslangic(false)
    }, [baslangic])

    const colors = ["#FF1654", "#ff00d3", "#00d4ff", "#F4A300", "#34D399", "#FF5E5B"];


    const dateFormatter = (date) => {
        const d = new Date(date);
        const day = String(d.getDate()).padStart(2, '0');
        const month = String(d.getMonth() + 1).padStart(2, '0'); // Aylar 0-11 arası olduğu için +1 ekliyoruz
        const year = String(d.getFullYear()).slice(-2); // Son iki haneyi almak için slice kullanıyoruz
        const hours = String(d.getHours()).padStart(2, '0');
        const minutes = String(d.getMinutes()).padStart(2, '0');

        return `${day}:${month}:${year} ${hours}:${minutes}`;
    }

    var options = {
        chart: {
            height: 350,
            type: "line",
            stacked: false
        },
        dataLabels: {
            enabled: false
        },
        colors: colors.slice(0, 6), // Maksimum 6 renk
        series: gosterilecekData,
        stroke: {
            width: [4, 4, 4, 4, 4, 4],
            curve: "smooth"
        },
        plotOptions: {
            bar: {
                columnWidth: "20%"
            }
        },
        xaxis: {
            labels: {
                style: {
                    colors: "lightblue" // Renkleri kullan
                }
            },
            categories: gosterilecekData.length ? gosterilecekData[0].tarih.map((_, i) => dateFormatter(gosterilecekData[0].tarih[i])) : [] // Dinamik x-axis
        },
        yaxis: gosterilecekKanallar.slice(0, 6).map((kanal, index) => ({
            axisTicks: {
                show: true
            },
            axisBorder: {
                show: true,
                color: colors[index] // Renkleri kullan
            },
            labels: {
                style: {
                    colors: colors[index] // Renkleri kullan
                }
            },
            title: {
                text: kanalDatalari[kanal].name, // Kanal ismini başlık olarak kullan
                style: {
                    color: colors[index] // Renkleri kullan
                }
            }
        })),
        tooltip: {
            followCursor: true,
            shared: true,
            intersect: false,
            x: {
                show: true
            }
        },
        legend: {
            horizontalAlign: "left",
            offsetX: 40,
            labels: {
                colors: undefined,
                useSeriesColors: true
            },
        }
    };

    const dataIslemi = (kanal, islem) => {
        // console.log("7878")
        let kopyaGosterilecekKanallar = JSON.parse(JSON.stringify(gosterilecekKanallar))
        if (!islem) kopyaGosterilecekKanallar.push(kanal)
        else {
            let index = kopyaGosterilecekKanallar.indexOf(kanal)
            kopyaGosterilecekKanallar.splice(index, 1)
        }

        let kopyaGrafikDatalari = JSON.parse(JSON.stringify(kanalDatalari))
        // console.log(kopyaGrafikDatalari, "44")
        let gosterilecekDatalar = []

        kopyaGosterilecekKanallar.forEach(index => {
            gosterilecekDatalar.push(kopyaGrafikDatalari[index])
        })

        setGosterilecekData(gosterilecekDatalar)
        setGosterilecekKanallar(kopyaGosterilecekKanallar)
    }

    function BackButton() {
        const navigate = useNavigate();

        const goBack = () => {
            navigate(-1); // Bir önceki sayfaya gider
        };

        return (
            <span className='backButtonDiv'>
                <button onClick={goBack} className='backButtonStyle'>
                    ←
                </button>
            </span>
        );
    }

    return (
        <div style={{ width: "100%", backgroundColor: "#42494f", alignItems: "center", display: "flex", flexDirection: "column", overflow: "hidden" }}>
            {/* <Chart options={options} series={options.series} type="line" height={500} /> */}
            <BackButton></BackButton>
            <div style={{ display: "flex", margin: 15, flexWrap: "wrap", justifyContent: "center" }}>
                {
                    kanalButonlari.map((anahtar, i) => {
                        return (
                            <div style={{ height: 65, width: 100, backgroundColor: "#009CEB", borderRadius: 4, margin: 5 }} onClick={() => { dataIslemi(i, gosterilecekKanallar.includes(i)) }}>
                                <div style={{ height: 8, width: 8, borderRadius: 10, marginTop: 5, marginLeft: 5, borderColor: "#F7FBFF", borderWidth: 2, borderStyle: "solid", display: "flex" }}>
                                    <div style={{ height: 5, width: 5, margin: "auto", borderRadius: 10, backgroundColor: gosterilecekKanallar.includes(i) ? "#F7FBFF" : "transparent" }}>

                                    </div>
                                </div>
                                <div style={{ color: "#F7FBFF", display: "flex", flexDirection: "column", alignItems: "center" }}>
                                    <div>
                                        {kanalDatalari[i].name}
                                    </div>
                                    <div>
                                        {kanalDatalari[i]?.data[0] + " " + kanalDatalari[i].unit[0]}
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
            {
                kanalButonlari.length === 0 ?
                    <div>
                        Gösterilecek veri yok
                    </div> :
                    <div style={{ height: 350, width: ekranGenisligi - 50, background: "transparent", marginLeft: 20 }}>
                        <Chart options={options} series={gosterilecekData} type="line" height={350} />
                    </div>
            }
        </div>
    );
};

export default CizgiGrafik;
