import React from "react";
import "./bottom-menu.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHouse, faMicrochip, faRss, fa1, fa2, faSquare, faMoneyBillTransfer, faUsersGear, faUsersRectangle, faCirclePlus, faCircleCheck } from '@fortawesome/free-solid-svg-icons'
import { Link } from "react-router-dom";

class AltMenu extends React.Component
{
    state = {
        cihazlarPenceresiAcik: false
    }
    classBelirle(konum){
        let mevcutKonum = this.props.konum
        if(konum === mevcutKonum && this.state.cihazlarPenceresiAcik)
        {
            return  "menu-item-selected-hover"
        }
        return konum === mevcutKonum ? "alt-menu-item-selected" : "alt-menu-item"
    }
    altMenuButton = (sayfa, icon)=>{
        return(
            <div className="alt-menu-buton">
                <Link className="alt-menu-link" to={"/"+sayfa}>
                    <div  className={this.classBelirle(sayfa) + " alt-menu-buton-div"}> 
                        <FontAwesomeIcon icon={icon} className="alt-menu-ico"/>
                    </div>
                </Link>
            </div>
        )
    }
    render(){
        return(
            <div style={{width: "100vw", display: "flex", height: "50px", position: "relative", justifyContent: "center", alignItems: "center"}} className="bottom-menu">
                {this.altMenuButton("anasayfa", faHouse)}
                {this.altMenuButton("cihazekle", faCirclePlus)}
                {this.altMenuButton("cihazonay", faCircleCheck)}
                <div style={{backgroundColor: "transparent"}} className={this.classBelirle("cihazlar") + " alt-menu-buton"}>
                    <div style={{position: "relative", height: "35px"}}>
                        <div onClick={()=>{this.setState({cihazlarPenceresiAcik: !this.state.cihazlarPenceresiAcik})}} id="alt-menu-buton-cihazlar" style={{backgroundColor: (this.state.cihazlarPenceresiAcik || this.props.konum === "cihazlar") ? "white" : "#2D353C"}} 
                            className={this.classBelirle("cihazlar")}> 
                            <FontAwesomeIcon icon={faMicrochip} className="alt-menu-ico" style={{color: this.state.cihazlarPenceresiAcik || this.props.konum === "cihazlar" ? "#2D353C" : "#F7FBFF"}}/> 
                        </div>
                        {
                            ["tema1", "tema2"].map(e=>{
                                return(
                                    <div className="alt-menu-buton-cihazlar-sekme" style={{top: this.state.cihazlarPenceresiAcik ? ("tema1" === e ? "-88px" : "-48px") : "0px", transition: "all 0.5s"}} key={e+"alt"}>
                                        <Link className="alt-menu-link" to="/cihazlar" onClick={()=>{localStorage.setItem("tema",e);this.props.temayiGuncelle(e) }}>
                                            <div className="alt-menu-buton-div" style={{backgroundColor: this.props.tema === e && this.state.cihazlarPenceresiAcik && this.props.konum === "cihazlar"  ? "#F7FBFF" : "#2D353C" }}> 
                                                <div className="alt-menu-buton-cihazlar-sekme-div">
                                                    <div>
                                                        <FontAwesomeIcon className="alt-menu-buton-cihazlar-sekme-ico-kare" icon={faSquare} style={{color: this.props.konum === "cihazlar" ? this.props.tema === e ? "#2D353C" : "#F7FBFF" : "#F7FBFF"}}/>
                                                        <FontAwesomeIcon className="alt-menu-buton-cihazlar-sekme-ico-rakam" icon={"tema1" === e ? fa1 : fa2} style={{margin: "auto 0px auto " + ("tema1" === e ? "14.3px" : "13.5px"), 
                                                        color: this.props.konum === "cihazlar" ? this.props.tema === e ? "#F7FBFF" : "#2D353C" : "#2D353C"}}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default AltMenu