import { React, useEffect, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faXmark } from "@fortawesome/free-solid-svg-icons"

const bildirimTipi = ["Kaydedilemedi", "Kaydedildi", "Kaydediliyor", "Düzgün Kaydedilemedi","İşleminiz Devam Ediyor","Değişen Data Yok"]
const bildirimRenkleri = ["red","green","#E6960B","red","#E6960B","red"]

const Bildirim = (props)=>{
    // props.bildirimiListedenCikar(props.konum)
    const [baslangic, setBaslangic] = useState(false)

    useEffect(()=>{
        if(baslangic) return
        setBaslangic(true)
        // // console.log("bildirim pozisyonu bildirimlerdennnnnnnnnnn")
        setTimeout(()=>{props.bildirimlerinPozisyonlanmasiniYonet(props.bildirimBilgileri.key,20)},150)
    },[baslangic])

    let bildirimOynat = (bildirim, i)=>{
        let pozisyon = i === 6 ? 20 : (20 + (i%2 ? 10 : -10))
        bildirim.style.right = pozisyon + "px"
        
        i < 6 ? setTimeout(()=>bildirimOynat(bildirim, ++i),100) : props.bildirimBilgileri.uyari = 0
    }

    // // console.log(props.bildirimBilgileri.uyari,"koooonnntttrooolllslslslsls")
    if(props.bildirimBilgileri.uyari === 1)
    {
        bildirimOynat(document.getElementById(props.bildirimBilgileri.key), 0)
    }

    return(
        <div id={props.bildirimBilgileri.key} style={{width: "190px", backgroundColor: bildirimRenkleri[props.bildirimBilgileri.bildirim], borderRadius: "5px", position: "absolute", top: 60 * props.konum + "px", right: props.bildirimBilgileri?.sagaGorePozisyonu + "px", color: "#F7FBFF", zIndex: "20", transition: "0.35s all"}}>
            <FontAwesomeIcon icon={faXmark} style={{position: "absolute", right: "8px", top: "5px", cursor: "pointer"}} onClick={()=>{props.bildirimlerinPozisyonlanmasiniYonet(props.bildirimBilgileri.key,-250); setTimeout(()=>props.bildirimiListedenCikar(props.bildirimBilgileri.key,"bildirimKaldir"),350)}}/>
            <div style={{fontSize: "12px", marginLeft: "15px", flexDirection: "column", lineHeight: "14px", padding: "10px 0px"}}>
                <p style={{margin: "0px"}}>{props.bildirimBilgileri.mesaj}</p>
                <p style={{margin: "0px"}}>{bildirimTipi[props.bildirimBilgileri.bildirim]}</p>
            </div>
        </div>
    )
}

export default Bildirim