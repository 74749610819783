import React from "react"
import "./cihazlar.css"
import { faChevronDown, faChevronUp, faMagnifyingGlass, faWifi, faTriangleExclamation, faCirclePlay, faMicrochip, faSlash, faCircleMinus, faCirclePlus, faPlay, faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TemaBirCihazYuklemeAnimasyonu from "./components/tema-bir/tema-bir-cihaz-yukleme";
import TemaBirCihaz from "./components/tema-bir/tema-bir-cihaz";
import TemaIkiCihazYukleme from "./components/tema-iki/tema-iki-cihaz-yukleme";
import TemaIkiCihaz from "./components/tema-iki/tema-iki-cihaz";
import cihazDatalariniEldeEt from "./data-islemleri/cihazlar-data";
import cihazVerileriniEldeEt from "./data-islemleri/cihazlar-verileri";
import axios from "axios"
import Swal from "sweetalert2";
import CookieManager from "../../../cookieManager";

let ilkOlusturma = true
const butonFiltreIcolar = [faMicrochip, faCirclePlay, faStar, faTriangleExclamation, faWifi, faSlash]
const butonFiltreRenkler = ["#005fab", "#02bb4a", "#009ceb", "#f59c1a", "#ff5b57"]
const butonFiltreRenkKatmaniRenkleri = ["#005eab85", "#02bb4985", "#009ceb85", "#f59d1a85", "#ff5a5791"]
let cihazIcerikTimeOut = null

class Cihazlar extends React.Component {

    state = {
        acikPencereKonumu: 0,
        filtrePeneresiBoyu: 35,
        filtrePenceresiIco: faChevronDown,
        filtre: "",
        temelFiltre: this.props.temelFiltre,
        filtreIconuGozukme: true,
        cihazDatalari: [],
        cihazData: [],
        ekranGenisligi: window.innerWidth,
        cihazBilgileri: ["-", "-", "-", "-", "-"],
        cihazVerileri: [],
        filtrelenmisData: [],
        durum: 0,
        tumData: [] // Başlangıçta boş olabilir
    }
    
    acikPencereKonumuGuncelle(guncellenekKonumBilgisi) {
        this.setState(
            {
                acikPencereKonumu: guncellenekKonumBilgisi
            }
        )
    }

    filtrePenceresiBoyunuGuncelle() {
        this.setState(
            {
                filtrePenceresiIco: this.state.filtrePenceresiIco === faChevronDown ? faChevronUp : faChevronDown,
                filtrePeneresiBoyu: this.state.filtrePeneresiBoyu === 35 ? 150 : 35
            }
        )
    }

    degisti = (e) => {
        const yeniFiltre = e.target.value;
        this.setState(prevState => {
            const filtreIconuGozukme = yeniFiltre.length < 19;
    
            // Eğer filtre boşsa, filtrelenmiş veriyi tüm verilerle geri yükleyin
            const yeniFiltrelenmisData = yeniFiltre
                ? prevState.tumData.filter(item =>
                    item.includes(yeniFiltre)
                  )
                : prevState.tumData; // Filtre temizlendiğinde tüm verileri geri yükleyin
    
            return {
                filtre: yeniFiltre,
                filtreIconuGozukme,
                filtrelenmisData: yeniFiltrelenmisData
            };
        }, () => {
            // console.log("Güncellenmiş filtrelenmiş data:", this.state.filtrelenmisData);
            // console.log("Güncellenmiş filtre:", this.state.filtre);
        });
    }

    

    ekranBoyutunaGoreYanYanaGosterilecekEnFazlaElementSayisi() {
        let kullanılabilirGenislik = this.state.ekranGenisligi - this.props.menuGenisligi
        if (kullanılabilirGenislik > 2030) return 6;
        if (kullanılabilirGenislik > 1690) return 5;
        if (kullanılabilirGenislik > 1355) return 4;
        if (kullanılabilirGenislik > 1045) return 3;
        if (kullanılabilirGenislik > 720) return 2;
        return 1;
    }

    icerikEkraniBoyutuAyarla = (cihaz) => {
        let guncellenecekData = this.state.cihazDatalari
        guncellenecekData[cihaz].icerikEkraniAcikKapali = !guncellenecekData[cihaz].icerikEkraniAcikKapali
        this.setState({ cihazDatalari: guncellenecekData })

    }

    ekrandaGosterilecekDatalariOlustur(tokenBilgileri, filtrelenmisData, durum) {
        const { cihazDatalari, alarmVerenCihazlar, haberlesmeyenCihazlar, stabilCihazlar, cihazVerileri, cihazData } = this.state;
        const { tema, kullaniciBilgileri, gosterilecekCihaziGuncelle } = this.props;

        let gosterilecekElemanSayisi = this.ekranBoyutunaGoreYanYanaGosterilecekEnFazlaElementSayisi();
        if (cihazDatalari.length === 0) {
            return Array(gosterilecekElemanSayisi).fill().map((_, i) => [
                {
                    component: tema.toLowerCase() === "tema1"
                        ? <TemaBirCihazYuklemeAnimasyonu key={`aaa-${i}`} />
                        : <TemaIkiCihazYukleme key={`aa-${i}`} />,
                    id: i
                }
            ]);
        }

        gosterilecekElemanSayisi = Math.min(gosterilecekElemanSayisi, filtrelenmisData.length);

        const olusturulacak = Array(gosterilecekElemanSayisi).fill().map(() => []);

        filtrelenmisData.forEach((e, i) => {
            if (cihazDatalari[e]) {
                let m = 1; // Default value for stabil cihazlar
                if (alarmVerenCihazlar.includes(e)) {
                    m = 3;
                } else if (haberlesmeyenCihazlar.includes(e)) {
                    m = 4;
                }

                const component = tema.toLowerCase() === "tema1"
                    ? <TemaBirCihaz
                        durum={m}
                        kullaniciId={kullaniciBilgileri.kullaniciId}
                        tokenBilgileri={tokenBilgileri}
                        key={e}
                        cihazVerileri={cihazDatalari[e]}
                        cihazData={cihazData}
                        cihazDegerleri={cihazVerileri[e]}
                        icerikEkraniBoyutuAyarla={this.icerikEkraniBoyutuAyarla}
                        gosterilecekCihaziGuncelle={gosterilecekCihaziGuncelle} />
                    : <TemaIkiCihaz
                        durum={m}
                        kullaniciId={kullaniciBilgileri.kullaniciId}
                        tokenBilgileri={tokenBilgileri}
                        key={e}
                        cihazVerileri={cihazDatalari[e]}
                        cihazData={cihazData}
                        cihazDegerleri={cihazVerileri[e]}
                        icerikEkraniBoyutuAyarla={this.icerikEkraniBoyutuAyarla}
                        gosterilecekCihaziGuncelle={gosterilecekCihaziGuncelle} />;

                olusturulacak[i % gosterilecekElemanSayisi].push({ component, id: e });
            }
        });

        return olusturulacak;
    }

    ekranBoyutuDegisti = () => {
        this.setState(
            {
                ekranGenisligi: window.innerWidth
            }
        )
    }

    cihazVerileriniGuncelleVeri = async () => {
        if (window.location.pathname !== "/cihazlar")
            return
        if (cihazIcerikTimeOut !== null)
            clearTimeout(cihazIcerikTimeOut)
        let cihazDatalari = await cihazDatalariniEldeEt(CookieManager.getCookie("id"))

        if (cihazDatalari.cihazMiktarlari.tumCihazlar !== 0) {

            let cihazVerilerinew = await cihazVerileriniEldeEt(Object.keys(cihazDatalari.cihazlar))

            this.setState({ cihazVerileri: cihazVerilerinew });


        }


    }

    cihazVerileriniGuncelle = async () => {
        if (window.location.pathname !== "/cihazlar")
            return
        if (cihazIcerikTimeOut !== null)
            clearTimeout(cihazIcerikTimeOut)
        let cihazDatalari = await cihazDatalariniEldeEt(CookieManager.getCookie("id"))

        const keys = Object.keys(cihazDatalari.cihazMiktarlari);

        // Anahtarları belirli bir sıraya göre sıralamak
        const orderedKeys = ["tumCihazlar", "stabilCihazlar", "pinli", "alarmVerenCihazlar", "haberlesmeyenCihazlar"];

        const sortedKeys = keys.sort((a, b) => orderedKeys.indexOf(a) - orderedKeys.indexOf(b));
        if (cihazDatalari.cihazMiktarlari.tumCihazlar !== 0) {
            Object.keys(cihazDatalari.cihazlar).forEach(e => {
                let pozisyon = false
                if (!ilkOlusturma && this.state.cihazDatalari[e] !== undefined) {
                    if (this.state.cihazDatalari[e]["icerikEkraniAcikKapali"] !== undefined)
                        pozisyon = this.state.cihazDatalari[e]["icerikEkraniAcikKapali"]
                }
                cihazDatalari.cihazlar[e]["icerikEkraniAcikKapali"] = pozisyon
            })
            let cihazBilgileri = Array(5)
            let cihazVerileri = await cihazVerileriniEldeEt(Object.keys(cihazDatalari.cihazlar))
            // console.log(Object.keys(cihazDatalari.cihazMiktarlari), cihazBilgileri)
            sortedKeys.forEach((e, i) => {
                //console.log(e, i)
                cihazBilgileri[i] = cihazDatalari.cihazMiktarlari[e]

            })
            this.setState({ filtrelenmisData: Object.keys(cihazDatalari.cihazlar), cihazDatalari: cihazDatalari.cihazlar, cihazBilgileri: cihazBilgileri, cihazVerileri: cihazVerileri, stabilCihazlar: cihazDatalari.stabilCihazlar, pinliler: cihazDatalari.pinliler, haberlesmeyenCihazlar: cihazDatalari.haberlesmeyenCihazlar, alarmVerenCihazlar: cihazDatalari.alarmVerenCihazlar })

            // this.setState({ cihazDatalari: cihazDatalari.cihazlar, cihazBilgileri: cihazBilgileri })

            ilkOlusturma = false
            cihazIcerikTimeOut = setTimeout(this.cihazVerileriniGuncelle, 1000)
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Görüntülenebilecek cihaz yok!',
                text: 'Lütfen Cihaz Ekle menüsü ile cihaz kurulumunu tamamlayınız.',
                color: "aliceblue",
                position: "center",
                background: "#505d68",
                confirmButtonText: 'Tamam',
                confirmButtonColor: 'rgb(70, 85, 105)',
                width: '25em'
            })
        }

    }

    cihazVerileriniGuncelleData = async () => {
        if (window.location.pathname !== "/cihazlar")
            return
        if (cihazIcerikTimeOut !== null)
            clearTimeout(cihazIcerikTimeOut)
        let gonderilecekData = {
            id: CookieManager.getCookie("id")
        }
        let dataIsimleri = await axios.post("https://api.enelsa.com:5015/api/alarmBilgileri", gonderilecekData, {
            headers: {
                'authorization': 'Bearer ' + CookieManager.getCookie("token")
            }
        })
        // console.log(dataIsimleri)
        this.setState({ cihazData: dataIsimleri })
    }

    intervalId = null;

    tekrarDene = async () => {
        if (CookieManager.getCookie("id") === "-") {
            // Kullanıcı bilgileri henüz yüklenmedi, tekrar denenecek...
            setTimeout(this.tekrarDene, 1000);
        } else {
            await this.cihazVerileriniGuncelleVeri();
            await this.cihazVerileriniGuncelle();
            await this.cihazVerileriniGuncelleData();
        }
    }

    async componentDidMount() {
        this.props.konumGuncelle("cihazlar");
        window.addEventListener("resize", this.ekranBoyutuDegisti);
        try {
            await this.props.kullaniciBilgileriGuncelle();
            // 5 saniyede bir cihaz verilerini güncelle
            this.intervalId = setInterval(async () => {
                await this.cihazVerileriniGuncelleVeri();
                //await this.cihazVerileriniGuncelle();
                //await this.cihazVerileriniGuncelleData();
            }, 5000); // 5000 ms = 5 saniye

            this.tekrarDene();
        } catch (e) {
            // console.log(e);
        }
    }

    componentWillUnmount() {
        // Bileşen kaldırıldığında interval'i temizle
        if (this.intervalId) {
            clearInterval(this.intervalId);
        }
        window.removeEventListener("resize", this.ekranBoyutuDegisti);
    }


    butonFiltreIcon(ico, size) {
        return (
            <>
                <FontAwesomeIcon icon={ico} className="buton-filtre-ico-golge" style={{ fontSize: size }} />
                <FontAwesomeIcon icon={ico} className="buton-filtre-ico" style={{ fontSize: size }} />
            </>
        )
    }

    temelFiltreDegistir = (e) => {
        // console.log(this.state, "--*")
        const yeniFiltre = e;
        let kullanilacakArray = Object.keys(this.state.cihazDatalari);
        let renk = butonFiltreRenkler[e]
        switch (e) {
            case 0: // Tüm cihazlar
                kullanilacakArray = Object.keys(this.state.cihazDatalari);
                break;
            case 1: // Stabil Cihazlar
                kullanilacakArray = this.state.stabilCihazlar;
                break;
            case 2: // Pinliler
                kullanilacakArray = this.state.pinliler;
                break;
            case 3: // Alarmlar
                kullanilacakArray = this.state.alarmVerenCihazlar;
                break;
            case 4: // Haberleşmeyen
                kullanilacakArray = this.state.haberlesmeyenCihazlar;
                break;
            default:
                kullanilacakArray = Object.keys(this.state.cihazDatalari);
                break;
        }
// TODO tumData stati bugün sayfa açıldığında yüklenmesi lazım aksi halde boş geliyor butona basmamız egrekyor afiltreleme butonu
        this.setState({ temelFiltre: yeniFiltre, filtrelenmisData: kullanilacakArray, tumData: kullanilacakArray, renk, durum: e }, () => {
            this.ekrandaGosterilecekDatalariOlustur(this.props.tokenBilgileri, kullanilacakArray, e);
        });
    }



    render() {
        // console.log(this.props, "cihazlar.js props")
        if (this.props.konum !== "cihazlar")
            this.props.konumGuncelle("cihazlar")
        let kullanilabilirAlan = this.state.ekranGenisligi - this.props.menuGenisligi
        // Get the data to display
        const dataToDisplay = this.ekrandaGosterilecekDatalariOlustur(this.props.tokenBilgileri, this.state.filtrelenmisData, this.state.durum);

        // Filter out items where component is empty
        const filteredData = Array.isArray(dataToDisplay)
            ? dataToDisplay.map(group =>
                group.filter(item => item.component)
            ).filter(group => group.length > 0)
            : [];

        return (
            <div className="cihazlar">
                <div id="cihazlar-temel-div" style={{ margin: (kullanilabilirAlan > 750 ? "15px " : "0px ") + "auto 5px auto" }}>
                    <div id="cihazlar-filtre-penceresi" style={{ flexDirection: kullanilabilirAlan > 750 ? "row" : "column" }}>
                        {kullanilabilirAlan > 750 &&
                            <>
                                <FontAwesomeIcon icon={faPlay} className="cihazlar-sayfa-degistirme-icolari" style={{ rotate: "180deg" }} />
                                <FontAwesomeIcon icon={faPlay} className="cihazlar-sayfa-degistirme-icolari" style={{ right: "0px" }} />
                            </>
                        }
                        <div id="buton-filtre" style={{ margin: (kullanilabilirAlan > 460 ? "0px" : "0px") + " 10px 0px " + (kullanilabilirAlan > 750 ? ((((kullanilabilirAlan) / 2) - 180)) : 10) + "px" }}>
                            {
                                Array.from(Array(5).keys()).map(e => {
                                    return (
                                        <div className="buton-filtre-cerceve" style={{ backgroundColor: butonFiltreRenkler[e], border: this.state.renk === butonFiltreRenkler[e] ? "2px solid #F7FBFF" : "0px solid #F7FBFF" }} onClick={() => this.temelFiltreDegistir(e)} key={e}>
                                            {this.butonFiltreIcon(butonFiltreIcolar[e], e !== 4 ? "20px" : "17px")}
                                            {e === 4 && this.butonFiltreIcon(butonFiltreIcolar[e + 1], "17px")}
                                            <div className="buton-filtre-renk-katmani" style={{ backgroundColor: butonFiltreRenkKatmaniRenkleri[e] }}></div>
                                            <p className="buton-filtre-yazi">{this.state.cihazBilgileri[e]}</p>
                                        </div>
                                    )
                                })
                            }
                        </div>

                        <div style={{
                            margin: this.state.ekranGenisligi > 640 ? kullanilabilirAlan > 750 ? "0px 10px 0px auto" : "0px 10px 0px 10px" : "auto",
                            width: this.state.ekranGenisligi > 640 ? kullanilabilirAlan > 750 ? "auto" : "270px" : "270px"
                        }} id="arama-filtresi-cihaz-boyu">
                            <div id="arama-filtresi">
                                <div>
                                    {this.state.filtreIconuGozukme && <FontAwesomeIcon icon={faMagnifyingGlass} id="arama-filtresi-ico" />}
                                </div>
                               
                                <input placeholder={"Filtrele"} onChange={this.degisti} value={this.state.filtre} />
                            </div>
                            {/* <FontAwesomeIcon icon={true ? faCircleMinus : faCirclePlus} id="tüm-cihaz-boyu-ico" onClick={() => this.icerikEkraniBoyutuAyarla()} />
                            {!(kullanilabilirAlan > 750) &&
                                <>
                                    <FontAwesomeIcon icon={faPlay} className="cihazlar-sayfa-degistirme-icolari-kucuk-ekran" style={{ left: "0px", rotate: "180deg" }} />
                                    <FontAwesomeIcon icon={faPlay} className="cihazlar-sayfa-degistirme-icolari-kucuk-ekran" style={{ right: "0px" }} />
                                </>
                            } */}
                        </div>
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                        {
                            filteredData.map((group, index) => (
                                <div key={index} style={{ display: "flex", flexDirection: "column" }}>
                                    {group.map(item => item.component)}
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>

        )
    }
}

export default Cihazlar