import React, { useEffect, useState } from "react"
import { faWaveSquare, faChartLine, faX } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import "./sinyal-ppm-on-taraf.css"
import CookieManager from "../../../../../cookieManager"
import axios from "axios"
import Swal from "sweetalert2"
import yuklemeDalgasi from "../img/dalga-animasyon.svg"
import {
    mode,
    state,
    aktif,
    isletimModu,
    unitSelection,
    unitSelection2,
    icolarMod,
    icolarState
} from './dictionaries'
import {
    ChooseMenu,
    SelectMenu,
    Input,
    ConfigLock,
    EventBus,
    ApprovalPopup,
    MailPopup,
    Countdown
} from "./common-components"
import { ConfigClient } from "./common-requests"

const SinyalPpmArkaTaraf = (props) => {

    let altMode = {
        "0": "Vuruş/Sinyal",
        "1": "Su Sayacı",
        "2": "ppm Uygulama",
        "3": "Debimetre"
    }

    let pulseAmountUnit = {
        "0": "Lt",
        "1": "m3"
    }

    let flowMeterUnit = {
        "0": "Lt/h",
        "1": "m3/h"
    }

    let [baslangic, setBaslangic] = useState(false)
    let [showSettingsMenu, setShowSettingsMenu] = useState(false)
    let [strokeRateUnitSelection, setStrokeRateUnitSelection] = useState(null)
    let [runMode, setRunMode] = useState(null)
    let [editx, setEditx] = useState(false)
    let [strokeRate, setStrokeRate] = useState(null)
    let [strokeRateLowLimit, setStrokeRateLowLimit] = useState(null)
    let [strokeRateHighLimit, setStrokeRateHighLimit] = useState(null)
    let [pulseAmount, setPulseAmount] = useState(null)
    let [chemicalActivity, setChemicalActivity] = useState(null)
    let [watermeterOutput, setWatermeterOutput] = useState(null)
    let [pulseCount, setPulseCount] = useState(null)
    let [strokeCount, setStrokeCount] = useState(null)
    let [flowMaterMinimumRatio, setFlowMaterMinimumRatio] = useState(null)
    let [flowMaterMaximumRatio, setFlowMaterMaximumRatio] = useState(null)
    let [pulseInputDivider, setPulseInputDivider] = useState(null)
    let [ppmSet, setPpmSet] = useState(null)
    let [strokeMemorize, setStrokeMemorize] = useState(null)
    let [adaptiveStrokeRateControl, setAdaptiveStrokeRateControl] = useState(null)
    let [icoRunMode, setIcoRunMode] = useState(null)
    let [showLoadScreen, setShowLoadScreen] = useState(true)

    let [configLock, setConfigLock] = useState(true)

    let popupArkaplanHeight = props.ekranGenisligi < 790 && "700px"
    let location = window.location.pathname.split("r/")[1]
    let seriNo = location.split("#")[0]

    async function manuelKaydet(cihazKullanici, data) {
        // console.log(data)
        let gonderilecekData = {}
        let keys = Object.keys(data)
        keys.map((e) => {
            if (!isNaN(data[e])) {
                gonderilecekData[e] = data[e]
            }
        })
        let gonderilecek = {
            seriNo: seriNo,
            data: gonderilecekData
        }
        // console.log(gonderilecekData)
        try {
            const res = await axios.post("https://api.enelsa.com:5015/api/cihazAyarNova", JSON.stringify(gonderilecek), {
                headers: {
                    "Content-Type": "application/json",
                    "authorization": "Bearer " + CookieManager.getCookie("token")
                }
            })
            if (res.data === "ok") {
                let gonderilecekData = {
                    seriNo: seriNo
                }
                try {
                    const res = await axios.post('https://api.enelsa.com:5015/api/cihazAyarKontrol', JSON.stringify(gonderilecekData), {
                        headers: {
                            'Content-Type': 'application/json',
                            'authorization': 'Bearer ' + CookieManager.getCookie("token")
                        }
                    })
                    if (res.status == 200) {
                        Swal.fire({
                            icon: 'success',
                            title: 'Ayar Başarılı',
                            text: 'Güncelleme işlemi 1-5 saniye içinde gerçekleşecektir.',
                            confirmButtonText: 'Tamam',
                            confirmButtonColor: 'rgb(70, 85, 105)',
                            width: '25em',
                        })
                        setEditx(true)
                    }

                } catch (e) {
                    // console.log(e)
                }
            }
            setEditx(true)
            setShowLoadScreen(true)
            setShowSettingsMenu(false)
        } catch (e) {
            setShowLoadScreen(false)
        }
    }

    function runModeEdit() {
        runMode == 0 ? setRunMode("1") : setRunMode("0")
        icoRunMode == 0 ? setIcoRunMode("1") : setIcoRunMode("0")
    }

    async function varsayilanlaraSifirla() {
        setStrokeRate(props.veriler["strokeRate"])
        setStrokeRateLowLimit(props.veriler["strokeRateLowLimit"])
        setStrokeRateHighLimit(props.veriler["strokeRateHighLimit"])
        setStrokeRateUnitSelection(props.veriler["strokeRateUnitSelection"])
        setPulseAmount(props.veriler["pulseAmount"])
        setChemicalActivity(props.veriler["chemicalActivity"])
        setWatermeterOutput(props.veriler["watermeterOutput"])
        setPulseCount(props.veriler["pulseCount"])
        setStrokeCount(props.veriler["strokeCount"])
        setFlowMaterMinimumRatio(props.veriler["flowMaterMinimumRatio"])
        setFlowMaterMaximumRatio(props.veriler["flowMaterMaximumRatio"])
        setPulseInputDivider(props.veriler["pulseInputDivider"])
        setPpmSet(props.veriler["ppmSet"])
        setStrokeMemorize(props.veriler["strokeMemorize"])
        setAdaptiveStrokeRateControl(props.veriler["adaptiveStrokeRateControl"])
        setRunMode(props.veriler["runMode"])
        setIcoRunMode(props.veriler["runMode"])

    }

    useEffect(() => {
        setBaslangic(true)
        setStrokeRate(props.veriler["strokeRate"])
        setStrokeRateLowLimit(props.veriler["strokeRateLowLimit"])
        setStrokeRateHighLimit(props.veriler["strokeRateHighLimit"])
        setStrokeRateUnitSelection(props.veriler["strokeRateUnitSelection"])
        setPulseAmount(props.veriler["pulseAmount"])
        setChemicalActivity(props.veriler["chemicalActivity"])
        setWatermeterOutput(props.veriler["watermeterOutput"])
        setPulseCount(props.veriler["pulseCount"])
        setStrokeCount(props.veriler["strokeCount"])
        setFlowMaterMinimumRatio(props.veriler["flowMaterMinimumRatio"])
        setFlowMaterMaximumRatio(props.veriler["flowMaterMaximumRatio"])
        setPulseInputDivider(props.veriler["pulseInputDivider"])
        setPpmSet(props.veriler["ppmSet"])
        setStrokeMemorize(props.veriler["strokeMemorize"])
        setAdaptiveStrokeRateControl(props.veriler["adaptiveStrokeRateControl"])
        setRunMode(props.veriler["runMode"])
        setIcoRunMode(props.veriler["runMode"])
    }, [baslangic, editx && props])

    useEffect(() => {
        setShowLoadScreen(false)
        setShowSettingsMenu(false)
        setEditx(false)
    }, [props.veriler])

    let classNovaAlt = props.ekranGenisligi < 790 ? "nova-bilgi-kart-alt-mobil" : "nova-bilgi-kart-alt"
    return (
        <>
            <div className="cihaz-bilgi-kart-icerik-cerceve2">
                <div className="nova-bilgi-kart-ust">
                    <div className="nova-isletim-modu">
                        <div>İşletim Modu : {isletimModu[props.veriler["operatingMode"]]}</div>
                        <div>Sinyal Giriş Modu : {altMode[props.veriler["operatingSubMode"]]}</div>
                    </div>
                </div>
                <div className="nova-bilgi-kart-orta-ppm">
                    <div className="vurus-sayisi">
                        <div className="vurus-ust">
                            Dozaj Hızı
                        </div>
                        <hr className="vurus-cizgi"></hr>
                        <div className="vurus-alt">
                            <span>{props.veriler["strokeRate"]}
                                {configLock ?
                                    <button className="nova-bilgi-kart-orta-unit-selection-button" >{unitSelection[strokeRateUnitSelection]}</button>
                                    :
                                    <button className="nova-bilgi-kart-orta-unit-selection-button" onClick={() => { setShowSettingsMenu(true) }}>{unitSelection[strokeRateUnitSelection]}</button>
                                }
                            </span>
                        </div>
                    </div>
                    <div>
                        <hr className=""></hr>
                        <div className="icos">
                            <div className="calisma-modux">
                                <div>Çalışma Modu : <span>{mode[runMode]}</span> {<span className={(mode[props.veriler["runMode"]] != mode[runMode]) ? "editStar" : "star"}>*</span>}</div>
                                <span style={{ cursor: "pointer" }} onClick={runModeEdit}>{icolarMod[icoRunMode]} </span>
                            </div>
                            <div className="calisma-modux">
                                <div>Durum : <span>{state[props.veriler["runModeState"]]} {<span className="star">*</span>}</span></div>
                                <span>{icolarState[props.veriler["runModeState"]]} </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={classNovaAlt}>
                    <div className="kapasite-limit">
                        {props.veriler["operatingSubMode"] == "2" && <div>ppm Set : <span>{Input(ppmSet, setPpmSet, "nova-manuel-alt-input")} ppm</span> </div>}
                        <div>Kapasite Alt Limit : <span>{Input(strokeRateLowLimit, setStrokeRateLowLimit, "nova-manuel-alt-input")} {<span className={(props.veriler["strokeRateLowLimit"] != strokeRateLowLimit) ? "editStar" : "star"}>*</span>} {unitSelection2[strokeRateUnitSelection]}</span> </div>
                        <div>Kapasite Üst Limit : <span>{Input(strokeRateHighLimit, setStrokeRateHighLimit, "nova-manuel-alt-input")} {<span className={(props.veriler["strokeRateHighLimit"] != strokeRateHighLimit) ? "editStar" : "star"}>*</span>} {unitSelection2[strokeRateUnitSelection]}</span> </div>
                    </div>
                    <div className="kapasite-limit">
                        <div>Sinyal Bölen : <span>{Input(pulseInputDivider, setPulseInputDivider, "nova-manuel-alt-input")} {<span className={(props.veriler["pulseInputDivider"] != pulseInputDivider) ? "editStar" : "star"}>*</span>}</span> </div>
                        {props.veriler["operatingSubMode"] != "0" && <div>Miktar/Sinyal : <span>{Input(pulseAmount, setPulseAmount, "nova-manuel-alt-input")} {<span className={(props.veriler["pulseAmount"] != pulseAmount) ? "editStar" : "star"}>*</span>} {pulseAmountUnit[props.veriler["pulseAmountUnit"]]}</span></div>}
                        {props.veriler["operatingSubMode"] == "2" && <div>Kimyasal Aktivite : <span>{Input(chemicalActivity, setChemicalActivity, "nova-manuel-alt-input")} {<span className={(props.veriler["chemicalActivity"] != chemicalActivity) ? "editStar" : "star"}>*</span>} %</span></div>}
                        {props.veriler["operatingSubMode"] == "1" && <div>Çıkış mL/m3 : <span>{Input(watermeterOutput, setWatermeterOutput, "nova-manuel-alt-input")} {<span className={(props.veriler["watermeterOutput"] != watermeterOutput) ? "editStar" : "star"}>*</span>}</span></div>}
                        {props.veriler["operatingSubMode"] == "0" && <div>Sinyal Sayısı: <span>{Input(pulseCount, setPulseCount, "nova-manuel-alt-input")} {<span className={(props.veriler["pulseCount"] != pulseCount) ? "editStar" : "star"}>*</span>}</span></div>}
                        {props.veriler["operatingSubMode"] == "0" && <div>Vuruş Sayısı: <span>{Input(strokeCount, setStrokeCount, "nova-manuel-alt-input")} {<span className={(props.veriler["strokeCount"] != strokeCount) ? "editStar" : "star"}>*</span>}</span></div>}
                    </div>
                    {(props.veriler["operatingSubMode"] != "3") &&
                        <div className="kapasite-limit">
                            <div style={{ display: "flex", alignItems: "baseline" }}>Vuruş Hafıza : <span>{SelectMenu(strokeMemorize, setStrokeMemorize, aktif)} {<span className={(props.veriler["strokeMemorize"] != strokeMemorize) ? "editStar" : "star"}>*</span>}</span></div>
                            <div style={{ display: "flex", alignItems: "baseline" }}>Adaptif Vuruş : <span>{SelectMenu(adaptiveStrokeRateControl, setAdaptiveStrokeRateControl, aktif)} {<span className={(props.veriler["adaptiveStrokeRateControl"] != adaptiveStrokeRateControl) ? "editStar" : "star"}>*</span>}</span></div>
                        </div>
                    }
                    {props.veriler["operatingSubMode"] == "3" &&
                        <div className="kapasite-limit">
                            <div>Debimetre Min Oran : <span>{Input(flowMaterMinimumRatio, setFlowMaterMinimumRatio, "nova-manuel-alt-input")} {<span className={(props.veriler["flowMaterMinimumRatio"] != flowMaterMinimumRatio) ? "editStar" : "star"}>*</span>}</span></div>
                            <div style={{ paddingBottom: "8px" }}>Debimetre Max Oran : <span>{Input(flowMaterMaximumRatio, setFlowMaterMaximumRatio, "nova-manuel-alt-input")} {<span className={(props.veriler["flowMaterMaximumRatio"] != flowMaterMaximumRatio) ? "editStar" : "star"}>*</span>}</span></div>
                            <div>Debimetre Birimi : <span>{flowMeterUnit[props.veriler["flowMeterUnit"]]}</span></div>
                        </div>
                    }
                </div>
            </div>
            {
                <div className="buttonDiv">
                    <button onClick={() => varsayilanlaraSifirla()} className="nova-kaydet-button">TEMİZLE</button>
                    <button onClick={() => manuelKaydet(props.cihazKullanici,
                        {
                            strokeRate: parseInt(strokeRate),
                            strokeRateLowLimit: parseInt(strokeRateLowLimit),
                            strokeRateHighLimit: parseInt(strokeRateHighLimit),
                            runMode: parseInt(runMode),
                            pulseAmount: parseInt(pulseAmount),
                            chemicalActivity: parseInt(chemicalActivity),
                            watermeterOutput: parseInt(watermeterOutput),
                            pulseCount: parseInt(pulseCount),
                            strokeCount: parseInt(strokeCount),
                            flowMaterMinimumRatio: parseInt(flowMaterMinimumRatio),
                            flowMaterMaximumRatio: parseInt(flowMaterMaximumRatio),
                            pulseInputDivider: parseInt(pulseInputDivider),
                            ppmSet: parseInt(ppmSet),
                            strokeMemorize: parseInt(strokeMemorize),
                            adaptiveStrokeRateControl: parseInt(adaptiveStrokeRateControl)
                        }
                    )} className="nova-kaydet-button">KAYDET</button>
                </div>
            }
        </>
    )
}

export default SinyalPpmArkaTaraf