import React, { useEffect, useState } from "react"
import { faGear, faRotateLeft, faChartLine, faWaveSquare } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "react-router-dom"
import "./kontrol-on-taraf.css"
import {
    aktif,
    isletimModu,
    mode,
    state,
    unitSelection2 as unitSelection,
    icolarMod,
    icolarState
} from "./dictionaries"

const KontrolOnTarafi = (props) => {

    let altMode = {
        "0": "pH",
        "1": "ORP",
        "2": "Serbest Klor OC",
        "3": "4-20 mA Sensör"
    }

    let unit = {
        "0": "pH",
        "1": "mV"
    }

    let classNovaAlt = props.ekranGenisligi > 790 ? "nova-bilgi-kart-altx" : (props.ekranGenisligi > 520) ? "nova-bilgi-kart-alt-tabletx" : "nova-bilgi-kart-alt-mobilx"
    return (
        <>
            <div className="cihaz-bilgi-kart-icerik-cerceve2">
                <div className="nova-bilgi-kart-ust">
                    <div className="nova-isletim-modu">
                        <div>İşletim Modu : {isletimModu[props.veriler["operatingMode"]]}</div>
                        <div>Sensör Tipi : {altMode[props.veriler["measurementSensorTypeValue"]]}</div>
                    </div>
                </div>
                <div className="nova-bilgi-kart-orta-ppm">
                    <div className="vurus-sayisi">
                        <div className="vurus-ust">
                            Dozaj Hızı
                        </div>
                        <hr className="vurus-cizgi"></hr>
                        <div className="vurus-alt">
                            <span>{props.veriler["strokeRate"]} {unitSelection[props.veriler["strokeRateUnitSelection"]]}</span>
                        </div>
                    </div>
                    <div>
                        <hr className=""></hr>
                        <div className="icos">
                            <div className="calisma-modux">
                                <div>Çalışma Modu : <span>{mode[props.veriler["runMode"]]}</span></div>
                                <span>{icolarMod[props.veriler["runMode"]]} </span>
                            </div>
                            <div className="calisma-modux">
                                <div>Durum : <span>{state[props.veriler["runModeState"]]}</span></div>
                                <span>{icolarState[props.veriler["runModeState"]]} </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={classNovaAlt}>
                    <div className="kapasite-limit">
                        <div>Kapasite Alt Limit : <span>{props.veriler["strokeRateLowLimit"]} {unitSelection[props.veriler["strokeRateUnitSelection"]]}</span> </div>
                        <div>Kapasite Üst Limit : <span>{props.veriler["strokeRateHighLimit"]} {unitSelection[props.veriler["strokeRateUnitSelection"]]}</span> </div>
                        <br></br>
                        <div>Kontrol Set Alt : <span>{props.veriler["setLow"]} {unit[props.veriler["measurementSensorTypeValue"]]}</span> </div>
                        <div>Set Alt Kapasite Oran : <span>{props.veriler["setLowStrokeRateValue"]} {unitSelection[props.veriler["strokeRateUnitSelection"]]}</span> </div>
                    </div>
                    <div className="kapasite-limit">
                        <div>Sensör Değeri : <span>{props.veriler["sensorValue"]} {unit[props.veriler["measurementSensorTypeValue"]]}</span> </div>
                        <div style={{ opacity: "0" }}>Sensör Değeri : <span>{props.veriler["sensorValue"]} {unit[props.veriler["measurementSensorTypeValue"]]}</span> </div>
                        <br></br>
                        <div>Kontrol Set Üst : <span>{props.veriler["setHigh"]} {unit[props.veriler["measurementSensorTypeValue"]]}</span> </div>
                        <div>Set Üst Kapasite Oran : <span>{props.veriler["setHighStrokeRateValue"]} {unitSelection[props.veriler["strokeRateUnitSelection"]]}</span> </div>
                    </div>
                    <div className="kapasite-limit">
                        <div>Başlangıç Gecikmesi : <span>{props.veriler["startupDelayTime"]} sn</span> </div>
                        <div style={{ opacity: props.veriler["measurementSensorTypeValue"] === 1 ? "0" : "1" }} >Sıcaklık Kompanzasyon : <span>{aktif[props.veriler["pHTemperatureCompenstionSelection"]]}</span></div>
                        <br></br>
                        <div>Analog Output Set Alt : <span>{props.veriler["analogOutputSetLowValue"]} {unit[props.veriler["measurementSensorTypeValue"]]}</span></div>
                        <div>Analog Output Set Üst : <span>{props.veriler["analogOutputSetHighValue"]} {unit[props.veriler["measurementSensorTypeValue"]]}</span></div>
                    </div>
                </div>
            </div>
            <Link id="cihaz-on-taraf-grafik-ico" to={"/grafik/" + window.location.pathname.split("r/")[1]}>
                <FontAwesomeIcon icon={faChartLine} />
            </Link>
        </>
    )
}

export default KontrolOnTarafi