import React, { useEffect, useState } from "react"
import "./sinyal-ppm-on-taraf.css"
import axios from "axios"
import Swal from "sweetalert2"
import CookieManager from "../../../../../cookieManager"
import {
    mode,
    state,
    aktif,
    isletimModu,
    unitSelection,
    unitSelection2,
    icolarMod,
    icolarState
} from './dictionaries'

import {
    ChooseMenu,
    SelectMenu,
    Input,
    ConfigLock,
    EventBus,
    ApprovalPopup,
    MailPopup,
    Countdown
} from "./common-components"


const KontrolArkaTaraf = (props) => {

    let altMode = {
        "0": "pH",
        "1": "ORP"
    }

    let unit = {
        "0": "pH",
        "1": "mV"
    }

    let [baslangic, setBaslangic] = useState(false)
    let [showSettingsMenu, setShowSettingsMenu] = useState(false)
    let [strokeRateUnitSelection, setStrokeRateUnitSelection] = useState(null)
    let [runMode, setRunMode] = useState(null)
    let [editx, setEditx] = useState(false)
    let [strokeRate, setStrokeRate] = useState(null)
    let [strokeRateLowLimit, setStrokeRateLowLimit] = useState(null)
    let [strokeRateHighLimit, setStrokeRateHighLimit] = useState(null)
    let [icoRunMode, setIcoRunMode] = useState(null)

    let [setHigh, setSetHigh] = useState(null)
    let [setHighStrokeRateValue, setSetHighStrokeRateValue] = useState(null)
    let [setLow, setSetLow] = useState(null)
    let [setLowStrokeRateValue, setSetLowStrokeRateValue] = useState(null)
    let [startupDelayTime, setStartupDelayTime] = useState(null)
    let [analogOutputSetLowValue, setAnalogOutputSetLowValue] = useState(null)
    let [analogOutputSetHighValue, setAnalogOutputSetHighValue] = useState(null)
    let [pHTemperatureCompenstionSelection, setpHTemperatureCompenstionSelection] = useState(null)

    let [configLock, setConfigLock] = useState(true)

    let location = window.location.pathname.split("r/")[1]
    let seriNo = location.split("#")[0]

    async function manuelKaydet(cihazKullanici, data) {

        // console.log(data)
        let gonderilecekData = {}
        let keys = Object.keys(data)
        keys.map((e) => {
            if (!isNaN(data[e])) {
                gonderilecekData[e] = data[e]
            }
        })
        let gonderilecek = {
            seriNo: seriNo,
            data: gonderilecekData
        }
        // console.log(gonderilecekData)
        try {
            const res = await axios.post("https://api.enelsa.com:5015/api/cihazAyarNova", JSON.stringify(gonderilecek), {
                headers: {
                    "Content-Type": "application/json",
                    "authorization": "Bearer " + CookieManager.getCookie("token")
                }
            })
            if (res.data === "ok") {
                let gonderilecekData = {
                    seriNo: seriNo
                }
                try {
                    const res = await axios.post('https://api.enelsa.com:5015/api/cihazAyarKontrol', JSON.stringify(gonderilecekData), {
                        headers: {
                            'Content-Type': 'application/json',
                            'authorization': 'Bearer ' + CookieManager.getCookie("token")
                        }
                    })
                    if (res.status == 200) {
                        Swal.fire({
                            icon: 'success',
                            title: 'Ayar Başarılı',
                            text: 'Güncelleme işlemi 1-5 saniye içinde gerçekleşecektir.',
                            confirmButtonText: 'Tamam',
                            confirmButtonColor: 'rgb(70, 85, 105)',
                            width: '25em',
                        })
                        setEditx(true)
                    }

                } catch (e) {
                    // console.log(e)
                }
            }
            setEditx(true)
            setShowSettingsMenu(false)
        } catch (e) {
            let yeniBildirim = { mesaj: "Ayarlar", bildirim: 0 }
            props.bildirimleriYonet("kanalRenk", yeniBildirim)
        }
    }

    function runModeEdit() {
        runMode == 0 ? setRunMode("1") : setRunMode("0")
        icoRunMode == 0 ? setIcoRunMode("1") : setIcoRunMode("0")
    }


    async function varsayilanlaraSifirla() {
        setStrokeRate(props.veriler["strokeRate"])
        setStrokeRateLowLimit(props.veriler["strokeRateLowLimit"])
        setStrokeRateHighLimit(props.veriler["strokeRateHighLimit"])
        setStrokeRateUnitSelection(props.veriler["strokeRateUnitSelection"])

        setSetHigh(props.veriler["setHigh"])
        setSetHighStrokeRateValue(props.veriler["setHighStrokeRateValue"])
        setAnalogOutputSetLowValue(props.veriler["analogOutputSetLowValue"])
        setStartupDelayTime(props.veriler["startupDelayTime"])
        setAnalogOutputSetHighValue(props.veriler["analogOutputSetHighValue"])
        setSetLow(props.veriler["setLow"])
        setSetLowStrokeRateValue(props.veriler["setLowStrokeRateValue"])
        setpHTemperatureCompenstionSelection(props.veriler["temperatureCompenstionSelection"])

        setRunMode(props.veriler["runMode"])
        setIcoRunMode(props.veriler["runMode"])
    }

    useEffect(() => {
        setBaslangic(true)
        setStrokeRate(props.veriler["strokeRate"])
        setStrokeRateLowLimit(props.veriler["strokeRateLowLimit"])
        setStrokeRateHighLimit(props.veriler["strokeRateHighLimit"])
        setStrokeRateUnitSelection(props.veriler["strokeRateUnitSelection"])

        setSetHigh(props.veriler["setHigh"])
        setSetHighStrokeRateValue(props.veriler["setHighStrokeRateValue"])
        setAnalogOutputSetLowValue(props.veriler["analogOutputSetLowValue"])
        setStartupDelayTime(props.veriler["startupDelayTime"])
        setAnalogOutputSetHighValue(props.veriler["analogOutputSetHighValue"])
        setSetLow(props.veriler["setLow"])
        setSetLowStrokeRateValue(props.veriler["setLowStrokeRateValue"])
        setpHTemperatureCompenstionSelection(props.veriler["temperatureCompenstionSelection"])

        setRunMode(props.veriler["runMode"])
        setIcoRunMode(props.veriler["runMode"])
    }, [baslangic, editx && props])

    useEffect(() => {
        setShowSettingsMenu(false)
        setEditx(false)
    }, [strokeRate])


    let classNovaAlt = props.ekranGenisligi > 790 ? "nova-bilgi-kart-altx" : (props.ekranGenisligi > 520) ? "nova-bilgi-kart-alt-tabletx" : "nova-bilgi-kart-alt-mobilx"
    return (
        <>
            <div className="cihaz-bilgi-kart-icerik-cerceve2">
                <div className="nova-bilgi-kart-ust">
                    <div className="nova-isletim-modu">
                        <div>İşletim Modu : {isletimModu[props.veriler["operatingMode"]]}</div>
                        <div>Sensör Tipi : {altMode[props.veriler["measurementSensorTypeValue"]]}</div>
                    </div>
                </div>
                <div className="nova-bilgi-kart-orta-ppm">
                    <div className="vurus-sayisi">
                        <div className="vurus-ust">
                            Dozaj Hızı
                        </div>
                        <hr className="vurus-cizgi"></hr>
                        <div className="vurus-alt">
                            <span>{props.veriler["strokeRate"]}
                                <button className="nova-bilgi-kart-orta-unit-selection-button" onClick={() => { setShowSettingsMenu(true) }}>{unitSelection[strokeRateUnitSelection]}</button>
                            </span>
                        </div>
                    </div>
                    <div>
                        <hr className=""></hr>
                        <div className="icos">
                            <div className="calisma-modux">
                                <div>Çalışma Modu : <span>{mode[runMode]}</span> {<span className={(mode[props.veriler["runMode"]] != mode[runMode]) ? "editStar" : "star"}>*</span>}</div>
                                <span style={{ cursor: "pointer" }} onClick={runModeEdit}>{icolarMod[icoRunMode]} </span>
                            </div>
                            <div className="calisma-modux">
                                <div>Durum : <span>{state[props.veriler["runModeState"]]}</span></div>
                                <span>{icolarState[props.veriler["runModeState"]]} </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={classNovaAlt}>
                    <div className="kapasite-limit">
                        <div>Kapasite Alt Limit : <span>{Input(strokeRateLowLimit, setStrokeRateLowLimit, "nova-manuel-alt-input")} {unitSelection2[strokeRateUnitSelection]}</span> </div>
                        <div>Kapasite Üst Limit : <span>{Input(strokeRateHighLimit, setStrokeRateHighLimit, "nova-manuel-alt-input")} {unitSelection2[strokeRateUnitSelection]}</span> </div>
                        <br></br>
                        <div>Kontrol Set Alt : <span>{Input(setLow, setSetLow, "nova-manuel-alt-input")} {unit[props.veriler["measurementSensorTypeValue"]]}</span> </div>
                        <div>Set Alt Kapasite Oran : <span>{Input(setLowStrokeRateValue, setSetLowStrokeRateValue, "nova-manuel-alt-input")} {unitSelection2[props.veriler["strokeRateUnitSelection"]]}</span> </div>
                    </div>
                    <div className="kapasite-limit">
                        <div style={{ marginLeft: "40px" }}>Sensör Değeri : <span>{props.veriler["sensorValue"]} {unit[props.veriler["measurementSensorTypeValue"]]}</span> </div>
                        <div style={{ opacity: "0" }}>Sensör Değeri : <span>{Input(setLowStrokeRateValue, setSetLowStrokeRateValue, "nova-manuel-alt-input")}{unit[props.veriler["measurementSensorTypeValue"]]}</span> </div>
                        <br></br>
                        <div>Kontrol Set Üst : <span>{Input(setHigh, setSetHigh, "nova-manuel-alt-input")} {unit[props.veriler["measurementSensorTypeValue"]]}</span> </div>
                        <div>Set Üst Kapasite Oran : <span>{Input(setHighStrokeRateValue, setSetHighStrokeRateValue, "nova-manuel-alt-input")} {unitSelection2[props.veriler["strokeRateUnitSelection"]]}</span> </div>
                    </div>
                    <div className="kapasite-limit">
                        <div>Başlangıç Gecikmesi : <span>{Input(startupDelayTime, setStartupDelayTime, "nova-manuel-alt-input")} sn</span> </div>
                        <div style={{ display: "flex", opacity: props.veriler["measurementSensorTypeValue"] === 1 ? "0" : "1" }}>Sıcaklık Kompanzasyon : <span>{SelectMenu(pHTemperatureCompenstionSelection, setpHTemperatureCompenstionSelection, aktif)}</span></div>
                        <br></br>
                        <div>Analog Output Set Alt : <span>{Input(analogOutputSetLowValue, setAnalogOutputSetLowValue, "nova-manuel-alt-input")} {unit[props.veriler["measurementSensorTypeValue"]]}</span></div>
                        <div>Analog Output Set Üst : <span>{Input(analogOutputSetHighValue, setAnalogOutputSetHighValue, "nova-manuel-alt-input")} {unit[props.veriler["measurementSensorTypeValue"]]}</span></div>
                    </div>
                </div>
            </div>
            <div className="buttonDiv">
                <button onClick={() => varsayilanlaraSifirla()} className="nova-kaydet-button">TEMİZLE</button>
                <button onClick={() => manuelKaydet(props.cihazKullanici,
                    {
                        strokeRate: parseInt(strokeRate),
                        strokeRateLowLimit: parseInt(strokeRateLowLimit),
                        strokeRateHighLimit: parseInt(strokeRateHighLimit),
                        runMode: parseInt(runMode),
                        setHigh: parseInt(setHigh),
                        setHighStrokeRateValue: parseInt(setHighStrokeRateValue),
                        analogOutputSetLowValue: parseInt(analogOutputSetLowValue),
                        startupDelayTime: parseInt(startupDelayTime),
                        analogOutputSetHighValue: parseInt(analogOutputSetHighValue),
                        setLow: parseInt(setLow),
                        setLowStrokeRateValue: parseInt(setLowStrokeRateValue),
                        pHTemperatureCompenstionSelection: parseInt(pHTemperatureCompenstionSelection)
                    }
                )} className="nova-kaydet-button">KAYDET</button>
            </div>
        </>
    )
}

export default KontrolArkaTaraf