import React, { useEffect, useState } from "react"
import { faGear, faRotateLeft,faChartLine, faWaveSquare } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import "./sinyal-ppm-on-taraf.css"
import { Link } from "react-router-dom"
import { 
    aktif,
    isletimModu,
    mode,
    state,
    unitSelection2 as unitSelection,
    icolarMod,
    icolarState
 } from "./dictionaries"

const SinyalPpmOnTarafi = (props)=>{

    let altMode = {
        "0": "Vuruş/Sinyal",
        "1": "Su Sayacı",
        "2": "ppm Uygulama",
        "3": "Debimetre"
    }

    let pulseAmountUnit = {
        "0" : "Lt",
        "1" : "m3"
    }

    let flowMeterUnit = {
        "0" : "Lt/h",
        "1" : "m3/h"
    }

    let classNovaAlt = props.ekranGenisligi < 790 ? "nova-bilgi-kart-alt-mobil" : "nova-bilgi-kart-alt" 

    return(
        <>
        <div className="cihaz-bilgi-kart-icerik-cerceve2">
            <div className="nova-bilgi-kart-ust">
                <div className="nova-isletim-modu">
                   <div>İşletim Modu : {isletimModu[props.veriler["operatingMode"]]}</div> 
                   <div>Sinyal Giriş Modu : {altMode[props.veriler["operatingSubMode"]]}</div> 
                </div>
            </div>
            <div className="nova-bilgi-kart-orta-ppm">
                <div className="vurus-sayisi">
                    <div className="vurus-ust">
                        Dozaj Hızı
                    </div>
                    <hr className="vurus-cizgi"></hr>
                    <div className="vurus-alt">
                        <span>{props.veriler["strokeRate"]} {unitSelection[props.veriler["strokeRateUnitSelection"]]}</span>
                    </div>
                </div>
                <div>
                    <hr className=""></hr>
                    <div className="icos">
                        <div className="calisma-modux">
                            <div>Çalışma Modu : <span>{mode[props.veriler["runMode"]]}</span></div>
                            <span>{icolarMod[props.veriler["runMode"]]} </span>
                        </div>
                        <div className="calisma-modux">
                            <div>Durum : <span>{state[props.veriler["runModeState"]]}</span></div>
                            <span>{icolarState[props.veriler["runModeState"]]} </span>
                        </div>
                    </div> 
                </div>
            </div>
            <div className={classNovaAlt}>
                <div className="kapasite-limit">
                    {props.veriler["operatingSubMode"] == "2" && <div>ppm Set : <span>{props.veriler["ppmSet"]} ppm</span> </div>}
                    <div>Kapasite Alt Limit : <span>{props.veriler["strokeRateLowLimit"]} {unitSelection[props.veriler["strokeRateUnitSelection"]]}</span> </div>
                    <div>Kapasite Üst Limit : <span>{props.veriler["strokeRateHighLimit"]} {unitSelection[props.veriler["strokeRateUnitSelection"]]}</span> </div>
                </div>
                <div className="kapasite-limit">
                    <div>Sinyal Bölen : <span>{props.veriler["pulseInputDivider"]}</span> </div>
                { props.veriler["operatingSubMode"] != "0" && <div>Miktar/Sinyal : <span>{props.veriler["pulseAmount"]} {pulseAmountUnit[props.veriler["pulseAmountUnit"]]}</span></div>}
                { props.veriler["operatingSubMode"] == "2" && <div>Kimyasal Aktivite : <span>{props.veriler["chemicalActivity"]} %</span></div>}
                { props.veriler["operatingSubMode"] == "1" && <div>Çıkış mL/m3 : <span>{props.veriler["watermeterOutput"]}</span></div>}    
                { props.veriler["operatingSubMode"] == "0" && <div>Sinyal Sayısı: <span>{props.veriler["pulseCount"]}</span></div>}  
                { props.veriler["operatingSubMode"] == "0" && <div>Vuruş Sayısı: <span>{props.veriler["strokeCount"]}</span></div>}  
                </div>
                { (props.veriler["operatingSubMode"] != "3") &&
                <div className="kapasite-limit">
                    <div>Vuruş Hafıza : <span>{aktif[props.veriler["strokeMemorize"]]}</span></div>
                    <div>Adaptif Vuruş : <span>{aktif[props.veriler["adaptiveStrokeRateControl"]]}</span></div>
                </div>
                }
                { props.veriler["operatingSubMode"] == "3" &&
                <div className="kapasite-limit">
                    <div>Debimetre Min Oran : <span>{props.veriler["flowMaterMinimumRatio"]}</span></div>
                    <div>Debimetre Max Oran : <span>{props.veriler["flowMaterMaximumRatio"]}</span></div>
                    <div>Debimetre Birimi : <span>{flowMeterUnit[props.veriler["flowMeterUnit"]]}</span></div>
                </div>
                }
            </div>
        </div>
        <Link id="cihaz-on-taraf-grafik-ico" to={"/grafik/" + window.location.pathname.split("r/")[1]}>
            <FontAwesomeIcon icon={faChartLine}/>
        </Link>
        </>
    )
}

export default SinyalPpmOnTarafi