import React, { useState } from "react"
import { Link } from "react-router-dom"
import { faStar as solidStar } from "@fortawesome/free-solid-svg-icons"
import { faStar } from "@fortawesome/free-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import axios from "axios"
import novaLogo from "../../../../assests/nova.png"
import saltLogo from "../../../../assests/SALT IONIC A.png"
import omniconLogo from "../../../../assests/Varlık 2.png"
import { icolarMod, icolarState } from "../../../cihaz-sayfasi/components/nova/dictionaries"
import CookieManager from "../../../../../cookieManager"

const TemaIkiCihaz = (props) => {
    const butonFiltreRenkler = ["#005fab", "#02bb4a", "#009ceb", "#f59c1a", "#ff5b57"]

    let borderColor = butonFiltreRenkler[props.durum]
    let cihazTuru = (Object.keys(props.cihazData).length !== 0 && props.cihazData.data[props.cihazVerileri?.seri]?.cihazTuru) ? props.cihazData.data[props.cihazVerileri?.seri]?.cihazTuru : "omnicon"
    // console.log(cihazTuru)
    // let pin = props.cihazVerileri?.pin
    const [pin, setPin] = useState(props.cihazVerileri?.pin);
    let pinle = async () => {
        let pinlenecek = {
            id: CookieManager.getCookie("id"),
            pin: !pin,
            seri: props.cihazVerileri?.seri
        }
        try {
            let res = await axios.put("https://api.enelsa.com:5015/api/pinle", JSON.stringify(pinlenecek), {
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': 'Bearer ' + CookieManager.getCookie("token")
                }
            })
            setPin(!pin)
        } catch {
            // console.log("pinleyemedi")
        }
    }
    let tarih = props.cihazDegerleri?.tarih ? (props.cihazDegerleri?.tarih).slice(0, -6) : "-"
    let cihazVar = props.cihazVerileri ? true : false
    let cihazIsim = ""
    if (props.cihazData.data !== undefined) {
        cihazIsim = ((Object.keys(props.cihazData).length !== 0 && props.cihazData.data[props.cihazVerileri?.seri]?.isim) && props.cihazData.data[props.cihazVerileri?.seri]?.isim !== undefined) ? props.cihazData.data[props.cihazVerileri?.seri]?.isim : props.cihazVerileri?.cihazAdi !== "undefined" ? props.cihazVerileri?.cihazAdi : ""
    } else {
        cihazIsim = props.cihazVerileri?.cihazAdi !== "undefined" ? props.cihazVerileri?.cihazAdi : ""
    }
    // console.log(props.cihazData, props.cihazData.data[props.cihazVerileri?.seri]?.cihazTuru == "nova")
    return (
        cihazVar ?
            (
               cihazTuru.toLowerCase() === "omnicon"
                    ?
                    <div className="tema-iki-cihaz-cerceve" style={{ border: borderColor + " solid 2px" }}>
                        <div className="tema-iki-cihaz-bilgisi">
                            <Link to={"/cihazlar/" + props.cihazVerileri?.seri} style={{ border: "1px solid #FFFBEB", borderRadius: "8px" }}>
                                <div className="tema-iki-pin tema-iki-omnicon-logo" >
                                    <img src={omniconLogo} alt="omnicon" style={{ height: "25px" }} />
                                </div>
                                <div id="tema-iki-cihaz-bilgi">
                                    <p style={{ fontWeight: "bolder", fontSize: "10px" }} className="tema-iki-cihaz-bilgi-txt-temel">OMNİCON</p>
                                    <p style={{ fontSize: "9px" }} className="tema-iki-cihaz-bilgi-txt-temel">{props.cihazVerileri?.kullaniciAdi}</p>
                                    <p style={{ fontSize: "9px" }} className="tema-iki-cihaz-bilgi-txt-temel">{cihazIsim}</p>
                                    <p style={{ fontSize: "9px" }} className="tema-iki-cihaz-bilgi-txt-temel">{props.cihazVerileri?.seri}</p>
                                    <p style={{ fontSize: "8px" }} className="tema-iki-cihaz-bilgi-txt-temel">{tarih}</p>
                                </div>
                            </Link>
                        </div>
                        <div className="tema-iki-cihaz-bilgi-durum">
                            {Array.from(Array(6).keys()).map(e => {
                                const cihazVerisi = props.cihazVerileri;
                                const kanalVerisi = props.cihazDegerleri && props.cihazDegerleri.data && props.cihazDegerleri.data[e + 1];

                                return (
                                    <Link
                                        style={{ display: "flex", alignItems: "center", justifyContent: "center", border: "1px solid #FFFBEB", borderRadius: "8px", flexDirection: "column", width: "45px", height: "45px", margin: "7px 0px", color: "#F7FBFF", textDecoration: "none" }}
                                        key={"kanal-" + e + props.cihazVerileri?.seri}
                                    >
                                        {cihazVerisi && kanalVerisi && kanalVerisi.value !== undefined ?
                                            <>
                                                <p style={{ fontSize: "10px" }} className="tema-iki-cihaz-bilgi-txt-temel">{kanalVerisi.unit}</p>
                                                <p style={{ fontSize: "10px" }} className="tema-iki-cihaz-bilgi-txt-temel">{kanalVerisi.value}</p>
                                            </> :
                                            <p style={{ fontSize: "10px" }} className="tema-iki-cihaz-bilgi-txt-temel">-</p>
                                        }
                                    </Link>
                                );
                            })}
                        </div>
                        <div className="tema-iki-pin" >
                            <FontAwesomeIcon icon={pin ? solidStar : faStar} onClick={() => { pinle() }} />
                        </div>
                    </div>
                    : cihazTuru.toLowerCase() === "nova" ?
                        <div className="tema-iki-cihaz-cerceve" style={{ border: borderColor + " solid 2px" }}>
                            <div className="tema-iki-cihaz-bilgisi">
                                <Link to={"/cihazlar/" + props.cihazVerileri?.seri} style={{ border: "1px solid #FFFBEB", borderRadius: "8px" }}>
                                    <div className="tema-iki-pin tema-iki-nova-logo" >
                                        <img src={novaLogo} alt="nova" style={{ height: "40px", padding: "0" }} />
                                    </div>
                                    <div id="tema-iki-cihaz-bilgi-nova" >
                                        <p style={{ fontWeight: "bolder", fontSize: "10px", opacity: "1" }} className="tema-iki-cihaz-bilgi-txt-temel">NOVA</p>
                                        <p style={{ fontSize: "9px" }} className="tema-iki-cihaz-bilgi-txt-temel">{props.cihazVerileri?.kullaniciAdi}</p>
                                        <p style={{ fontSize: "9px" }} className="tema-iki-cihaz-bilgi-txt-temel">{cihazIsim}</p>
                                        <p style={{ fontSize: "9px" }} className="tema-iki-cihaz-bilgi-txt-temel">{props.cihazVerileri?.seri}</p>
                                        <p style={{ fontSize: "8px" }} className="tema-iki-cihaz-bilgi-txt-temel">{tarih}</p>
                                    </div>
                                </Link>
                            </div>
                            <div className="tema-iki-cihaz-bilgi-durum-nova">
                                <Link className="tema-iki-cihaz-bilgi-durum-nova-link">
                                    <div className="tema-iki-cihaz-bilgi-durum-nova-link-div">
                                        <p style={{ fontSize: "10px" }} className="tema-iki-cihaz-bilgi-txt-temel">Vuruş Oranı</p>
                                        <p style={{ fontSize: "10px" }} className="tema-iki-cihaz-bilgi-txt-temel">{props.cihazDegerleri?.data.strokeRate}</p>
                                    </div>
                                </Link>
                                <Link className="tema-iki-cihaz-bilgi-durum-nova-link">
                                    <div className="tema-iki-cihaz-bilgi-durum-nova-link-div">
                                        <p style={{ fontSize: "10px" }} className="tema-iki-cihaz-bilgi-txt-temel">Çalışma Modu</p>
                                        <p style={{ fontSize: "10px" }} className="tema-iki-cihaz-bilgi-txt-temel">{icolarMod[props.cihazDegerleri?.data.runMode]}</p>
                                    </div>
                                </Link>
                                <Link className="tema-iki-cihaz-bilgi-durum-nova-link">
                                    <div className="tema-iki-cihaz-bilgi-durum-nova-link-div">
                                        <p style={{ fontSize: "10px" }} className="tema-iki-cihaz-bilgi-txt-temel">Durum</p>
                                        <p style={{ fontSize: "10px", alignSelf: "end" }} className="tema-iki-cihaz-bilgi-txt-temel">{icolarState[props.cihazDegerleri?.data.runModeState]}</p>
                                    </div>
                                </Link>
                            </div>
                            <div className="tema-iki-pin" >
                                <FontAwesomeIcon icon={pin ? solidStar : faStar} onClick={() => { pinle() }} />
                            </div>
                        </div>
                        : <div className="tema-iki-cihaz-cerceve" style={{ border: borderColor + " solid 2px" }}>
                            <div className="tema-iki-cihaz-bilgisi">
                                <Link to={{ pathname: "/cihazlar/salt/" + props.cihazVerileri?.seri, state: { cihazIsim: cihazIsim } }} style={{ border: "1px solid #FFFBEB", borderRadius: "8px" }}>
                                    <div className="tema-iki-pin tema-iki-nova-logo" >
                                        <img src={saltLogo} alt="saltionic" style={{ height: "40px", padding: "0 20px" }} />
                                    </div>
                                    <div id="tema-iki-cihaz-bilgi-nova" >
                                        <p style={{ fontWeight: "bolder", fontSize: "10px", opacity: "1" }} className="tema-iki-cihaz-bilgi-txt-temel">SALT IONIC</p>
                                        <p style={{ fontSize: "9px" }} className="tema-iki-cihaz-bilgi-txt-temel">{props.cihazVerileri?.kullaniciAdi}</p>
                                        <p style={{ fontSize: "9px" }} className="tema-iki-cihaz-bilgi-txt-temel">{cihazIsim}</p>
                                        <p style={{ fontSize: "9px" }} className="tema-iki-cihaz-bilgi-txt-temel">{props.cihazVerileri?.seri}</p>
                                        <p style={{ fontSize: "8px" }} className="tema-iki-cihaz-bilgi-txt-temel">{tarih}</p>
                                    </div>
                                </Link>
                            </div>
                            <div className="tema-iki-cihaz-bilgi-durum-nova">
                                <Link style={{ display: "flex", alignItems: "center", justifyContent: "center", border: "1px solid #FFFBEB", borderRadius: "8px", flexDirection: "column", width: "45px", height: "45px", margin: "7px 0px", color: "#F7FBFF", textDecoration: "none" }}>
                                    <div className="tema-iki-cihaz-bilgi-durum-nova-link-div">
                                        <p style={{ fontSize: "10px" }} className="tema-iki-cihaz-bilgi-txt-temel">Cihaz Modu</p>
                                        <p style={{ fontSize: "10px" }} className="tema-iki-cihaz-bilgi-txt-temel">{props.cihazVerileri?.mode == "0" ? "Manuel" : "Sensör"}</p>
                                    </div>
                                </Link>
                                <Link style={{ display: "flex", alignItems: "center", justifyContent: "center", border: "1px solid #FFFBEB", borderRadius: "8px", flexDirection: "column", width: "45px", height: "45px", margin: "7px 0px", color: "#F7FBFF", textDecoration: "none" }}>
                                    <div className="tema-iki-cihaz-bilgi-durum-nova-link-div">
                                        <p style={{ fontSize: "10px" }} className="tema-iki-cihaz-bilgi-txt-temel">Üretim</p>
                                        <p style={{ fontSize: "10px" }} className="tema-iki-cihaz-bilgi-txt-temel">{props.cihazVerileri?.outputStatus == "0" ? "Kapalı" : "Açık"}</p>
                                    </div>
                                </Link>
                                <Link style={{ display: "flex", alignItems: "center", justifyContent: "center", border: "1px solid #FFFBEB", borderRadius: "8px", flexDirection: "column", width: "45px", height: "45px", margin: "7px 0px", color: "#F7FBFF", textDecoration: "none" }}>
                                    <div className="tema-iki-cihaz-bilgi-durum-nova-link-div">
                                        <p style={{ fontSize: "10px" }} className="tema-iki-cihaz-bilgi-txt-temel">Üretim Set</p>
                                        <p style={{ fontSize: "10px", alignSelf: "end" }} className="tema-iki-cihaz-bilgi-txt-temel">{props.cihazVerileri?.outputSet}</p>
                                    </div>
                                </Link>
                                <Link style={{ display: "flex", alignItems: "center", justifyContent: "center", border: "1px solid #FFFBEB", borderRadius: "8px", flexDirection: "column", width: "45px", height: "45px", margin: "7px 0px", color: "#F7FBFF", textDecoration: "none" }}>
                                    <div className="tema-iki-cihaz-bilgi-durum-nova-link-div">
                                        <p style={{ fontSize: "10px" }} className="tema-iki-cihaz-bilgi-txt-temel">Anlık Üretim</p>
                                        <p style={{ fontSize: "10px" }} className="tema-iki-cihaz-bilgi-txt-temel">{props.cihazVerileri?.currentOutput}</p>
                                    </div>
                                </Link>
                                <Link style={{ display: "flex", alignItems: "center", justifyContent: "center", border: "1px solid #FFFBEB", borderRadius: "8px", flexDirection: "column", width: "45px", height: "45px", margin: "7px 0px", color: "#F7FBFF", textDecoration: "none" }}>
                                    <div className="tema-iki-cihaz-bilgi-durum-nova-link-div">
                                        <p style={{ fontSize: "10px" }} className="tema-iki-cihaz-bilgi-txt-temel">Üretim Miktarı</p>
                                        <p style={{ fontSize: "10px", alignSelf: "end" }} className="tema-iki-cihaz-bilgi-txt-temel">{props.cihazVerileri?.outputCount}</p>
                                    </div>
                                </Link>
                            </div>
                            <div className="tema-iki-pin" >
                                <FontAwesomeIcon icon={pin ? solidStar : faStar} onClick={() => { pinle() }} />
                            </div>
                        </div>
            ) :
            <div style={{ display: "none" }}></div>

    )
}

export default TemaIkiCihaz