import React, { useEffect, useState } from "react"
import { faGear, faRotateLeft, faChartLine, faWaveSquare, faX } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import "./paket-on-taraf.css"
import { Link } from "react-router-dom"
import ProsesPopUp from "./proses-pop-up"
import {
    isletimModu,
    mode,
    state,
    unitSelection2 as unitSelection,
    icolarMod,
    icolarState
} from "./dictionaries"

const PaketOnTarafi = (props) => {
    let [popupDurum1, setPopupDurum1] = useState(false)
    let [popupKonum1, setPopupKonum1] = useState(-250)
    let [index, setIndex] = useState(null)

    const popupGoster = (i) => {
        setPopupDurum1(true)
        setTimeout(() => setPopupKonum1(100), 100)
        setIndex(i)
    }

    const popupGizle = () => {
        setPopupKonum1(-250)
        setTimeout(() => setIndex(null), 320)
    }

    let girisMod = {
        "0": "Başlangıçta Tetik",
        "1": "Manuel Tetik",
        "2": "Sinyal Tetik",
        "3": "Periyodik",
        "4": "Zaman Program"
    }

    let hour = ""
    let minute = ""
    if (props.veriler["periodHour"] != undefined) {
        let periodHourString = props.veriler["periodHour"].toString();
        hour = periodHourString.length === 1 ? "0" + periodHourString : periodHourString

        let periodMinuteString = props.veriler["periodMinute"].toString();
        minute = periodMinuteString.length === 1 ? "0" + periodMinuteString : periodMinuteString
    }

    let dakika = ""
    let saniye = ""
    let processTime = ""
    if (props.veriler.processTime != undefined) {
        let dakikaa = Math.floor(props.veriler.processTime / 60);
        let dakikaString = dakikaa.toString();
        dakika = dakikaString.length === 1 ? "0" + dakikaString : dakikaString

        let saniyee = props.veriler.processTime % 60;
        let saniyeString = saniyee.toString();
        saniye = saniyeString.length === 1 ? "0" + saniyeString : saniyeString

        processTime = dakika + " : " + saniye
    }

    let buttonsDivClass = props.ekranGenisligi > 790 ? "nova-bilgi-kart-paket nova-bilgi-kart-paketx" : "nova-bilgi-kart-paket"

    function butonlar() {
        const buttons = []
        for (let i = 0; i < 10; i++) {
            const programAdi = i
            let className = props.veriler.programs[programAdi].processStartDayNumber != 0 ? "active" : "passive"
            buttons.push(
                <button className={className} onClick={() => popupGoster(i)}>Program {i + 1}</button>
            );
        }
        return (
            <div className={buttonsDivClass}>
                {buttons}
            </div>
        )
    }

    let classNovaAlt = props.ekranGenisligi > 790 ? "nova-bilgi-kart-alt" : (props.ekranGenisligi > 520) ? "nova-bilgi-kart-alt-tablet" : "nova-bilgi-kart-alt-mobil"
    let tetikSinyalAlt = props.ekranGenisligi > 790 ? "tetik-sinyal" : (props.ekranGenisligi > 520) ? "tetik-sinyal-tablet" : "tetik-sinyal-mobil"
    let tetikPeriyoduAlt = props.ekranGenisligi > 790 ? "tetik-periyot" : (props.ekranGenisligi > 520) ? "tetik-periyot-tablet" : "tetik-periyot-mobil"

    return (
        <>
            <div className="cihaz-bilgi-kart-icerik-cerceve2">
                <div className="nova-bilgi-kart-ust">
                    <div className="nova-isletim-modu">
                        <div>İşletim Modu : {isletimModu[props.veriler["operatingMode"]]}</div>
                        <div>Sinyal Giriş Modu : {girisMod[props.veriler["packageSubMode"]]}</div>
                    </div>
                </div>
                <div className="nova-bilgi-kart-orta-ppm">
                    <div className="vurus-sayisi">
                        <div className="vurus-ust">
                            Vuruş Oranı
                        </div>
                        <hr className="vurus-cizgi"></hr>
                        <div className="vurus-alt">
                            <span>{props.veriler["strokeRate"]} {unitSelection[props.veriler["strokeRateUnitSelection"]]}</span>
                        </div>
                        <div>
                            <hr className=""></hr>
                            <div className="icos">
                                <div className="calisma-modux">
                                    <div>Çalışma Modu : <span>{mode[props.veriler["runMode"]]}</span></div>
                                    <span>{icolarMod[props.veriler["runMode"]]} </span>
                                </div>
                                <div className="calisma-modux">
                                    <div>Durum : <span>{state[props.veriler["runModeState"]]}</span></div>
                                    <span>{icolarState[props.veriler["runModeState"]]} </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {(props.veriler["packageSubMode"] == "4") && butonlar()}
                <div className={classNovaAlt}>
                    <div className="kapasite-limit">
                        <div>Kapasite Alt Limit : <span>{props.veriler["strokeRateLowLimit"]} {unitSelection[props.veriler["strokeRateUnitSelection"]]}</span> </div>
                        <div>Kapasite Üst Limit : <span>{props.veriler["strokeRateHighLimit"]} {unitSelection[props.veriler["strokeRateUnitSelection"]]}</span> </div>
                    </div>
                    {(props.veriler["packageSubMode"] == "4") &&
                        <div className="kapasite-limit">
                            <div>Program Periyodu (Gün): <span>{props.veriler["programPeriod"]}</span></div>
                            <div>Program Gün No : <span>{props.veriler["currentDayNumber"]}</span></div>
                        </div>
                    }
                    {(props.veriler["packageSubMode"] != "4") &&
                        <>
                            <div className="kapasite-limit">
                                <div>Proses Vuruş Oranı : <span>{props.veriler.processStrokeRate} <FontAwesomeIcon icon={faWaveSquare}></FontAwesomeIcon>d</span></div>
                                <div>Proses Zamanı : <span>{processTime}</span></div>
                            </div>
                            <div className="kapasite-limit">
                                <div>Proses Vuruş Sayısı : <span>{props.veriler.processStrokeCount} <FontAwesomeIcon icon={faWaveSquare}></FontAwesomeIcon></span></div>
                                <div>Proses Kapasitesi : <span>{props.veriler.processCapacity} L</span></div>
                            </div>
                        </>
                    }
                </div>
                {(props.veriler["packageSubMode"] == "2") &&
                    <div className={tetikSinyalAlt}>
                        <div>Tetik Sinyal Sayısı : <span>{props.veriler["pulseCountPackage"]} s</span></div>
                        <div>Tetik Sinyal Bölen : <span>{props.veriler["pulseDividerPackage"]} s</span></div>
                    </div>
                }
                {(props.veriler["packageSubMode"] == "3") &&
                    <div className={tetikPeriyoduAlt}>
                        <div>Tetik Periyodu : <span>{hour} : {minute}</span></div>
                    </div>
                }
            </div>
            <Link id="cihaz-on-taraf-grafik-ico" to={"/grafik/" + window.location.pathname.split("r/")[1]}>
                <FontAwesomeIcon icon={faChartLine} />
            </Link>
            {(index !== undefined && index !== null) && (
                <ProsesPopUp
                    veriler={props.veriler}
                    index={index}
                    popupKonum={popupKonum1}
                    kapat={popupGizle}
                />
            )}
        </>
    )
}

export default PaketOnTarafi