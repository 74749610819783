import React from "react"
import Bildirim from "./components/bildirim"

const Bildirimler = (props)=>{
    let bilidirimSayisi = Object.keys(props.bildirimler).length
    return(
        <div style={{position: "absolute", top: "60px", right: "0px", height: (bilidirimSayisi !== 0 ? bilidirimSayisi * 60 - 12 : 0) + "px", width: "215px", zIndex: "2", overflow: "hidden", transition: "all 0.35s"}}>
            {Object.keys(props.bildirimler).map((e,i)=><Bildirim 
            konum={i} 
            bildirimBilgileri={props.bildirimler[e]} 
            bildirimiListedenCikar={props.bildirimiListedenCikar} 
            bildirimlerinPozisyonlanmasiniYonet={props.bildirimlerinPozisyonlanmasiniYonet} key={props.bildirimler[e].key}/>)}
        </div>
    )
}

export default Bildirimler