import axios from "axios"
import CookieManager from "../../../../cookieManager"

async function cihazVerileriniEldeEt(cihazlar) {
    let gonderilecekData = {
        cihazlar: cihazlar
    }
    
    if (Object.keys(cihazlar).length > 0) {
        try {
            let veriler = await axios.post("https://api.enelsa.com:5015/api/searchAll", gonderilecekData, {
                headers: {
                    'authorization': 'Bearer ' + CookieManager.getCookie("token")
                }
            })
            return veriler.data
        }
        catch (e) {
            if (e.response) {
                // Sunucudan gelen hata yanıtı varsa
                // console.log("Hata yanıtı:", e.response.data);
                // console.log("Hata kodu:", e.response.status);
            } else if (e.request) {
                // İstek yapıldı ama cevap alınamadı
                // console.log("Sunucudan cevap alınamadı:", e.request);
            } else {
                // Başka bir hata
                // console.log("Axios isteğinde bir hata oluştu:", e.message);
            }
            return {}
            //window.location.replace("/anasayfa")
        }
    } else {
        return {}
    }
}


export default cihazVerileriniEldeEt